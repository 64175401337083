/*
 * 게시글 상세내용 (등록, 수정, 삭제, 댓글작성)
 */

//페이징 구현
//https://dlgkstjq623.tistory.com/292

import React, { useEffect, useState } from 'react';
import { getPostByNo } from '../../db/Data';
import { Link } from 'react-router-dom';
import { withRouter, useParams, useNavigate } from 'react-router-dom';
import './Post.css';
import { collection, doc, getDocs, setDoc, deleteDoc, query, where, getDoc, whereFiled } from "firebase/firestore";
import {db} from "fb.js";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";

import {
  Image,
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  Confirm,
} from 'semantic-ui-react';




const PostView = ({ history, location, match }) => {
  // store에 접근하여 state 가져오기
  const { isLogin, userInfo } = useSelector(state => state.userInfo);

  const { id } = useParams();
  //const [ data, setData ] = useState({});
  const [ docId, setDocId ] = useState()
  const [ board, setBoard ] = useState()
  const [ replyList, setReplyList ] = useState([])
  let navigate = useNavigate();


  //https://velog.io/@kcdoggo/Cannot-read-property-params-of-undefined-%EC%97%90%EB%9F%AC
  // react-router-dom 6 버전부터는 아래와 같은 route props로 
  // (history, location, match) 파라미터를 넘겨받지 않는다.
  //const { no } = match.params;


  //https://velog.io/@soryeongk/ReactRouterDomV6

  
  
  async function getBoard() {
    //console.log("no : " + no)
    const q = query(collection(db, "Board"), where("board_id", "==", Number(id)));
    const docSnap = await getDocs(q);

    docSnap.forEach((doc) => {
      setBoard(doc.data());
      setDocId(doc.id);
      console.log(doc.data())
    })
  }

  async function deletePost() {
    console.log("board_id ==========> " + board.board_id);
    if (window.confirm("삭제하시겠습니까?")) {
       let isError = false;
      try {
        const docSnap = await deleteDoc(doc(db, "Board", docId));
      } 
      catch (err) {
        isError = true;
        console.error(err);
        alert(err.message);
      }
      if (!isError) {
        alert("삭제 되었습니다.");
        //navigate("/PostMain")
        navigate("/PostMain");
      }
    }
  }

  function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  }

  
  useEffect(() => {
    console.log("useEffect");
    //setData(getPostByNo(no));
    getBoard();
  }, [ ]);

  return (
    <>
      {/* <h2 align="center">게시글 상세정보</h2> */}

      { board ?
        <div align="center">
          <label>[Admin]　</label>
          <label>게시글번호: {id}</label>
          <label>　|　</label>
        </div> : 'aaaa'
      }
        {/* css로 처리해야하릇 */}
      
      <div className="post-view-wrapper" style={{whiteSpacae : "pre-wrap"}}>
        {
          board ? (
            <>
            <div className="post-view-title">
                <h3>{ board.title }</h3>
            </div>
            <div className="post-view-top">
              <Icon name='file image'/>
              <label>{ board.nickname }</label>
              <label>　　　</label>
              <Icon name='list'/>
              <label>잡담&고민</label>
              <label>　　</label>
              <Icon name='eye'/>
              <label>{ board.view }</label>
              <label>　　</label>
              <Icon name='comment'/>
              <label>6</label>
              <label>　　</label>
              <Icon name='thumbs up outline'/>
              <label float="right"> { board.like }</label>
              <label>　　</label>
              {
                board.user_id === userInfo.user_id ?
                  <>
                    <div className="right-box"> <label>　|　</label></div>
                    <div className="right-box"> 
                      <label onClick={ (e) => deletePost() }>삭제</label>
                    </div>
                    <div className="right-box"> <label>　|　</label></div>
                    <div className="right-box"> 
                      <Link to={`/PostEdit/${board.board_id}`}><label>수정</label></Link>
                    </div>
                    <div className="right-box"> <label>　|　</label></div>
                  </>
                : null
              }
              <div className="right-box"> <label>{ board.create_time }</label> </div>
            </div>
              <div className="post-view-row">
                <div>
                  {
                    board.content && <pre> {
                      // 개행문자를 기준으로 문자를 잘라(split) 배열로 만들고 
                      //배열 사이사이 <br />태그를 넣어 뿌려줘서 개행을 넣은 효과를 내준다.
                      board.content.split("\n").map((line) => {
                          return (
                              <span>
                                  {line}
                                  <br />
                              </span>
                          );
                      })}
                  </pre>
                  }
                </div>
              </div>
            </>
          ) : '해당 게시글을 찾을 수 없습니다.'
        }
        <br/>
        <br/>
        <button className="post-view-go-list-btn" onClick={() => navigate("/PostMain")}>목록으로 돌아가기</button>
      </div>
    </>
  )
}

export default PostView;