import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, setDoc, deleteDoc, query, where, getDoc, whereFiled } from "firebase/firestore";
import {db} from "fb.js";
import crypto from 'crypto';
import * as commonDB from "awUtils/dbFunction"

// AWS-intervase vs firebase
//https://aws-interface.com/ 

  /* 
   * funtion 현재 시간 구하기
   *  
   * type : "ms"일 때 밀리세컨드까지 구함.
   * 
   * return 'yyyy-mm-dd hh:mm:ss'
   * 2022.03.30
   */
  export function getDateTime(type) {
    var today = new Date();
    
    if (type === 'ms') {
      today.setHours(today.getHours() + 9); 
      today = today.toISOString().replace('T', ' ').substring(0, 23);
      today = today.replace('.', ' ');
    }
    else {
      today.setHours(today.getHours() + 9); 
      today = today.toISOString().replace('T', ' ').substring(0, 19);
    }
    
    return today
  }

  export function getLog() {
    return "잘 된다!"
  }

  /* 
   * funtion암호화 및 복호화
   * 2022.03.30
   */
  export async function cryptoPass(password) {
    let salt;
    await commonDB.getSalt()
      .then(result => {
        //console.log("result.salt : " + result.salt);
        //console.log("result.test : " + result.test);
        salt = result.salt;
      })
      .catch(result => {
        console.log("reject : " + result);
      })

    return new Promise((resolve, reject) => {
      //salt 랜덤생성
      //salt = crypto.randomBytes(32).toString('base64');

      crypto.pbkdf2(password, salt, 1000, 64, 'sha512', (err, hash) => {
          if(err){
              reject(err);
          } else{
              resolve({
                  hash: hash.toString('base64'),
                  salt: salt
              });
          }
      });
    })
  }

  