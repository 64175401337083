import { selector } from 'recoil';
import { countState, inputState, currentDateTime } from 'Recoil/Atom';

//현재 시간 구하기
const slctrGetCurruentDateTime = selector({
    key: 'slctrGetCurruentDateTime',
  
    // get: ({ get }) => {
    //   const inputVal = get(inputState);
    //   const count = get(countState);
  
    //   return `추가된 카운트는 ${inputVal}이고, 현재 카운트는 ${count}입니다.`;
    // },

    get: ({ get }) => {
        const currentDateTime = get(currentDateTime);
        currentDateTime = new Date(); 
        currentDateTime.setHours(currentDateTime.getHours() + 9); 
        currentDateTime = currentDateTime.toISOString().replace('T', ' ').substring(0, 19);
        
        return currentDateTime
    },
    set: ({ set }, newValue) => set(currentDateTime, newValue)
});



export default slctrGetCurruentDateTime;