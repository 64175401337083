/* eslint-disable */    //waring 제거
//1. 주식 API 추천   https://geekflare.com/best-stock-market-api/
//2. amChart 사용법  https://www.amcharts.com/docs/v5/getting-started/integrations/react/

import React, { Component, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import dummyData from '../db/data.json';

const data = dummyData.data

function TestPage1() {
  const [stockChartXValues, setStockChartXValues] = useState([]);
  const [stockChartYValues, setStockChartYValues] = useState([]);

  useEffect(() => {
    fetchStock()
    //fetchStockChart()
  }, [])

  const fetchStock = async() => {
    const pointerToThis = this;
    console.log(pointerToThis);
    const API_KEY = "CWKEZO3KHGLYJ9H0";
    let StockSymbol = 'TSLA';
    let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${StockSymbol}&outputsize=compact&apikey=${API_KEY}`;
    let stockChartXValuesFunction = [];
    let stockChartYValuesFunction = [];


    const json = await (
      fetch(API_Call)
    ).json();
    console.log(json);

    for (var key in json['Time Series (Daily)']) {
      stockChartXValuesFunction.push(key);
      stockChartYValuesFunction.push(json['Time Series (Daily)']
      [key]['4. close']);
    }

    //console.log(stockChartXValuesFunction);
    setStockChartXValues(stockChartXValuesFunction)
    setStockChartYValues(stockChartYValuesFunction)
    console.log("stockChartXValues", stockChartXValues)
  }

  return (
    <div>
      <h3> This is Test page1 </h3>
      <LineChart
        width={600}
        height={350}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="you" stroke="#8884d8" activeDot={{ r: 4 }} />
        <Line type="monotone" dataKey="market" stroke="#82ca9d" />
      </LineChart>

      {/* <p>x-values : {this.state.stockChartXValues}</p>
      <p>y-values : {this.state.stockChartYValues}</p> */}

      <Plot
        data={[
          {
            x: stockChartXValues,
            y: stockChartYValues,
            type: 'scatter',
            mode: 'lines+markers',
            marker: {color: 'red'},
          }
          //막대그래프
          //{type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
        ]}
        layout={ {width: 720, height: 440, title: 'A Fancy Plot'} }
      />
    </div>
  );
}

class TestPage1_onebone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockChartXValues: [],
      stockChartYValues: []
    }
  }
  componentDidMount = async () => {
    console.log('*******************************');
    console.log("****   componentDidMount   ****");
    console.log('*******************************');

    this.fetchStock();
    this.fetchStockChart();
  }

  fetchStock() {
    const pointerToThis = this;
    console.log(pointerToThis);
    const API_KEY = "CWKEZO3KHGLYJ9H0";
    let StockSymbol = 'TSLA';
    let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${StockSymbol}&outputsize=compact&apikey=${API_KEY}`;
    let stockChartXValuesFunction = [];
    let stockChartYValuesFunction = [];

    fetch(API_Call)
      .then(
        function(response) {
          return response.json();
        }
      )
      .then(
        function(data) {
          console.log(data);

          for (var key in data['Time Series (Daily)']) {
            stockChartXValuesFunction.push(key);
            stockChartYValuesFunction.push(data['Time Series (Daily)']
            [key]['4. close']);
          }

          //console.log(stockChartXValuesFunction);
          pointerToThis.setState({
            stockChartXValues : stockChartXValuesFunction,
            stockChartYValues : stockChartYValuesFunction
          })
        }
      )
  }

  fetchStockChart() {
    
  }
}

export default TestPage1;