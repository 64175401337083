import React, { useState, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

const ReadOnlyRowSnowIndex = ({ contact, handleCheckChange, bgColor }) => {

    const [textAssetData, setTextAssetData] = useState(contact);
    
    // onFocus 이벤트 발생 시 blur처리
    // contentEditable = true로 안하면 좌우 테이블의 행크기가 달라지기 때문
    function handlerNoInput(e) {
        e.target.blur();
    }

    function getClassName(value, text) {
        var className = "";

        if (text == "profit") {
            if (value == "") {
                className = ''
            }
            else if (Number(value) > 0) {
                //className = 'plusClassRoundBracket'
            } 
            else if (Number(value) < 0) {
                className = 'minusClassRoundBracket'
            }
        }
        
        return className
    }

    // 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }
        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return n;
    };
        
    // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    String.prototype.format = function() {
        var num = parseFloat(this);
        if( isNaN(num) ) {
            return "0";
        }

        return num.format().toString().replace('-', '');
    };

    useEffect(() => {
        // 조회 시 숫자 format() 적용
        const newFormData = { ...textAssetData };
        newFormData["unit"] = contact.unit.format();
        newFormData["profit_index"] = contact.profit_index.format();
        newFormData["asset_index"] = contact.asset_index.format();
        newFormData["profit"] = contact.profit.format();
        setTextAssetData(newFormData);

     }, [])

    return (
        <tr>
            <td 
                className={bgColor}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '50px'
                }}
                onFocus={handlerNoInput}
                suppressContentEditableWarning={true}
                >{textAssetData.unit}</td>
            <td 
                className={bgColor}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '50px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.profit_index}</td>
            <td 
                className={bgColor}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '50px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.asset_index}</td>
            {/* <td 
                className={getClassName(contact.profit, "profit")}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '50px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.profit}</td> */}
        </tr>
    )
}

export default ReadOnlyRowSnowIndex;