/* eslint-disable */    //waring 제거
//1. 주식 API 추천   https://geekflare.com/best-stock-market-api/
//2. amChart 사용법  https://www.amcharts.com/docs/v5/getting-started/integrations/react/
 
import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import AccountTable from './component/AccountTable'
import { ResponsiveLine } from '@nivo/line';

import {
  LineChart, Line, XAxis, YAxis, tesianGrid, Tooltip, Legend,
} from 'recharts';
import dummyData from '../db/data.json';

const data = dummyData.data


// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveLine = ({ data }) => (
  <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'transportation',
          legendOffset: 36,
          legendPosition: 'middle'
      }}
      axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle'
      }}
      colors={{ scheme: 'nivo' }}
      enablePoints={false}
      //pointSize={10}
      // pointColor={{ theme: 'background' }}
      // pointBorderWidth={2}
      // pointBorderColor={{ from: 'serieColor' }}
      // pointLabelYOffset={-12}
      //useMesh={true}
      legends={[
          {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
  />
)

class ChartStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockChartXValues: [],
      stockChartYValues: []
    }
  }
  componentDidMount = async () => {
    console.log('*******************************');
    console.log("****   componentDidMount   ****");
    console.log('*******************************');

    this.fetchStock('TSLA');
    this.fetchStock('SPY');
    this.fetchStockChart();
  }

  fetchStock(stockSymbol) {
    const pointerToThis = this;
    console.log(pointerToThis);
    const API_KEY = "CWKEZO3KHGLYJ9H0";
    //let StockSymbol = 'TSLA';
    console.log("stockSymbol : " + stockSymbol)
    //https://www.alphavantage.co/documentation/
    let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${stockSymbol}&outputsize=compact&apikey=${API_KEY}`;
    let stockChartXValuesFunction = [];
    let stockChartYValuesFunction = [];

    fetch(API_Call)
      .then(
        function(response) {
          return response.json();
        }
      )
      .then(
        function(data) {
          console.log(data);
          let i = 0;
          for (var key in data['Time Series (Daily)']) {
            stockChartXValuesFunction.push(key);
            stockChartYValuesFunction.push(data['Time Series (Daily)']
            [key]['4. close']);
            i++
          }
          console.log("i : " + i);

          pointerToThis.setState({
            stockChartXValues : stockChartXValuesFunction,
            stockChartYValues : stockChartYValuesFunction
          })
          
          //console.log(stockChartXValuesFunction);
        }
      )
  }

  fetchStockChart() {
    
  }

  

  render() {

    return (
        <div>
            <h3> This is ChartStock page!!! </h3>
            <LineChart
              width={600}
              height={350}
              data={data}
              margin={{
                top: 5, right: 30, left: 20, bottom: 5,
              }}
            >
              <tesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="you" stroke="RED" dot={false} />
              <Line type="monotone" dataKey="market" stroke="#82ca9d" dot={false} />
              <Line type="monotone" dataKey="amt" stroke="BLUE" dot={false} />
            </LineChart>

            <AccountTable></AccountTable>
      

            {/* <p>x-values : {this.state.stockChartXValues}</p>
            <p>y-values : {this.state.stockChartYValues}</p> */}

            <Plot
              //https://plotly.com/javascript/time-series/
              data={[
                {
                  x: this.state.stockChartXValues,
                  y: this.state.stockChartYValues,
                  type: 'scatter',
                  mode: 'lines+markers',
                  name: 'TSLA',
                  marker: {color: 'red'},
                },
                {
                  x: 10,
                  y: 100,
                  type: 'scatter',
                  mode: 'lines+markers',
                  name: 'APLL',
                  marker: {color: 'blue'},
                }
                //막대그래프
                //{type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
              ]}
              layout={ {width: 720, height: 440, title: 'A Fancy Plot'} }
            />  
            <div style={{height:'500px'}}>
            <MyResponsiveLine 
              data={[
                  {
                    "id": "japan",
                    "color": "hsl(108, 70%, 50%)",
                    "data": [
                      // {
                      //   x: this.state.stockChartXValues,
                      //   y: this.state.stockChartYValues,
                      // }
                      {
                        "x": "",
                        "y": 100
                      },
                      {
                        "x": " ",
                        "y": 200
                      },
                      {
                        "x": "3월",
                        "y": 300
                      },
                      {
                        "x": "4월",
                        "y": 400
                      },
                      {
                        "x": "5월",
                        "y": 500
                      },
                      {
                        "x": "6월",
                        "y": 600
                      },
                      {
                        "x": "7월",
                        "y": 700
                      },
                      {
                        "x": "8월",
                        "y": 800
                      },
                      {
                        "x": "9월",
                        "y": 900
                      },
                      {
                        "x": "10월",
                        "y": 700
                      },
                      {
                        "x": "11월",
                        "y": 600
                      },
                      {
                        "x": "12월",
                        "y": 1000
                      }
                    ]
                  },
                  {
                    "id": "france",
                    "color": "hsl(40, 70%, 50%)",
                    "data": [
                      {
                        "x": "",
                        "y": 200
                      },
                      {
                        "x": " ",
                        "y": 288
                      },
                      {
                        "x": "3월",
                        "y": 167
                      },
                      {
                        "x": "4월",
                        "y": 135
                      },
                      {
                        "x": "5월",
                        "y": 280
                      },
                      {
                        "x": "6월",
                        "y": 131
                      },
                      {
                        "x": "7월",
                        "y": 56
                      },
                      {
                        "x": "8월",
                        "y": 218
                      },
                      {
                        "x": "9월",
                        "y": 150
                      },
                      {
                        "x": "10월",
                        "y": 156
                      },
                      {
                        "x": "11월",
                        "y": 30
                      },
                      {
                        "x": "12월",
                        "y": 158
                      }
                    ]
                  },
                //막대그래프
                //{type: 'bar', x: [1, 2, 3], y: [2, 5, 3]},
              ]}
            />
            </div>
        </div>
    );
  }
}

export default ChartStock;