/* eslint-disable */    //waring 제거
import React from 'react';
import { Button } from 'semantic-ui-react';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {db} from "../fb.js";

class Buttons extends React.Component {
    constructor() {
        super()
        this.state = {
            likes : 0
        }
    }

    componentDidMount = async () => {
        const docRef = doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            this.setState({likes : docSnap.data().likes})
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    render() {
        console.log("================= 메모리 누수 체크");
        return (
            <div>
                <Button
                    color='red'
                    content='Like'
                    icon='heart'
                    label={{
                        basic: true,
                        color: 'red',
                        pointing: 'left',
                        content: this.state.likes,
                    }}
                    onClick = { () => {this.setState(prevState => {
                                //console.log("this.state.likes : " + this.state.likes);
                                return {likes : prevState.likes + 1}
                            }, async () => await updateDoc(doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC"), {likes : this.state.likes}))
                        }
                    }
                />
                <Button
                    basic
                    color='blue'
                    content='Visitors'
                    icon='user plus'
                    label={{
                        as: 'a',
                        basic: true,
                        color: 'blue',
                        pointing: 'left',
                        content: this.props.visitors,
                    }}
                    onClick = { () => this.props.openModal()}
                />

                <br/>
                <br/>
                <div>
                    <Button circular="circular" color="facebook" icon="facebook" onClick={ () => alert("준비중입니다.")}/>
                    <Button circular="circular" color="twitter" icon="twitter"/>
                    <Button
                        circular="circular"
                        color="youtube"
                        icon="youtube"
                        onClick={() => window.open("https://www.youtube.com/c/%EC%8A%88%EC%B9%B4%EC%9B%94%EB%93%9C")}
                    />
                    <Button circular="circular" color="google plus" icon="google plus"/>
                </div>
            </div>
            
            
        )
    }
}

export default Buttons