import React, { useEffect, useState } from "react";
import { collection, doc, getDocs, setDoc, deleteDoc, query, where, getDoc, whereFiled, Timestamp } from "firebase/firestore";
import {db} from "fb.js";
import * as common from "awUtils/commonFunction"
//import * as firebase from "firebase";

// firesotre 레퍼런스    https://firebase.google.com/docs/reference/js/firestore_

var stockData = [];
//var newData2 = { };

/* 아이디어

    			                yyyy-MM
ArrayList = stock_data.query("update_time" < current || "update_time" is empty)

            progressCnt / allStockCnt
<progress bar 25/100 >
[ lllllllllllllllllll                           ]

if (getPrgStock == allStockCnt) {
   setFetchState =  true
}


if (!fetchState) {
   api 호출 및 업데이트

   for (i=0;  i = ArrayList;  i++) {
      if ()
      ArrayList[i]

      progressCnt++
   }
}

*/






    // /*
    // * String to TimeStamp
    // * 2022.04.14
    // */
    // export function DateToTimeStamp(dateString) {
    //     return new Date(dateString).getTime()/10
    // }

    // /*
    // * Stock Fetch 상태 get
    // *
    // * return
    // * Array - 패치가 필요한 stock Name
    // * int   - 전체 stock count
    // * 2022.04.14
    // */
    // export async function getStockFetchState() {
    //     var result;
    //     const firstDay = new Date('yyyy-MM');
    //     //const timestamp1 = admin.firestore.Timestamp.fromDate(firstDay);
    //     //const current = new Date(Timestamp.now().seconds*1000).toLocaleDateString()
        

    //     var unFetchedStockArray = [];
    //     console.log("getStockFetchState - getTime : " + new Date("2022-04-16 05:22:14"));
    //     console.log("getStockFetchState - toTImeStamp : " + DateToTimeStamp("2022-04-16 02:00:01"));
    //     console.log("getStockFetchState - getDateTime : " + DateToTimeStamp(common.getDateTime()));
        

    //     /* update_time을 2202.04로 넣으면 2022.04.01 09:00보다 이른 시간인 경우
    //      * 쿼리조건에 걸림 (2022.03.31 로 여김)
    //      * --> 그래서 (yyyy-mm-01 00:00:01) 형식으로 직접 만들어줌.
    //     */
    //     var current = common.getDateTime().substr(0, 7) + "-01 00:00:01";
    //     current = new Date(current);
    //     //console.log("commonDB.getDataTime : " + common.getDateTime().substr(0, 7))
    //     //console.log("current : " + current)
        
    //     // unfetched stock name
    //     var q = query(collection(db, "stock_data"), where("update_time", "<", current))
    //     //q = collection(db, "stock_data")
    //     var docSnap = await getDocs(q);

    //     var i=0;
    //     docSnap.forEach((doc) => {
    //         unFetchedStockArray = unFetchedStockArray.concat(doc.id);
    //         //console.log(doc.id + " : " + doc.data().update_time)
    //         //update_time = new Date();
    //         i++;
    //     })

    //     // total stock count
    //     q = query(collection(db, "stock_data"));
    //     docSnap = await getDocs(q);
    //     let totalStockCount = docSnap.size;
    //     console.log("totalStockCount : "+ totalStockCount);

    //     //console.log("tong i : " + i)
    //     //console.log("tong stockArray : " + unFetchedStockArray[1])
        
    //     // console.log("docSnap.id : " + docSnap.id)
    //     // console.log("docSnap.data : " + docSnap.data())
    //     // result = docSnap.data().pw_salt;

    //     if (1==2) {
    //         return unFetchedStockArray;
    //     }
    //     else {
    //         return new Promise((resolve, reject) => {
    //             resolve({
    //                 unFetchedStockArray : unFetchedStockArray,
    //                 totalStockCount : totalStockCount,
    //                 test : "test 2022-04-14",  })
    //         })
    //     }
    // }

    // function timeout(delayMs) { 
    //     return new Promise( res => setTimeout(res, delayMs)); 
    // }

    // /*
    //  인덱스 지수 fetch & set API
    // * 2022.04.19
    // */
    // export async function setStockFetch() {
    //     let unFetchedStockArray;

    //     // 패치가 필요한 stock Name get
    //     await getStockFetchState()
    //         .then(result => {
    //             //console.log("result.hash : " + result.hash);
    //             console.log("result.stockArray : " + result.unFetchedStockArray);
    //             unFetchedStockArray = result.unFetchedStockArray;
    //         })
    //         .catch(result => {
    //             console.error("reject : " + result);
    //         })
    //     console.log(" ===== unFetchedStockArray: " + unFetchedStockArray[0])

        
    //     var docRef;
    //     let newData = {}
    //     for (var i = 0; i < unFetchedStockArray.length; i++) {
    //         console.log("unFetchedStockArray - i : " + i)
            
    //         if (i % 5 == 5) {
    //             await timeout(60000);   //1분간 대기 (AlphaVantage API 호출횟수 때문)
    //                                     // 5/minute, 500/day
    //         }
            
    //         //newData = await fetchStock(unFetchedStockArray[i])
    //         await fetchStock(unFetchedStockArray[i]).then((data) => {
    //             console.log("2022-04-19 fetchStock")
    //             newData = (data)
    //             console.dir(data, { depth: null })
    //         })

    //         // console.log("timeout START")
    //         // await timeout(5000);    //delay 5초주면 들어옴 --> 여기서 비동기, 동기 처리가 제대로 안되는듯
    //         // console.log("timeout END")

    //         console.log("########### newData ############");
    //         console.dir(newData, { depth: null })

    //         docRef = await setDoc(doc(db, "stock_data", unFetchedStockArray[i]), {
    //             monthly_adjusted_data : newData,
    //             //update_time : new Date(),
    //             update_time : new Date("2022-03-19"),
    //         });
    //     }

    //     return new Promise((resolve, reject) => {
    //         resolve({
    //             isEnd : true,
    //             test : "test 2022-04-16",  })
    //     }) 
    // }

    // /*
    //  * 주식 데이터 Fecth 함수
    //  * 2022.04.19
    // */
    // async function fetchStock(stockSymbol) {
    //     var resultData = { };
    //     const API_KEY = "CWKEZO3KHGLYJ9H0";
    //     let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${stockSymbol}&apikey=${API_KEY}`;
    //     let stockChartXValuesFunction = [];
    //     let stockChartYValuesFunction = [];

    //     try {
    //         const fetchData = await fetch(API_Call);
    //         const data = await fetchData.json()

    //         console.log(" ------------ fetch data *-------------- ");
    //         console.dir(fetchData, { depth: null });
    //         console.dir(data, { depth: null });

    //         let i = 0;
    //         let tsMonth
    //         for (var key in data['Monthly Adjusted Time Series']) {
    //             //월말 데이터를 기준으로 집계하기 때문에 현재 month의 데이터는 DB에 저장하지 않음.
    //             let tsMonth = key.substring(0, 7);
    //             if (tsMonth == common.getDateTime().substring(0,7)) {
    //                 continue;
    //             }

    //             stockChartXValuesFunction.push(tsMonth);
    //             stockChartYValuesFunction.push(data['Monthly Adjusted Time Series']
    //                                             [key]['5. adjusted close']);

    //             resultData[tsMonth] = data['Monthly Adjusted Time Series'][key]['5. adjusted close']
    //             i++
    //         }

    //         //alert("API Fetch END!!!");

    //         return resultData;
    //     } catch (err) {
    //         console.log("Failed to fetch StockData : "+err);
    //         return "Unknwon"
    //     }
    // }

    /*
    * 2022.03.30
    *
    export async function getBoard(id) {
        const q = query(collection(db, "Board"), where("board_id", "==", Number(id)));
        const docSnap = await getDocs(q);
        let result;

        docSnap.forEach((doc) => {
            //console.log("doc.id : " + doc.id)
            //console.log("doc.data : " + doc.data())
            result = doc.data();
        })
        const docData = {delete_time: '', is_deleted: false, category: '개발중', content: 'ㅁㅁㄴㄴㅇㅇㄹ\nㅓㅏㅣ\nㅏㅣ\n;ㅏㅣ\nㅏㅣ;\nㅏㅣ;ㅏ;ㅑㅕㅐ혀ㅑㅓ\n89ㅔ8[ㅕ9-]\nㅕㅑ[ㅗㅕㅏㅣ', create_time: '2022-02-03 22:30:16'};

        return new Promise((resolve, reject) => {
            resolve({
                data : result,
                fuck : "fuck",  })
        })
    }
    */

    /*
    * 암호화 키 get
    * 2022.03.30
    */
    export async function getSalt() {
        let result;
        const docRef = doc(db, "sy_config", "config")
        const docSnap = await getDoc(docRef);

        
        console.log("docSnap.id : " + docSnap.id)
        console.log("docSnap.data : " + docSnap.data())
        result = docSnap.data().pw_salt;

        return new Promise((resolve, reject) => {
            resolve({
                salt : result,
                test : "test 2022-03-30",  })
        })
    }

    /*
     * Google ReCaptcha SiteKey get
     * 2022.03.30 useEffect에서는 await 못쓰는듯 (에러뜸)
    */
    // export async function getReCaptchaSiteKey() {
    //     let result;
    //     const docRef = doc(db, "sy_config", "config")
    //     const docSnap = await getDoc(docRef);

    //     if (window.location.href.indexOf("localhost") > -1) {   
    //         //로컬호스트
    //         result = docSnap.data().recaptcha_local;
    //         console.log("============== 로컬 사이트 키 ==============");
    //     } 
    //     else {
    //         //웹
    //         result = docSnap.data().recaptcha_web;
    //         console.log("============== 웹 사이트 키 ==============");
    //     }
            
    //     console.log("docSnap.id : " + docSnap.id)
    //     console.log("docSnap.data : " + docSnap.data().recaptcha_local)
        

    //     return new Promise((resolve, reject) => {
    //         resolve({
    //             siteKey : result,
    //             test : "test 2022-03-30",  })
    //     })
    // }