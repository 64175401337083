import { documentId } from "firebase/firestore";
import { before, indexOf } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { inputState } from "Recoil/Atom";


const ReadOnlyRowAsset = ({ contact, handleTextChange }) => {
    /* *****************************************
     * 1. 변수선언
     * *****************************************/
    const yearMonth = useRef(null);
    const accountBalance1 = useRef(null);
    const accountBalance2 = useRef(null);
    const accountBalance3 = useRef(null);
    const deposit = useRef(null);
    const totalAsset = useRef(null);

    var preInputText = "";

    const [textAssetData, setTextAssetData] = useState(contact);

    //입력값
    const [inputs, setInputs] = useState({
        yearMonth: "",          // 계좌별 자산
        accountBalance1: "",    // A계좌
        accountBalance2: "",    // B계좌
        accountBalance3: "",    // C계좌
        deposit: "",            // 입금액
        totalAsset: ""          // 총자산
      });
      
    //유효성 검사
    const [validates, setValidates] = useState({
        isYearMonth: false,         // 계좌별 자산
        isAccountBalance1: false,   // A계좌
        isAccountBalance2: false,   // B계좌 확인
        isAccountBalance3: false,   // C계좌
        isDeposit: false,           // 입금액
        isTotalAsset: false,        // 총자산
    });

    //오류메세지
    const [inputMessage, setInputMessages] = useState({
        // idMessage: "2글자 이상 5글자 미만으로 입력해주세요.",                //아이디
        // passwordMessage: "2 ~ 5글자 미만으로 입력해주세요.",         //비밀번호
        // passwordConfirmMessage: '비밀번호가 달라요 다시 확인해주세요!',  //비밀번호 확인
        // nameMessage: '',             //닉네임
        // nicknameMessage: "공백없이 한글, 영문, 숫자만 입력가능 (한글2자, 영문4자 이상) 닉네임을 바꾸시면 앞으로 30일 이내에는 변경 할 수 없습니다.",         //닉네임
        // emailMessage: '',            //이메일

        yearMonthMessage: "",         // 계좌별 자산
        accountBalance1Message: "",   // A계좌
        accountBalance2Message: "",   // B계좌 확인
        accountBalance3Message: "",   // C계좌
        depositMessage: "",           // 입금액
        totalAssetMessage: "",        // 총자산
    });

    /* *****************************************
     * 2. 커스텀 function
     * *****************************************/

    /**
    * 그리드 셀 클릭 시 타입변환 (num to string, string To num)
    */
    function getCaret(el) {
        let caretAt = 0;
        const sel = window.getSelection();
        
        if ( sel.rangeCount == 0 ) { return caretAt; }
      
        const range = sel.getRangeAt(0);    
        const preRange = range.cloneRange();
        preRange.selectNodeContents(el);
        preRange.setEnd(range.endContainer, range.endOffset);
        caretAt = preRange.toString().length;
      
        return caretAt;
    }

    /**
     * 마우스 커서위치 설정
     */
    function setCaret(el, offset) {
        let sel = window.getSelection();
        let range = document.createRange();

        //console.log("ReadOnlyRowAsset - setCaret, offset : " + offset)
        
        // yearMonth는 el.childNodes[0] 호출시 0번째 데이터가 없어서 에러터짐
        if (el.childNodes.length != 0) {
            range.setStart(el.childNodes[0], offset);
        }
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    }

    
    function getClassName(value) {
        //console.dir(accountBalance1.current, { depth: null })
        //console.log("getClassName - value : " + value + ",   tpye: "+typeof value);

        var className = "";
        value = value.replaceAll(',', '');

        if (value === "") {
            className = 'zero'
        }
        else if (Number(value) > 0) {
            className = 'plus'
        } else if (Number(value) < 0) {
            className = 'minusClass'
        }
        
        return className
    }

    function getRef(name) {
        var ref;
        switch(name) {
            case "yearMonth": 
                ref = yearMonth;
                break;
            case "accountBalance1": 
                ref = accountBalance1;
                break;
        
            case "accountBalance2": 
                ref = accountBalance2;
                break;
            case "accountBalance3": 
                ref = accountBalance3;
                break;
            case "deposit": 
                ref = deposit;
                break;
            case "totalAsset": 
                ref = totalAsset;
                break;
        }
        return ref
    }

    /**
     * 숫자로 보이기 ( , ▲  ▼ 떼주기, minusClass는 '-' 부호 붙여주기)
     */
    function textAssetDataToNum(e) {
        e.preventDefault();

        const fieldName = e.target.getAttribute('name');
        var fieldValue = e.target.textContent.replaceAll(',', '');
        fieldValue = fieldValue.replaceAll('-', '');

        if (fieldName === "yearMonth") {
            return 0;
        } else if (e.target.className === "minusClass") {
            fieldValue = "-" + fieldValue;
        }
        var newFormData = { ...textAssetData };
        //var newFormData = JSON.parse(JSON.stringify(textAssetData));

        newFormData[fieldName] = fieldValue;
        setTextAssetData(newFormData);

        //console.log("fieldName ===> " + fieldName);
        //console.log("e.target.textContent ===> " + fieldValue);
    }

    /**
     * 문자열로 보이기 (',', ▲, ▼ 붙여주기)
     */
    function textAssetDataToFormat(e) {
        e.preventDefault();

        const fieldName = e.target.getAttribute('name');
        var fieldValue;
        
        // console.log("fieldName : " + fieldName)
        //console.log("fieldValue : " + e.target.textContent)

        if (fieldName == "yearMonth") {
            fieldValue = e.target.textContent;
        } else {
            fieldValue = e.target.textContent.format();
        }

        var newFormData = { ...textAssetData };
        newFormData[fieldName] = fieldValue;
        
        //if (fieldName !== "yearMonth") {  //--> 2023.05.01 이건 왜 해준거지..? 이거땜에 입력 시 자잘한 에러 발생한거같음
            setTextAssetData(newFormData);
        //}
        
        //newFormData는 화면에 컴마를 찍어주기 위한 배열이므로
        //SetState 실행 전에 컴마를 다 떼줘야함.
        var newData = Object.entries(newFormData).reduce((acc, [key, value]) => {
            //value 타입이 Strnig이면 replace 실행, else 원래 값 유지
            acc[key] = typeof value === 'string' ? value.replaceAll(',', '') : value;
            return acc;
          }, {});

        handleTextChange(newData);
    }

    /**
    * 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    * onFocus()
    */ 
    Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }

        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return n;
    };
    
    /**
    * 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    * onBlur()
    */
    String.prototype.format = function() {
        var num = parseFloat(this);

        if( isNaN(num) ) {
            return "0";
        }

        if (num.format().toString().startsWith('-')) {
            num = num.format().toString()
        } else {
            num = num.format().toString().replace('-', '')
        }

        return num;
    };

    /* *****************************************
     * 3. 이벤트
     * *****************************************/
    
    /*
    *   키입력 시 이벤트 발생 순서 onFocus --> onKeyDown --> onInput --> onBlur
    */
    function onFocus(e) {
        //console.log("onFocus")

       if (e.target.getAttribute("name") !== "yearMonth") {
           //숫자로 보이기 (',', ▲, ▼ 떼주기)
           textAssetDataToNum(e);
       }

       // 커서위치 마지막으로 이동
       var ref = getRef(e.target.getAttribute("name"))
       setCaret(ref.current, ref.current.textContent.length);
       
       // console.log("ref.current.textContent : " + ref.current.textContent);
       // console.log("ref.current.textContent.length : " + ref.current.textContent.length)
   }

    function onKeyDown(e) {
        //console.log("onKeyDown");

        if (e.key == 'Enter') {
            e.target.blur();
            return;
        }

        preInputText = e.target.textContent;
    }

    function onInput(e) {
        //console.log("onInput");
        const ref = getRef(e.target.getAttribute("name"));  
        var currentCaret = getCaret(ref.current) - 1;     // 커서 마지막위치

        //yearMont의 MaxLenth = 7
        if (e.target.getAttribute("name") === "yearMonth") {
            if (e.target.textContent.length > 7) {
                
                ref.current.textContent = preInputText;
                setCaret(ref.current, currentCaret);
            }
        }

        // 입력값 제어
        // const {textContent, name} = e.target;   // 우선 e.target 에서 name 과 textContent 를 추출
        const textContent = e.target.textContent;
        const name = e.target.getAttribute("name");
        // console.log("textContent : " + textContent)
        // console.log("name : " + name)
        

        setInputs({
            ...inputs,              // 기존의 input 객체를 복사한 뒤
            [name] : textContent    // name 키를 가진 값을 textContent 로 설정
        });


        switch(name) {
            // 정규식 테스트 사이트
            // https://regexr.com/

            case "yearMonth":
                const yearMonthRegex = /^\d{0,4}-{0,1}(0[1-9]{0,2}|1[012]{0,2}){0,2}$/

                if (!yearMonthRegex.test(e.target.textContent)) {
                    setValidates({ ...validates, isYearMonth : false })
                    setInputMessages({ ...inputMessage
                        , yearMonthMessage : "ex) 2022-08 이와 같은 형식으로 작성해주십시오." })
                    ref.current.textContent = preInputText;
                    setCaret(ref.current, currentCaret);
                    console.log("잘못된 형식")
                } else {
                    setValidates({ ...validates, isYearMonth : true})
                    setInputMessages({ ...inputMessage
                        , yearMonthMessage : "올바른 형식입니다." })
                    console.log("올바른 형식")
                }
                break;
            case "accountBalance1":
                const account1Regex = /^\d{0,16}$/

                if (!account1Regex.test(e.target.textContent)) {
                    setValidates({ ...validates, isAccountBalance1 : false })
                    setInputMessages({ ...inputMessage
                        , accountBalance1Message : "16자리 이하의 숫자만 입력가능합니다." })
                    ref.current.textContent = preInputText;
                    setCaret(ref.current, currentCaret);
                    console.log("잘못된 형식")
                }
                break;
            case "accountBalance2":
                const account2Regex = /^\d{0,16}$/

                if (!account2Regex.test(e.target.textContent)) {
                    setValidates({ ...validates, isAccountBalance2 : false })
                    setInputMessages({ ...inputMessage
                        , accountBalance2Message : "16자리 이하의 숫자만 입력가능합니다." })
                    ref.current.textContent = preInputText;
                    setCaret(ref.current, currentCaret);
                    console.log("잘못된 형식")
                }
                break;
            case "accountBalance3":
                const account3Regex = /^\d{0,16}$/

                if (!account3Regex.test(e.target.textContent)) {
                    setValidates({ ...validates, isAccountBalance3 : false })
                    setInputMessages({ ...inputMessage
                        , accountBalance3Message : "16자리 이하의 숫자만 입력가능합니다." })
                    ref.current.textContent = preInputText;
                    setCaret(ref.current, currentCaret);
                    console.log("잘못된 형식")
                }
                break;
            case "deposit":
                const depositRegex = /^[+-]?\d{1,16}$/

                if (!depositRegex.test(e.target.textContent)) {
                    setValidates({ ...validates, isDeposit : false })
                    setInputMessages({ ...inputMessage
                        , depositMessage : "16자리 이하의 숫자만 입력가능합니다." })
                    ref.current.textContent = preInputText;
                    setCaret(ref.current, currentCaret);
                    console.log("잘못된 형식")
                }
                break;
        }

    }

    function onBlur(e) {
        //console.log("onBlur")

        // 변경된 state 적용
        textAssetDataToFormat(e);

        /**
         * 2023.02.11
         * 필요하면 하고, 당장은 필요없음 (저장 후 리로드)
         * 
         * .then()으로 assetState가 바뀔떄마다 
         * profit도 바로바로 값을 계산하고 변경
         */
    }


    // window.onload = function(){
    //     console.log("onload end")
    // }
    
    /* *****************************************
     * 4. OnLoad
     * *****************************************/

    useEffect(() => {
        // if (contact.yearMonth == "2023-03") {
        //     console.log("[ReadOnlyRowAsset - useEffect [contact]] ... 2023-03")
        //     console.dir(contact, {depth:null})
        // }

        const newFormData = { ...textAssetData };
        newFormData["accountBalance1"] = contact.accountBalance1.format();
        newFormData["accountBalance2"] = contact.accountBalance2.format();
        newFormData["accountBalance3"] = contact.accountBalance3.format();
        newFormData["deposit"] = contact.deposit.format();
        newFormData["totalAsset"] = contact.totalAsset.format();
        setTextAssetData(newFormData);
    }, [contact])

    useEffect(() => {
        // 조회 시 숫자 format() 적용
        // console.log("-----> format()")
        // console.log("ReadOnlyRowAsset accountBalance1: " + contact.accountBalance1)
        // console.log("ReadOnlyRowAsset accountBalance2: " + contact.accountBalance2)
        // console.log("ReadOnlyRowAsset accountBalance3: " + contact.accountBalance3)
        // console.log("ReadOnlyRowAsset deposit: " + contact.deposit)
        // console.log("ReadOnlyRowAsset totalAsset: " + contact.totalAsset)

     }, [])

    return (
        <tr style={{height: '24px'}}>
            <td style={{ textAlign: 'center', width: '100px', }}
                name='yearMonth'    //계좌별 자산
                ref={yearMonth}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onInput={onInput}
                >{textAssetData.yearMonth}</td>
            <td style={{ 
                    textAlign: 'right',
                }}
                name='accountBalance1'
                ref={accountBalance1}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onInput={onInput}
                onBlur={onBlur}
                >{textAssetData.accountBalance1}</td>
            <td 
                style={{ 
                    textAlign: 'right',
                }}
                name='accountBalance2'
                ref={accountBalance2}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onInput={onInput}
                onBlur={onBlur}
                >{textAssetData.accountBalance2}</td>
            <td 
                style={{ 
                    textAlign: 'right',
                }}
                name='accountBalance3'
                ref={accountBalance3}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onInput={onInput}
                onBlur={onBlur}
                >{textAssetData.accountBalance3}</td>
            <td 
                style={{ 
                    textAlign: 'right',
                }}
                name='deposit'
                ref={deposit}
                className={getClassName(contact.deposit)}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onInput={onInput}
                onBlur={onBlur}
                >{textAssetData.deposit}</td>
            <td 
                style={{ 
                    textAlign: 'right',
                }}
                name='totalAsset'
                ref={totalAsset}
                suppressContentEditableWarning={true}
                >{textAssetData.totalAsset}</td>
        </tr>
    )
}

export default ReadOnlyRowAsset;