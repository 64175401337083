// reducers/counter.js

// reducer가 많아지면 action상수가 중복될 수 있으니
// 액션이름 앞에 파일 이름을 넣습니다.
export const LOGIN = "USERINFO/LOGIN";
export const LOGOUT = "USERINFO/LOGOUT";
export const SET_ACCOUNT = "USERINFO/ACCOUNT";
export const SETUSERINFO = "USERINFO/SETUSERINFO";

//export const login = userInfo => ({ type: LOGIN, isLogin });
export function login(userInfo) {
  return {
    type: LOGIN,
    userInfo: userInfo
  }
}

//export const logout = isLogin =>  ({ type: LOGOUT, isLogin });
export function logout(isLogin) {

  // local storage 삭제
  window.localStorage.removeItem("userInfo");   

  return { 
    type: LOGOUT, isLogin 
  }
}


export function setUserInfo(userInfo) {
  return {
    type: SETUSERINFO,
    userInfo: userInfo
  }
}


export function setAccount(hasAccount) {
  return {
    type: SET_ACCOUNT,
    hasAccount: hasAccount
  }
}

const initalState = {
  
  userInfo: {
    user_id: '',
    name: '',
    nickname: '',
    auth: '',
    isLogin: false,
    hasAccount: false,
  }
  
};

const userInfo = (state = initalState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case LOGOUT:
      return {
        ...state,
        userInfo: {
          user_id: '',
          name: '',
          nickname: '',
          auth: '',
          isLogin: false,
          hasAccount: false,
        }
      };
    case SET_ACCOUNT:
      return {
          ...state,
          userInfo: {
            ...state.userInfo,
            hasAccount: action.hasAccount
          }
      };
    case SETUSERINFO:
      return {
        ...state,
        //userInfo: action.userInfo
      }

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};

export default userInfo;