// reducers/counter.js

// reducer가 많아지면 action상수가 중복될 수 있으니
// 액션이름 앞에 파일 이름을 넣습니다.
export const INCRESE = "COUNT/INCRESE";
export const MUL = "COUNT/MUL";

export const increseCount = count => ({ type: INCRESE, count });
export const mulTest = test => ({ type: MUL, test });

const initalState = {
  count: 0,
  test: 100,
};

const counter = (state = initalState, action) => {
  switch (action.type) {
    case INCRESE:
      return {
        ...state,
        count: state.count + 1
      };
    case MUL:
      return {
        ...state,
        test: state.test * 2
      }

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};

export default counter;