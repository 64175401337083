import React, { useState, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

const ReadOnlyRowSnowInfo = ({ contact, bgColor }) => {

    const [textAssetData, setTextAssetData] = useState(contact);
    
    // onFocus 이벤트 발생 시 blur처리
    // contentEditable = true로 안하면 좌우 테이블의 행크기가 달라지기 때문
    function handlerNoInput(e) {
        e.target.blur();
    }

    function getClassName(value, text) {
        var className = "";

        //console.dir(accountBalance1.current, { depth: null })
        //console.log(value);

        // // ▲ ▼
        // //https://www.w3schools.com/cssref/tryit.asp?cssenti=25B2

        if (text == "profit_month") {
            if (value == "") {
                className = 'zero'
            }
            else if (Number(value) > 0) {
                className = 'plus'
            } 
            else if (Number(value) < 0) {
                className = 'minusClass'
            }
        }
        else if (text == "deposit") {
            if (value == "") {
                className = ''
            }
            else if (Number(value) > 0) {
                className = ''
            } 
            else if (Number(value) < 0) {
                className = 'minusClassRoundBracket'
            }
        } 
        else if (text == "profit_year" || text == "profit_total") {
            if (value == "") {
                className = ''
            }
            else if (Number(value) > 0) {
                className = 'plusClassRoundBracket'
            } 
            else if (Number(value) < 0) {
                className = 'minusClassRoundBracket'
            }
        }
        else {
            if (Number(value) < 0) {
                className = 'minusClass'
            }
        }
        
        return className
    }

    // 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }
        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return n;
    };
        
    // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    String.prototype.format = function() {
        var num = parseFloat(this);
        if( isNaN(num) ) {
            return "0";
        }

        return num.format().toString().replace('-', '');
    };

    useEffect(() => {
        // 조회 시 숫자 format() 적용

        const newFormData = { ...textAssetData };
        newFormData["year"] = contact.year;
        newFormData["month"] = contact.month;
        newFormData["totalAsset_start"] = contact.totalAsset_start.format();
        newFormData["totalAsset_end"] = contact.totalAsset_end.format();
        newFormData["deposit"] = contact.deposit.format();
        newFormData["profit_month"] = contact.profit_month.format();
        newFormData["profit_year"] = contact.profit_year.format();
        newFormData["profit_total"] = contact.profit_total.format();
        setTextAssetData(newFormData);

     }, [])

    return (
        <tr>
            <td 
                style={{ 
                    textAlign: 'center',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '60px'
                }}
                onFocus={handlerNoInput}
                suppressContentEditableWarning={true}
                >{contact.year}</td>
            <td
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '45px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.month}</td>
            <td
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '100px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.totalAsset_start}</td>
            <td 
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '100px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.totalAsset_end}</td>
            <td 
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '120px'
                }}
                className={getClassName(contact.deposit, "deposit")}
                suppressContentEditableWarning={true}
                >{textAssetData.deposit}</td>
            <td 
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '120px'
                }}
                className={getClassName(contact.profit_month, "profit_month")}
                suppressContentEditableWarning={true}
                >{textAssetData.profit_month}</td>
            <td 
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '120px'
                }}
                className={getClassName(contact.profit_year, "profit_year")}
                suppressContentEditableWarning={true}
                >{textAssetData.profit_year}</td>
            <td 
                style={{ 
                    textAlign: 'right',  
                    background: contact.bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '120px'
                }}
                className={getClassName(contact.profit_total, "profit_total")}
                suppressContentEditableWarning={true}
                >{textAssetData.profit_total}</td>
        </tr>
    )
}

export default ReadOnlyRowSnowInfo;