import { initializeApp } from 'firebase/app';
import { getAuth} from "firebase/auth";
import { getFirestore} from "firebase/firestore";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
//import * as firebase from "firebase"

//firebaseConfig 질문..
//https://sso-feeling.tistory.com/452

const firebaseConfig = {
    //...
  apiKey: "AIzaSyDniSY3rbDRjYu86EgAH6rgcV6iGLpZZ4Y",
  authDomain: "alpha-who.firebaseapp.com",
  projectId: "alpha-who",
  storageBucket: "alpha-who.appspot.com",
  messagingSenderId: "884680144155",
  appId: "1:884680144155:web:9432fbd81f1cba02b80fbe",
  measurementId: "G-7XPV62B3VQ",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
//const db = firebase.firestore();

//v3 사이트키: 6Lf6uBIfAAAAALZ3gPRRfm-oxg5kiYiJvkADtqKe
//v3 비밀키: 6Lf6uBIfAAAAAMcwskqQ1r585hApIhl14_j95e-7

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV2Provider('6Lf-JAkfAAAAAF_11vsi6qubxXGQmn5Mg56yysgJ'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true
// });

//export default auth
export {auth, db}