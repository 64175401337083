import { functionsIn, truncate } from 'lodash';
import React, { Component, useState, useEffect } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth, db } from "fb.js";

import { Button, Header, Icon, Image, Modal, Form, Dimmer, Loader, Segment} from 'semantic-ui-react'

import * as common from "awUtils/commonFunction"
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
import { LabelList } from 'recharts';

//새로고침하면 로그인 풀리는거 해결하기.
//https://blog.javabom.com/minhee/session/storage/localstorage-sessionstorage/react

const Modal_SaveAsk = ({onClose}) => {
  const [modalResult, setModalResult] = useState();

  // dispatch를 사용하기 위한 준비
  const dispatch = useDispatch();

  // Redux에 접근하여 state 가져오기
  const { userInfo } = useSelector(state => state.userInfo);
  // const [ isLogin, setIsLogin ] = useState(false);
  // const [ userInfo, setUserInfo ] = useState({
  //   auth: '',
  //   id: '',
  //   name: '',
  //   nickname: '',
  // });

  useEffect(() => {

  }, [])

  return (
    <>
        <Modal
          basic
          open={true}
          size='small'
        >
          <Header icon>
                {/* <Dimmer active> */}
                    <Loader size='medium'>Loading</Loader>
                {/* </Dimmer> */}

                {/* <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' /> */}
            {/* Loading... !!! */}
          </Header>
        </Modal>
    </>
  );
}

export default Modal_SaveAsk;