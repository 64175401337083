/* eslint-disable */    //waring 제거
import React from "react";
import {Component} from "react/cjs/react.production.min";
import {Button, Comment, Form, Header, Icon, Pagination, Grid} from "semantic-ui-react";
import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import _ from "lodash";

import hs1 from "../HS1.jpg";
import hs2 from "../HS2.jpg";
import {db} from "../fb.js";

function SingleComment(detail) {
    return (
        <Comment style = {{padding : 10}}>
            <Comment.Avatar as="a" src={hs1}/>
            <Comment.Content>
                <Comment.Author as="a">
                    {detail.info.userName}
                </Comment.Author>
                <Comment.Metadata>
                    <div>{detail.info.time}</div>
                </Comment.Metadata>
                <Comment.Text>{detail.info.content}</Comment.Text>
                <Comment.Actions>
                    <a onClick={ async () => {
                        console.log("detail.info.userName : " + detail.info.userName);
                        console.log("detail.userName : " + detail.userName);
                        //댓글에 등록된 이름 == 로그인된 이름
                        if (detail.info.userName == detail.userName && detail.userName != "방문자") {
                            await deleteDoc(doc(db, "comments", detail.info.id)).then(res => alert("삭제 되었습니다. \n새로고침 해보세요"));
                        } else {
                            alert("본인이 작성한 댓글만 삭제할 수 있습니다.")
                        }
                    }}>삭제</a>
                    <a onClick={ async () => {
                        if (detail.info.userName == detail.userName && detail.userName != "방문자") {
                            detail.selectComment(detail.index, detail.info.content, detail.info.id)
                        } else {
                            alert("본인이 작성한 댓글만 수정할 수 있습니다.")
                        }
                    }}>수정</a>
                </Comment.Actions>
            </Comment.Content>
        </Comment>
    )
}


class Comments extends React.Component {
    constructor() {
        super();
        this.state = {
            visitors: "",
            inputContent: "",
            inputTime: "",
            inputId: "",
            userName: "",
            commentsList: [],
            formLocation: -1
        };
    }

    selectComment = (num, content, id) => {this.setState({formLocation : num, inputContent : content, inputId : id})}

    componentDidMount = async () => {
        const querySnapshot = await getDocs(collection(db, "comments"));
        let comments = [];
        var i = 0;
        querySnapshot.forEach((doc) => {
            //console.log(doc.data());
            //console.log(Object.assign(doc.data(), { id : doc.id }).content.replace("\n", "<br>"));
            comments.push(Object.assign(doc.data(), { id : doc.id}))
            //comments[i].content = comments[i].content.replace("\n", "\r\n")
            i++;
        })

        console.log("i: " + i)

            
        this.setState({
            commentsList : comments,
            visitors : this.props.visitors
        })

        console.log("comment: "+comments)
        console.log("COMENTS LIST: "+this.state.commentsList)

        // this.setState({
        //     commentsList : this.state.commentsList.concat({comments})
        // })

        // db.collection("comments").get().then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         console.log(doc.data());
        //     });
        // });
    }

    render() {
        console.log("this.state.commentsList")
        console.log(this.state.commentsList)
        console.log("this.state")
        console.log(this.state)

        
        console.log("this.state. commentsList.length =====> "+this.state. commentsList.length)
        console.log("this.state. commentsList =====> "+this.state. commentsList.detail)
        return (
            <Comment.Group
                threaded="threaded"
                style={{
                    margin: "Auto"
                }}>
                <Header as="h3">
                    Comments
                </Header>
                
                {
                    _.orderBy(this.state.commentsList, "time", "desc")
                        .map((comments, index) => 
                            <div>
                                <SingleComment info={comments} userName = {this.props.userName} selectComment = {this.selectComment} index = {index}/>
                                {this.state.formLocation == index ? (<Form reply="reply">
                    <Form.TextArea
                        value={this.state.inputContent}
                        placeholder={"댓글을 입력해주세요."}
                        onChange={(e) => this.setState({inputContent: e.target.value})}/>
                    <Button
                        content="Edit Reply"
                        labelPosition="left"
                        icon="edit"
                        secondary
                        onClick={() =>  {
                            if (this.state.inputContent != "") {
                                this.setState((prevState) => {
                                    let newArr = _.filter(prevState.commentsList, (comments) => comments.id != this.state.inputId)
                                    let newComment = {
                                        content: this.state.inputContent,
                                        time: moment().format("YYYY-MM-DD HH:mm"),
                                        userName: this.props.userName
                                    } 
                                    
                                    console.log("LOG: "+ newComment[newComment.length-1])
                                     
                                    return {
                                        commentsList: [
                                            ...newArr,
                                            newComment
                                        ],
                                        inputContent : "",
                                        formLocation : -1
                                    }
                                }, async() => await updateDoc(doc(db, "comments", this.state.inputId), this.state.commentsList[this.state.commentsList.length-1]) )
                            }
                            else {
                                alert("내용을 입력해주세요.");
                            }
                        }}/>
                </Form>) : null}
                                        </div>)
                }
                
                {this.state.formLocation == -1 ? <Form reply="reply">
                    <Form.TextArea
                        value={this.state.inputContent}
                        placeholder={"댓글을 입력해주세요."}
                        onChange={(e) => this.setState({inputContent: e.target.value})}/>
                    <Button
                        content="Add Reply"
                        labelPosition="left"
                        icon="add"
                        primary="primary"
                        onClick={() =>  {
                            if (this.state.inputContent != "") {
                                this.setState((prevState) => {
                                    let newComment = {
                                        content: this.state.inputContent,
                                        time: moment().format("YYYY-MM-DD HH:mm"),
                                        userName: this.props.userName
                                    }
                                    
                                    console.log("LOG: "+ newComment[newComment.length-1])
                                     
                                    return {
                                        commentsList: [
                                            ...prevState.commentsList,
                                            newComment
                                        ],
                                        inputContent: ""

                                    }
                                }, async() => await addDoc(collection(db, "comments"), this.state.commentsList[this.state.commentsList.length-1]) )
                            }
                            else {
                                alert("내용을 입력해주세요.");
                            }
                        }}/>
                </Form> : null}

                <br/>
                <br/>
                <Grid centered>
                    <Pagination
                        //activePage={activePage}
                        //onPageChange={this.handlePaginationChange}
                        totalPages={5}
                    />
                </Grid>
            </Comment.Group>
        );
    }
}

export default Comments;
