/*
 * 게시글 상세내용 (등록, 수정, 삭제, 댓글작성)
 */

import React, { useEffect, useState } from "react";
import { getPostByNo } from "../../db/Data";
import { Link } from "react-router-dom";
import { withRouter, useParams, useNavigate } from "react-router-dom";

import "./Post.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "fb.js";

import {
  Image,
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  TextArea,
} from "semantic-ui-react";
import { toNumber } from "lodash";

const PostAdd = ({ mode }) => {
  const { id } = useParams();
  //const [ data, setData ] = useState({});
  const [board, setBoard] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  let navigate = useNavigate();

  //https://velog.io/@kcdoggo/Cannot-read-property-params-of-undefined-%EC%97%90%EB%9F%AC
  // react-router-dom 6 버전부터는 아래와 같은 route props로
  // (history, location, match) 파라미터를 넘겨받지 않는다.
  //const { no } = match.params;

  //https://velog.io/@soryeongk/ReactRouterDomV6

  async function getReply() {
    //console.log("no : " + no)
    const q = query(
      collection(db, "Reply"),
      where("board_id", "==", Number(id))
    );
    const docSnap = await getDocs(q);

    docSnap.forEach((doc) => {
      setBoard(doc.data());
    });
  }

  async function savePost() {
    let newBoardId;

    try{
      const querySnapshot = query(collection(db, "Board"), orderBy("board_id", "desc"), limit(1));
      const docSnap = await getDocs(querySnapshot);

      docSnap.forEach((doc) => {
        newBoardId = doc.data().board_id + 1;
        //console.log("docSnap.board_id ========> " + newBoardId);
      })

      const docRef = await addDoc(collection(db, "Board"), {
        board_id : newBoardId,
        title : title,
        content : content,
        nickname : "Admin",
        is_deleted : false,
        create_time : getDateTime(),
        update_time : getDateTime(),
        delete_time : "",
        reply_count : 0,
        category : "개발중",
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      alert("저장되었습니다.");
      //navigate("/PostMain")
      navigate("/PostView/" + newBoardId)
    }
    
  }

  function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  }

  //현재 시간 구하기
  function getDateTime() {
    var today = new Date(); 
    today.setHours(today.getHours() + 9); 
    today = today.toISOString().replace('T', ' ').substring(0, 19);
    
    return today
  }

  useEffect(() => {
    console.log("useEffect");
    //setData(getPostByNo(no));
    //getBoard();
    console.log("mode: " + mode);
  }, []);

  return (
    <>
      {/* <h2 align="center">게시글 상세정보</h2> */}

      <h2>ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ PostAdd ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ</h2>

      {board ? (
        <div align="center">
          <label>[Admin]　</label>
          <label>게시글번호: {id}</label>
          <label>　|　</label>
        </div>
      ) : (
        "aaaa"
      )}
      {/* css로 처리해야하릇 */}

      <div className="post-view-wrapper" style={{ whiteSpacae: "pre-wrap" }}>
          <>
            <div className="post-view-title">
              <div className="">
                <label htmlFor="title" style={{ marginRight: "30px" }}>
                  제목
                </label>
                <input
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ padding: "5px", width: "90%" }}
                ></input>
              </div>
            </div>
            <div className="post-view-top">
              <Icon name="list" />
              <label>잡담&고민</label>
            </div>
            <div className="post-view-row">
              <TextArea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                style={{
                  width: "100%",
                  height: "300px",
                }}
              >
                {/* {board.content && (
                  <pre>
                    {" "}
                    {
                      // 개행문자를 기준으로 문자를 잘라(split) 배열로 만들고
                      //배열 사이사이 <br />태그를 넣어 뿌려줘서 개행을 넣은 효과를 내준다.
                      board.content.split("\n").map((line) => {
                        return (
                          <span>
                            {line}
                            <br />
                          </span>
                        );
                      })
                    }
                  </pre>
                )} */}
              </TextArea>
            </div>

            <Button
              className="right-box"
              content="취소"
              onClick={() => navigate("/PostMain")}
            ></Button>
            <Button
              className="right-box"
              content="저장"
              primary="primary"
              onClick={ (e) => savePost() }
            ></Button>
          </>
        <br />
        <br />
      </div>
    </>
  );
};

export default PostAdd;
