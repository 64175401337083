import React, { useState, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

const ReadOnlyRowProfit = ({ contact, handleCheckChange }) => {

    const [textAssetData, setTextAssetData] = useState(contact);
    
    // onFocus 이벤트 발생 시 blur처리
    // contentEditable = true로 안하면 좌우 테이블의 행크기가 달라지기 때문
    function handlerNoInput(e) {
        e.target.blur();
    }

    function getClassName(value) {
        var className = "";

        //console.dir(accountBalance1.current, { depth: null })
        //console.log(value);

        // // ▲ ▼
        // //https://www.w3schools.com/cssref/tryit.asp?cssenti=25B2

        if (value === "") {
            className = 'zero'
        }
        else if (Number(value) > 0) {
            className = 'plus'
        } else if (Number(value) < 0) {
            className = 'minusClass'
        }
        
        return className
    }

    // 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }
        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return n;
    };
        
    // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    String.prototype.format = function() {
        var num = parseFloat(this);
        if( isNaN(num) ) {
            return "0";
        }

        return num.format().replace('-', '');
    };

    useEffect(() => {
        // 조회 시 숫자 format() 적용
        const newFormData = { ...textAssetData };
        newFormData["accountProfit1"] = contact.accountProfit1.format();
        newFormData["accountProfit2"] = contact.accountProfit2.format();
        newFormData["accountProfit3"] = contact.accountProfit3.format();
        newFormData["grossProfit"] = contact.grossProfit.format();
        setTextAssetData(newFormData);

     }, [contact])

    useEffect(() => {

     }, [])

    return (
        <tr style={{height: '24px'}}>
            <td style={{textAlign: 'center', width: '30px'}}
             suppressContentEditableWarning={true}  //공백 Warnning 안뜨게 하기 --> 근데도 계속 에러뜸.. ㅜㅜ - 2023.02.11
             >
            <Checkbox
                checked={contact._chk}
                onChange={(e, data) => handleCheckChange(data.checked, contact.id)}
            />
            </td>
            <td style={{textAlign: 'center', width: '100px'}} onFocus={handlerNoInput} suppressContentEditableWarning={true}>
                {contact.yearMonth} {/* 월별 투자손익 */}
            </td>
            <td style={{textAlign: 'right', width: 'auto'}} className={getClassName(contact.accountProfit1)} suppressContentEditableWarning={true}>
                {textAssetData.accountProfit1}
            </td>
            <td style={{textAlign: 'right', width: 'auto'}} className={getClassName(contact.accountProfit2)} suppressContentEditableWarning={true}>
                {textAssetData.accountProfit2}
            </td>
            <td style={{textAlign: 'right', width: 'auto'}} className={getClassName(contact.accountProfit3)} suppressContentEditableWarning={true}>
                {textAssetData.accountProfit3}
            </td>
            <td style={{textAlign: 'right', width: 'auto'}} className={getClassName(contact.grossProfit)} suppressContentEditableWarning={true}>
                {textAssetData.grossProfit}
            </td>
        </tr>
    )
}

export default ReadOnlyRowProfit;