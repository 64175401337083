//아래 링크 보고 만듬
//https://www.youtube.com/watch?v=dYjdzpZv5yc&list=PL67KSqKuOe5thV9nf2ZozdJIhCgQetN0x&index=1

//코드 포맷팅 익스텐션
//https://onlydev.tistory.com/60

//React 크롬 개발자도구
//https://reference-m1.tistory.com/171


/*
 * 0. import
*/
import React, { Component, useEffect, useState, useRef , Fragment } from 'react';
import styled from 'styled-components';
import { collection, doc, getDoc, getDocs, updateDoc, addDoc, deleteDoc, query, where, limit, orderBy } from "firebase/firestore";
import {db} from "fb.js";
import {
    LineChart, Line, XAxis, YAxis, tesianGrid, Tooltip, Legend, CartesianGrid
  } from 'recharts';
import Plot from 'react-plotly.js';
import Modal_Progress from 'body/Modal_Progress.js';

import { ResponsiveLine } from '@nivo/line';
//import {fdr} from 'finance-datareader';
import def_dataProfit from "db/month-profit-data.json";
import def_dataAsset from "db/month-asset-data.json";
import def_dataChart from "db/snowball-dataChart.json";     // 2023.04.02 안씀
import def_stockList from "db/stockList.json";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
import {
  Image,
  Dimmer,
  Loader,
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  //Input,
  TextArea,
  Form,
  Segment,
  GridColumn,
  GridRow,
  Label,
  Table,
  Checkbox,
} from "semantic-ui-react";
import { isNull } from 'lodash';

const IndexChart = () => {
    const [sy_user_docId, setSyUserDocId] = useState("NULL");
    const [showProgressModal, setShowProgressModal] = useState(false);

    // store에 접근하여 state 가져오기
	const { isLogin, userInfo } = useSelector(state => state.userInfo);

    // 자동완성 검색기능
    /**
     * 2. 드롭다운 크기 제한
     * 3. 드롭다운 크기 max 도달 시 스크롤바 생성
     * 4. 관리자 전용 체크 버튼 (allTextArray랑 DB의 DOC_ID랑 빠진거나, DB에는 없는데 소스에만 있는것들 찾아내기)
     *    4.1 DB에 빠진애들은 추가해주기
     * 5. 한글 자음/모음 따로 검색
     * 6. 한글 초성 검색
     */
    const [inputValue, setInputValue] = useState('');
    const allTextArray = def_stockList.map(item => item.toLocaleUpperCase()).sort();
    // const allTextArray2 = [
    //                         {'name' : 'TSLA', 'korName' : '테슬라', 'code' : '111111'}, 
    //                         {'name' : 'QQQ', 'korName' : '', 'code' : '111112'},
    //                         {'name' : 'SPY', 'korName' : '', 'code': '111113'},
    //                         {'name' : 'ARKK', 'korName' : '', 'code' : '222222'},
    //                         {'name' : 'TAAA', 'korName' : '테스트AAA', 'code' : '222233'},
    //                         {'name' : 'TBBB', 'korName' : '테스트BBB', 'code' : '222244'},
    //                         {'name' : 'TCCC', 'korName' : '테스트CCC', 'code' : '333333'}
    //                     ]

    const [isHaveInputValue, setIsHaveInputValue] = useState(false)
    const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)
    const [dropDownList, setDropDownList] = useState(allTextArray)

    
	//https://quant.stackexchange.com/questions/46209/using-alphavantage-for-japan-shanghai-hong-kong-shenzhen-stock-exchange-data
	//위처럼 타 거래소도 지원하는거 같은데, 코스피 티커를 몰라서 못하는중. ex) 6758.T (도쿄증권거래소)
	//096770.KS --> 안됨..
                        //회색,    					연두
    // 색상 사이트      https://colors.eva.design/
    const colorArray = ['#888888', 'red', 'blue', '#00CC00', '#FF33FC', '#ADC8FF', '#33B7DB', '#FFAD76', '#90FFFC', '#920BBF', '#FFFF33',];
    const colorIndexRef = useRef(0);
    const countCallUseEffectChart = useRef(0);
    const [chartLoading, setChartLoading] = useState(false);
    const [stockData, setStockData] = useState([]);		//차트에 그려줄 데이터
    const [stockData2, setStockData2] = useState([]);	//차트에 그려줄 데이터2
    const refStockData = useRef([]);
    const [monthCount, setMonthCount] = useState(0);     //차트

    const [stockNameArray, setStockNameArray] = useState(['TSLA', 'SPY']);	//차트에 그려줄 데이터2
    var vChartData2 = [];
    
	var newContacts1 = [];   //
    var newContacts4 = [];  // asset Index
    const [startMonth, setStartMonth] = useState();
    const [endMonth, setEndMonth] = useState();

    // let startMonth = "123";
    // let endMonth = "456";

    /**
	 * 0. 비교할 지수를 추가,삭제 및 DB로 저장하는 타이밍 필요
	 * 
	 * 1. user의 계좌가 몇월부터 몇월까지 인지 Check  (db/sy_user/admin/account)
	 * 2. user의 지수를 로드
	 * 3. user의 계좌의 startMonth, endMonth로 차트 로드
	 * 4. 
	 * 
	 */
    async function loadStockData() {
        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        var v_SyUserDocId
    
        //1. user_id == "로그인 id"인 doc.id를 얻는다.
        const querySnapshot = query(collection(db, "sy_user"), where("user_id", "==", vUserInfo.user_id));
        const docSnap = await getDocs(querySnapshot);
        docSnap.forEach((doc) => {
            console.log("get DocId ===> " + doc.id);
            //console.log(doc.id, " => ", doc.data());
            setSyUserDocId(doc.id);
            v_SyUserDocId = doc.id
        })
    
        //1.2 위에서 얻은 doc.id로 쿼리날린다. query(collection(db, "sy_user", docId, "account")
        const querySnapshot2 = query(collection(db, "sy_user", v_SyUserDocId, "account"), orderBy("monthEnd", "asc"), limit(100));
        const docSnap2 = await getDocs(querySnapshot2);
        
        var i = 0;
        var vStartMonth = "";
        var vEndMonth = "";

        docSnap2.forEach((doc) => {
            if (i == 0) {
                newContacts1 = [];  //
                newContacts4 = [];  //
                vStartMonth = doc.data().monthEnd
            } 
            else if (i == docSnap2.size -1) {
                vEndMonth = doc.data().monthEnd
            }

            var docId = doc.id;
            var yearMonth = doc.data().monthEnd;
            var account1 = doc.data().account1;
            var account2 = doc.data().account2;
            var account3 = doc.data().account3;
            var deposit = doc.data().deposit;
            addData1(docId, yearMonth, account1, account2, account3, deposit, i);
    
            i++;
        })

        setStartMonth(vStartMonth)
        setEndMonth(vEndMonth)
    
        // setContacts1(newContacts1);
        // setContacts4(newContacts4);

        if (startMonth == null) {
            //createTableData() ???

            return {vStartMonth, vEndMonth};
        }
    
        //2. 차트 지수 목록 LOAD
        const docRef3 = doc(db, "sy_user", v_SyUserDocId);
        const docSnap3 = await getDoc(docRef3);

        var arrStock = [];
        if (docSnap3.exists()) {
            if (docSnap3.data().arrChartStock != null) {
                var newArrStock = docSnap3.data().arrChartStock;
                for (var i=0; i<newArrStock.length; i++) {
                    arrStock[i] = newArrStock[i];
                    //console.log("@@@@@@@@@@@@@@@@@ newArrStock : " + newArrStock[i])
                }
                //setStockNameArray(arrStock);
            } else {
                //버튼 Array 초기 데이터 설정
                arrStock = ["TSLA", "SPY"];
                //setStockNameArray(arrStock);

                // 2023.04.02 안씀
                //차트 초기 데이터 설정
                //setStockData(def_dataChart);
            }
        } else {
            //버튼 Array 초기 데이터 설정
            arrStock = ["TSLA", "SPY"];
            setStockNameArray(arrStock);
            // setStartMonth("2021-01")
            // setEndMonth("2021-05")

            // 2023.04.02 안씀
            //차트 초기 데이터 설정
            //setStockData(def_dataChart);
        }

        return { arrStock };
    }
    
    /**
     * 차트 데이터 초기설정
     */
    async function fnInitChart() {
        let xBase = [];
        let yBase = [];
        let stockChartXValuesFunction = [];
        let stockChartYValuesFunction = [];
        let newChartData2;
        var vColorIndex = colorIndexRef.current;
    
        // 자산 Index Load
        //console.log("newContacts4.length : " + newContacts4.length)
        setMonthCount(newContacts4.length);
        
        for (var i=0; i<newContacts4.length; i++) {
            stockChartXValuesFunction.push(newContacts4[i].yearMonth);
            stockChartYValuesFunction.push(newContacts4[i].profit_index);
    
            xBase.push(newContacts4[i].yearMonth);
            yBase.push(1000);

            newChartData2 = {
                "name" : newContacts4[i].yearMonth,
                "BaseLine" : 1000,
                "MyAsset" : Math.round(newContacts4[i].profit_index * 1) / 1,
            }
            vChartData2[i] = newChartData2
            // console.log("■ ■ ■ ■ ■ ■ newChartData2["+i+"]")
            // console.dir(vChartData2[i], {depth : null})

            setStockData2(stockData2 => ([ ...stockData2, newChartData2, ]));
        }
    
        var newData1 = {
            name: "BaseLine", // name key value 로 설정
            x: xBase,
            y: yBase,
            marker: {color: colorArray[vColorIndex]},
            type: 'scatter',
            mode: 'lines',
        }
        //setStockData(stockData => ([ ...stockData, newData1, ]));
        refStockData.current = [...refStockData.current, newData1];
        vColorIndex = vColorIndex + 1;
    
        var newData2 = {
            name: "MyAsset", // name key value 로 설정
            x: stockChartXValuesFunction,
            y: stockChartYValuesFunction,
            marker: {color: colorArray[vColorIndex]},
            type: 'scatter',
            mode: 'lines',
        }
        vColorIndex = vColorIndex + 1;
    
        colorIndexRef.current = vColorIndex
        refStockData.current = [...refStockData.current, newData2];
        //setStockData(stockData => ([ ...stockData, newData2,]));
    };

    /**
     * 차트 추가할 데이터 LOAD 및 차트에 추가
     */
    async function fnAddChartData(arrStock, mode) {
        var vColorIndex = colorIndexRef.current;
        

        for (var i=0; i<arrStock.length; i++) {
            //console.log("=============== fnAddChartData, for ============= " + arrStock[i] + ", "+ typeof(arrStock[i]))
    
            //
            var querySnapshot4 = doc(db, "stock_data", arrStock[i]);
            var docSnap4 = await getDoc(querySnapshot4);
            
            let stockChartXValuesFunction = [];
            let stockChartYValuesFunction = [];
            let yValue;
            let newChartData2;
            // console.log("■■■■■■■■■■■■■■■ ■ ■ ■ newChartData2 : ")
            // console.dir(newChartData2, {depth:null})
    
            if (docSnap4.exists()) {
    
                var monthlyAdjustedData = docSnap4.data().monthly_adjusted_data;
                const sortedData = Object.keys(monthlyAdjustedData).sort().reduce((obj, key) => {
                    obj[key] = monthlyAdjustedData[key];
                    return obj;
                }, {});
    
                var sortDataidx = 0;

                for (var [key, value] of Object.entries(sortedData)) {
                    if (key >= startMonth && key <= endMonth) {
                        stockChartXValuesFunction.push(key);

                        //index로 변환 후 값 추가
                        // && mode == "init"
                        if (sortDataidx == 0) {
                            yValue = value;
                            stockChartYValuesFunction.push(1000)

                            //새로운 차트 데이터 만들어보기
                            vChartData2[sortDataidx] = Object.assign({}, vChartData2[sortDataidx], { [docSnap4.id]: 1000 });
                        } else {
                            var yPer = value / yValue - 1;
    
                            var yIndex = 1000 * (1+yPer);
                            yIndex = Math.round(yIndex * 1) / 1
                            stockChartYValuesFunction.push(yIndex)

                            //새로운 차트 데이터 만들어보기
                            vChartData2[sortDataidx] = Object.assign({}, vChartData2[sortDataidx], { [docSnap4.id]: yIndex });
                        }

                        sortDataidx++
                    }
                }
                //setStockData2([newChartData2]);
                
                let newData = {
                    name: docSnap4.id, // name key value 로 설정
                    x: stockChartXValuesFunction,
                    y: stockChartYValuesFunction,
                    marker: {color: colorArray[vColorIndex]},
                    type: 'scatter',
                    mode: 'lines',
                }
                refStockData.current = [...refStockData.current, newData];
               // setStockData2(vChartData2);

                if (mode == "add") {
                    //4. 차트 위에 버튼 Array 추가
                    var newNameArray = [...stockNameArray, arrStock[0]];
                    setStockNameArray(newNameArray);
                    setInputValue('');
                }

                vColorIndex = vColorIndex + 1;
                colorIndexRef.current = vColorIndex;
            } else {
                alert("No such document!")
            }
        }

        setStockData(refStockData.current);
    }


    
    

    
    /*
    * DB에 있는 데이터를 배열에 저장
    */
    function addData1 (docId, yearMonth, account1, account2, account3, deposit, i) {
        var newContact = { };
        var totalAsset_start = (account1 + account2 + account3).toString();
        var totalAsset_end = (account1 + account2 + account3).toString();

        if (i == 0) {
            var deposit = account1 + account2 + account3
            newContact = {
                id: i,
                _docId : docId,
                totalAsset_start: (account1 + account2 + account3).toString(),
                totalAsset_end: (account1 + account2 + account3).toString(),
                deposit: deposit.toString(),
                account1: account1,
                account2: account2,
                account3: account3,
            }

            addData4(i, docId, totalAsset_end, deposit, 1000, yearMonth);
            // addData3();
        }
        else {
            totalAsset_start = newContacts1[i-1].totalAsset_end.toString();
            var profitMonth = (   (account1 - newContacts1[i-1].account1) 
                                + (account2 - newContacts1[i-1].account2) 
                                + (account3 - newContacts1[i-1].account3) 
                                - deposit) + "";

            newContact = {
                id: i,
                _docId : docId,
                totalAsset_start: totalAsset_start,
                totalAsset_end: totalAsset_end,
                deposit: deposit.toString(),
                account1: account1,
                account2: account2,
                account3: account3,
            }
            addData4(i, docId, totalAsset_end, deposit, profitMonth, yearMonth);
        }

        newContacts1 = [...newContacts1, newContact];
    }

    function addData4 (i, docId, totalAsset_end, deposit, profitMonth, yearMonth) {
        //엑셀 E,F,G (기말자산, 입출금, 당월손익) 필요함.
        var newContact = { };

        if (i == 0) {
            newContact = {
                id: i,
                _docId : docId,
                unit: (Number(deposit) / 1000).toString(),
                profit_index: 1000,
                asset_index: 1000,
                profit: 0,
                yearMonth: yearMonth.toString(),
            }
        }
        else {
            var unit =  Number(newContacts4[i-1].unit) + (deposit / Number(newContacts4[i-1].profit_index));
            var profit_index = Number(newContacts4[i-1].profit_index) + (Number(profitMonth) / Number(unit));
            var asset_index = Number(newContacts4[0].asset_index) * (Number(totalAsset_end) / Number(newContacts1[0].totalAsset_end))
            var profit = Number(profit_index) - Number(newContacts4[0].profit_index);

            newContact = {
                id: i,
                _docId : docId,
                unit: (Math.round(unit * 1) / 1).toString(),
                profit_index: (Math.round(profit_index * 1) / 1).toString(),
                asset_index: (Math.round(asset_index * 1) / 1).toString(),
                profit: (Math.round(profit * 1) / 1).toString(),
                yearMonth: yearMonth.toString(),
            }
        }

        newContacts4 = [...newContacts4, newContact];
    }

    //여기서 테이블 데이터들 추가
    function createTableData() {
        // docSnap2.forEach((doc) => {
        //     if (i == 0) {
        //         newContacts1 = [];  //
        //         newContacts4 = [];  //
        //         vStartMonth = doc.data().monthEnd
        //     } 
        //     else if (i == docSnap2.size -1) {
        //         vEndMonth = doc.data().monthEnd
        //     }

        //     var docId = doc.id;
        //     var yearMonth = doc.data().monthEnd;
        //     var account1 = doc.data().account1;
        //     var account2 = doc.data().account2;
        //     var account3 = doc.data().account3;
        //     var deposit = doc.data().deposit;
        //     addData1(docId, yearMonth, account1, account2, account3, deposit, i);
    
        //     i++;
        // })

        // setStartMonth(vStartMonth)
        // setEndMonth(vEndMonth)
    }

    /**
     * 추가 버튼 클릭 이벤트
     */
     async function addChartStock(e) {
        //inputValue

        var words;
        var arrStockTicker = [ ];
        for (var i=0; i<allTextArray.length; i++) {
            words = allTextArray[i].split('　　');
            arrStockTicker[i] = words[0];
        }

        // 처음에 설정한 주식 목록들
        const isListIn = arrStockTicker.some((ticker) => {
            //console.log("isListIn - ticker : " + ticker);
            return ticker.toLowerCase() == inputValue.toLowerCase()
        });

        // 그래프에 추가된 주식
        const isChartIn = stockNameArray.some((ticker) => {
            // console.log("isChartIn - ticker : " + ticker);
            // console.log("ticker : " + ticker + '   type: ' + typeof(ticker))
            // console.log("inputValue : " + inputValue + '   type: ' + typeof(inputValue))
            
            if (typeof ticker === 'object') {
                ticker = JSON.stringify(ticker);
              }
            return ticker.toLowerCase() == inputValue.toLowerCase()
        });

        if (isListIn) {
            if (!isChartIn) {
                setShowProgressModal(true);

                // DB에 추가
                var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
                if (vUserInfo.isLogin) {
                    const docRef = doc(db, "sy_user", vUserInfo.user_id);
                    const docSnap = await getDoc(docRef);

                    var newArrStock = docSnap.data().arrChartStock;
                    if (newArrStock == null) {
                        newArrStock = stockNameArray;
                    }

                    newArrStock = [...newArrStock, inputValue.toUpperCase()]
                    await updateDoc(docRef, {
                        arrChartStock: newArrStock
                    });
                }

                // 추가할 녀석의 데이터 LOAD 및 차트에 데이터 추가
                var arrStock = [ ];
                arrStock[0] = inputValue.toUpperCase();
                fnAddChartData(arrStock, "add");

                
                setShowProgressModal(false);
                console.log("IndexChart - addChartStock ... arrStock")
                console.dir(arrStock, {depth:null})
            }
            else {
                alert(inputValue + "는 이미 차트에 추가되어 있습니다.");
                setInputValue('');
                return;
            }
        }
        else {
            alert(inputValue + "는 아직 추가하지 않았거나, 존재하지 않습니다.");
            setInputValue('');
            return;
        }
    }

    /**
     * 삭제 로직
     */
    async function deleteChartStock(stockName) {
        // setStockData(prevState => prevState.filter(obj => obj.name !== stockName));
        // setStockNameArray(prevState => prevState.filter(obj => obj.name !== stockName));
        setShowProgressModal(true);

        var deleteIndex = stockNameArray.indexOf(stockName);
        var vColorArray = colorArray;
        var vDeleteColor = vColorArray[deleteIndex];
        vColorArray.splice(deleteIndex, 1);     //자르기
        vColorArray.splice(colorIndexRef.current, 0, vDeleteColor);     //붙이기

        // 내부 배열 제거
        // ex ["SPY", "QQQ", ["TSLA"], ["AMZN"]]
        vColorArray = vColorArray.map(item => Array.isArray(item) ? item[0] : item);

        // DB에서 삭제한 것만 빼고 Update (delete)
        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        if (vUserInfo.isLogin) {
            const docRef = doc(db, "sy_user", vUserInfo.user_id);
            //const docSnap = await getDoc(docRef);

            

            var newArrStock = stockNameArray;
            newArrStock = newArrStock.filter(name => name !== stockName);

            console.log("[IndexChart - deleteChartStock] newArrStock : " + newArrStock)
            console.dir(newArrStock, {depth:null})

            await updateDoc(docRef, {
                arrChartStock: newArrStock
            });
        }

        //위 코드로 setState를 실행하면 너무 많은 reRender가 한번에 발생함.
        //그래서 setState의 콜백함수로 해결
        setStockData(prevState => {
            const filteredArray = prevState.filter(stock => stock.name !== stockName);
            refStockData.current = filteredArray;

            return filteredArray
        });

          
        setStockNameArray(prevState => {
            const filteredArray = prevState.filter(stock => stock !== stockName);
            return [...filteredArray];
        });

        setShowProgressModal(false);
    }

    function onChangeInput(e) {
        e.preventDefault();

        var newText = e.target.value;
        // console.log("########### newText : " + newText)

        setInputValue(newText);
    }

    // 정렬을 위한 비교 함수
    function compare(a, b) {
        if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
        }
        if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
        }
        return 0;
    }
  

    function showDropDownList() {
        if (inputValue === '') {
            setIsHaveInputValue(false)
            setDropDownList([])
        } else {
            // const choosenTextList = allTextArray
            //                             .filter(textItem => textItem.toLowerCase().includes(inputValue.toLowerCase()) )
            //                             .sort(compare);
            const choosenTextList = allTextArray
                                        .filter(textItem => textItem.toLowerCase().includes(inputValue.toLowerCase())).sort((a, b) => {
                                                if (a.toLowerCase().startsWith(inputValue) && !b.toLowerCase().startsWith(inputValue)) {
                                                    return -1;
                                                } else if (!a.toLowerCase().startsWith(inputValue) && b.toLowerCase().startsWith(inputValue)) {
                                                    return 1;
                                                } else {
                                                    return a.localeCompare(b);
                                                }
                                            });
                                          
            setDropDownList(choosenTextList)
        }
    }
    
    function changeInputValue(event) {
        setInputValue(event.target.value)
        setIsHaveInputValue(true)
    }
    
    function clickDropDownItem(clickedItem) {
        var words = clickedItem.split('　　');
        setInputValue(words[0])
        setIsHaveInputValue(false)
    }
    
    function handleDropDownKey(event) {
        //input에 값이 있을때만 작동
        if (isHaveInputValue) {
            if (event.key === 'ArrowDown' &&
                dropDownList.length - 1 > dropDownItemIndex) 
            {
                setDropDownItemIndex(dropDownItemIndex + 1)
            }

            if (event.key === 'ArrowUp' && dropDownItemIndex >= 0)
                setDropDownItemIndex(dropDownItemIndex - 1)
            if (event.key === 'Enter' && dropDownItemIndex >= 0) {
                clickDropDownItem(dropDownList[dropDownItemIndex])
                setDropDownItemIndex(-1)
            }
        }
    }

    useEffect(showDropDownList, [inputValue])

    // useEffect(() => {
    //     sColorIndex = colorIndex;
    //     console.log("usEffect colorIndex : " + colorIndex)
    // }, [colorIndex])

    /**
     * 차트 데이터 setState 가 완료되고 나면 차트보여주기
     */
    useEffect(() => {
        if (countCallUseEffectChart.current > 0) {
            setChartLoading(true);

            console.log("IndexChart - useEffect[stockData] ... stockData")
            console.dir(stockData, {depth:null})
        }
        countCallUseEffectChart.current += 1;
        //console.log("▶▶▶ useEffect stockData , " + stockNameArray.length + ",    cnt: " + countCallUseEffectChart.current)
        

        // if (chartLoading) {
            
        //     if (countCallUseEffectChart.current > stockNameArray.length) {
        //             //Plot.react('stock-chart', stockData, {
        //             //   scrollZoom: false,
        //             //   editable: false,
        //             //   layout: {
        //             //     title: 'Index',
        //             //     width: 1200,
        //             //     height: 500,
        //             //     dragmode: 'pan',
        //             //     xaxis: { tickformat: '%Y-%m', showticklabels: true, dtick: 'M3' },
        //             //   },
        //             // });
        //     }
        // }
      }, [stockData]);
    

    useEffect(() => {
        loadStockData()
            .then(({ arrStock }) => {

                if (startMonth == null) {
                    //myAssetIndex 추가
                    fnInitChart();
                    //return { arrStock };
                }
                return { arrStock }
            })
            .then(({ arrStock }) => {
                if (startMonth != null) {
                    //3. 차트 LOAD (하는 김에 update 날짜도 체크해서 최신 데이터로 업데이트 해주면 좋을듯!)
                    fnAddChartData(arrStock, "init");

                    //4. 차트 위에 버튼 Array 설정
                    setStockNameArray(arrStock);
                    //setChartLoading(true);
                    
                    //console.log("######### END LOAD () ---- sStartMonth : " + vStartMonth)
                }
                return { arrStock }
            })
            .then(({ arrStock }) => {

                // setStockData
                console.log("------------> refStockData")
                console.dir(refStockData, {depth : null})
            })

        

        // console.log("############### allTextArray2");
        // console.dir(allTextArray2, {depth : null})
    }, [endMonth])
    

    return (
        <div>
            {showProgressModal && <Modal_Progress /> }
            {/* {
                stockData.length > 1 && (
                console.log("return stockData[0] : " + stockData[0].name)
                )
            } */}
            {/* { console.log("stackData : " + stackData[colorIndex]) } */}
             {/* { console.log("return stockData.length : " + stockData.length) }
            { console.log("========= new stock Data ===========")}
            { console.dir(stockData2, { depth: null }) } */}
            
            {/* { console.log("========= Fetch stock Data ===========")}
            { console.dir(stockData, { depth: null }) } */}

            { (userInfo != null) ? 
                <div style={{marginLeft: '20px', fontSize: '18px'}}>
                    <p>비교군으로 추가하고 싶은 종목, ETF가 있다면 게시판에 남겨주세요</p>
                    <p>현재는 나스닥에 상장된 일부만 지원하고 있습니다.</p>
                </div>
                : null
            }

            {/* userInfo != null && userInfo.auth == "admin" */}
            { (userInfo != null) ? 
                <div style={{ display: 'flex', marginLeft: '40px' }}>
                    {/* <Input icon='search' placeholder='Search...' value={inputValue} onChange={onChangeInput}/>
                     */}
                    {/* 자동완성기능   https://velog.io/@beanlove97/React-%EC%9E%90%EB%8F%99%EC%99%84%EC%84%B1-%EA%B8%B0%EB%8A%A5 */}
                    <AllBox>
                        {/* <Title text='AutoComplete' /> */}
                        <InputBox isHaveInputValue={isHaveInputValue}>
                            <Input
                            type='text'
                            value={inputValue}
                            onChange={changeInputValue}
                            onKeyUp={handleDropDownKey}
                            />
                            <DeleteButton onClick={() => setInputValue('')}>&times;</DeleteButton>
                        </InputBox>
                        {isHaveInputValue && (
                            <DropDownBox>
                            {dropDownList.length === 0 && (
                                <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
                            )}
                            {dropDownList.map((dropDownItem, dropDownIndex) => {
                                return (
                                <DropDownItem
                                    key={dropDownIndex}
                                    onClick={() => clickDropDownItem(dropDownItem)}
                                    onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                                    className={
                                    dropDownItemIndex === dropDownIndex ? 'selected' : ''
                                    }
                                >
                                    {dropDownItem}
                                </DropDownItem>
                                )
                            })}
                            </DropDownBox>
                        )}
                    </AllBox>

                    {/* 추가 버튼 */}
                    <div style={{ marginTop:'15px' }}>
                        <Button 
                            size='large'
                            icon="add"
                            //labelPosition="left"
                            secondary   //검은색
                            onClick={addChartStock}></Button>
                    </div>
                </div>
                : null
            }
            
            

            <br/>
            <br/>
            <br/>
            {/* userInfo != null && userInfo.auth == "admin" */}
            { (userInfo != null && chartLoading) ? 
                <div style={{ marginLeft:'40px', display:'flex'}}>
                {
                    stockNameArray.map((stockName, i) => {
                        return(
                            <div style={{marginLeft:'10px'}} key={'stockArray' + i} >
                                <Button size='small' color='black' 
                                    onClick={() => deleteChartStock(stockName)}>{stockName}</Button>
                            </div>
                        );
                    })
                }
                </div> 
            : null 
            }
            <br/>

            { chartLoading ? 
                <Plot
                //https://plotly.com/javascript/time-series/
                    data={ stockData }
                    config={{ scrollZoom: false, editable: false}}
                    layout={{ 
                        title: 'Index', 
                        width: 1200, 
                        height: 500, 
                        dragmode: 'pan',
                        xaxis: {tickformat: '%Y-%m', showticklabels: true, dtick : monthCount >= 12 ? 'M3' : 'M1'},
                        
                    }}
                />
            : <Segment>
                <Dimmer active>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>

                <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' />
                <Image src='/images/wireframe/short-paragraph.png' />
                </Segment>
            }

            {/* <LineChart
                width={1200}
                height={400}
                data={stockData2}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid  strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="BaseLine" stroke="#000000" dot={false} />
                <Line type="linear" dataKey="MyAsset" stroke="RED" dot={false} />
                <Line type="linear" dataKey="TSLA" stroke="BLUE" dot={false} />
                <Line type="linear" dataKey="QQQ" stroke="#82ca9d" dot={false} />
                
            </LineChart> */}
        </div>
    );
}

const activeBorderRadius = '16px 16px 0 0'
const inactiveBorderRadius = '16px 16px 16px 16px'

const AllBox = styled.div`
  padding: 10px;
  width: 400px;
`

const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: ${props =>
    props.isHaveInputValue ? activeBorderRadius : inactiveBorderRadius};
  z-index: 3;

  &:focus-within {
    box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  }
`

const Input = styled.input`
  flex: 1 0 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
`

const DeleteButton = styled.div`
  cursor: pointer;
`

const DropDownBox = styled.ul`
  display: block;
  margin: 0 auto;
  padding: 8px 0;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
`

const DropDownItem = styled.li`
  padding: 0 16px;

  &.selected {
    background-color: lightgray;
  }
`

export default IndexChart;