/* eslint-disable */    //waring 제거
import React, { Component, useState, useEffect } from 'react';
import eruda from 'eruda';

import { BrowserRouter, Route, Routes, Link, useNavigate  } from 'react-router-dom';

import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import {db} from "../fb.js";
import _ from "lodash";
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
import {
    Divider,
    Menu,
    Button,
} from 'semantic-ui-react';

import {auth} from '../fb.js';
import FetchButton from 'top/FetchButtons';  //주식 업데이트 버튼

import HomePage from 'body/HomePage.js';           // 메뉴1 (Home)
import MyWeb from '../body/MyWeb.js';           // 메뉴1 (Home)
import ChartStock from '../body/ChartStock.js'  // 메뉴2 (Chart)
import AssetInput from '../body/AssetInput.js'  // 메뉴3 (Chart)


import Test from '../body/Test.js';   // 메뉴3 (Test1)
import TestPage1 from '../body/TestPage1.js';   // 메뉴3 (Test1)
import TestPage2 from '../body/TestPage2.js'    // 메뉴4 (Test2)
import Snowball from '../body/Snowball.js'      // 메뉴5 (Snowball)
import PostMain from '../body/post/PostMain.js' // 메뉴6 (게시글)
import PostView from '../body/post/PostView.js' // 게시글 상세
import PostAdd from '../body/post/PostAdd.js' // 게시글 등록
import PostEdit from '../body/post/PostEdit.js' // 게시글 수정
import SignUpPage from 'body/SignUpPage.js' // 회원가입
import MainLogo from 'assets/MainLogo.png' // 로고
import ImgLogo from 'assets/favicon.ico' // AlphaWho
import Modal_Login from 'body/Modal_Login';

import { useSelector, useDispatch } from "react-redux";
import { increseCount, mulTest } from "reducer/counter";
import { login, logout } from "reducer/userInfo";
import { async } from '@firebase/util';


const provider = new GoogleAuthProvider();

const MenuFrame = () => {
    const navigate = useNavigate();
    const [userName , setUserName] = useState('방문자');
    const [visitors , setVisitors] = useState('방문자들');

    // dispatch를 사용하기 위한 준비
    const dispatch = useDispatch();

    // store에 접근하여 state 가져오기
    const { count, test } = useSelector(state => state.counter);
    const { userInfo } = useSelector(state => state.userInfo);
    const [isAdmin , setIsAdmin] = useState(false);

    const loginRedux = async(_userInfo) => {
        dispatch(login(_userInfo));
    }

    const logoutRedux = () => {
        dispatch(logout());
        localStorage.clear();
        window.location.href = '/';
        //window.location.reload();
    }

    const increse = () => {
        // store에 있는 state 바꾸는 함수 실행
        dispatch(increseCount());
    };
    
    const mul = () => {
        // store에 있는 state 바꾸는 함수 실행
        dispatch(mulTest());
    };
    //console.log("================= 메모리 누수 체크 1");

    // 새로고침 할때마다 local 데이터 가져오기
    //https://velog.io/@bellecode20/localStorage%EC%97%90-state-%EC%A0%80%EC%9E%A5%ED%95%98%EA%B8%B0
    //https://reactjs.org/docs/lifting-state-up.html

    async function fn_loginCheck() {
        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        //var vUserInfo = getUserInfoRedux();     // 로그인 정보를 로컬스토리지에서 가져옵니다.
        if (vUserInfo == null) return;         // 로그인 정보가 없다면 여기서 멈춥니다.


        console.dir(vUserInfo, { depth: null })
        loginRedux(vUserInfo);

        if (vUserInfo.auth == "admin") {
            setIsAdmin(true);
        }

        console.log("fn_loginCheck - auth =========> " + vUserInfo.auth)
        console.log("userInfo.auth =========> " + userInfo.auth)
        console.log("isAdmin =========> " + isAdmin)
    }

    function initEruda() {
        if (process.env.NODE_ENV !== 'production') {
          eruda.init();
        }
    }
    
    useEffect(() => {
        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));

        fn_loginCheck();
        console.log("isAdmin_ =========> " + isAdmin)

        console.log("vUserInfo" + vUserInfo)

        if (vUserInfo != null && vUserInfo.auth == "admin") {
            // initEruda();    //모바일에서 개발자 console 띄우기
        }
    }, [])
    
    return (
        <div style={{width: '100%'}}>
            <RecoilRoot>
                {/* <h1 style={{ textAlign: 'center', paddingTop : '15px'}}>Alpha Who</h1> */}
                <div 
                    style={{
                        textAlign : 'center',
                        cursor : 'pointer',
                        width: '100%',
                    }}>
                    <Link to='/'>
                        <img src={ImgLogo} style={{ paddingTop : '15px', width:'40px'}} />
                        <img src={MainLogo}style={{ paddingTop : '15px', }} />
                    </Link>
                </div>
                
                {/* <h4 style={{ textAlign: 'center'}}>
                    {`반가워요, ${this.state.userName}님`}
                </h4> */}
                
                {/* 구글 recaptcha 기능 (로봇이 아닙니다.) */ }
                {/* 
                  * recoil 탐구
                  *  https://velog.io/@dldngus5/TILReact-%EC%83%81%ED%83%9C%EA%B4%80%EB%A6%AC-%EA%B3%A0%EB%AF%BC-Context-Recoil
                  *  https://velog.io/@hwanieee/Recoil-%EC%8B%9C%EC%9E%91%ED%95%98%EA%B8%B0
                  *  https://abangpa1ace.tistory.com/212
                */}

                { userInfo.auth === 'admin' ? 
                    <>
                    <FetchButton />

                    <Button 
                        color='red'
                        content='Redux Count Test'
                        icon='cloud upload'
                        label={{
                            basic: true,
                            color: 'red',
                            pointing: 'left',
                            content: count,
                        }}
                        onClick = {increse}
                    />
                        <div style={{display: "flex"}}>
                            <div style={{marginLeft: "30px"}}>
                                <br/>
                                <h3>당장 해야할 일</h3>
                                <h5>■ 시스템 공통</h5>
                                <p> - Local Storage --&gt; 토큰 </p>
                                {/* https://seungwoolog.tistory.com/88 */}
                                <h5>■ 마이페이지</h5>
                                <p> - 회원탈퇴, 프로필사진, 닉네임 변경, 비밀번호 변경</p>
                                <h5>■ 회원가입 페이지</h5>
                                <p> - 개인정보제공 동의 체크하기</p>
                                <br/>
                            </div>

                            <div style={{marginLeft: "60px"}}>
                                <br/>
                                <h3>개선사항</h3>
                                <h5>■ 자산입력</h5>
                                <p> - 테이블들 보면 Header랑 Body랑 좌우가 1px씩 밀려서 벌어져있음</p>
                                <h5>■ 투자요약</h5>
                                <p> - 주식목록 추가해주기</p>
                                <p> - 차트 컬러 순서가 현재 보이는거랑, 새로고침 했을 때랑 달라짐..</p>
                                <h5>■ 게시판</h5>
                                <p> - postEdit, postAdd 합치기</p>
                                <br/>
                            </div>

                            <div style={{marginLeft: "60px"}}>
                                <br/>
                                <h3>소스코드</h3>
                                <h5>■ 페이지, 컴포넌트 폴더 구분</h5>
                            </div>
                        </div>
                    </>
                    : null
                }
                
                <Modal_Login />
                <label>/</label>
                { !(userInfo.isLogin) ? <a href='/SignUpPage'> 회원가입</a>
                    : <label
                        onClick={logoutRedux}> 로그아웃</label>
                }
                

                
                    <Menu inverted widths={7} style={{ marginBottom : '15px'}}>
                        <Menu.Item as={ Link } name='Home' to='/'/>
                        {/* 관리자용 테스트 메뉴 */}
                        { isAdmin ? <Menu.Item as={ Link } name='H_테스트_H' to='/Test'/> : null}
                        { isAdmin ? <Menu.Item as={ Link } name='H_차트 테스트_H' to='/chartStock'/> : null}
                        
                        {/* <Menu.Item as={ Link } name='convert Chart' to='/AssetInput'/> */}
                        <Menu.Item as={ Link } name='1 자산입력' 
                            to={userInfo.isLogin ? '/AssetInput' : '/SignUpPage'}
                            onClick={() => {
                                if (!userInfo.isLogin) {
                                alert('회원가입이 필요한 서비스입니다.');
                                }
                            }}
                        />
                        <Menu.Item as={ Link } name='2 투자요약' 
                            to={
                                userInfo.isLogin
                                    ? userInfo.hasAccount
                                        ? '/snowball'
                                        : '/AssetInput'
                                    : '/SignUpPage'
                                }
                            onClick={() => {
                                if (!userInfo.isLogin) {
                                    alert('회원가입이 필요한 서비스입니다.');
                                } else if (userInfo.isLogin && !userInfo.hasAccount) {
                                    alert('자산입력이 필요합니다. \n초기에 보이는 데이터는 DB에 저장된 데이터가 아닙니다. 그대로 사용하더라도 저장 후 이용해 주십시오.');
                                }
                            }}
                        />
                        <Menu.Item as={ Link } name='게시판' to='/PostMain'/>

                        {/* <Menu.Item as={ Link } name='myWeb' to='/myWeb'/> */}
                        {/* <Menu.Item name='Login' 
                            onClick={() => {
                                //const auth = getAuth();
                                signInWithPopup(auth, provider)
                                .then( async (result) => {
                                    

                                    // This gives you a Google Access Token. You can use it to access the Google API.
                                    const credential = GoogleAuthProvider.credentialFromResult(result);
                                    const token = credential.accessToken;
                                    // The signed-in user info.
                                    const user = result.user;

                                    //this.setState({userName : user.displayName})
                                    

                                    // console.log("length: " + (this.state.visitors.length-1))
                                    // console.log("result: " + result)
                                    // console.log("user: " + user.displayName)
                                    // console.log("...this.state.visitors: " + [...this.state.visitors, user.displayName])

                                    let duplicate = ""
                                    duplicate = _.find(this.props.visitors, (name) => name == user.displayName)
                                    if (!duplicate) {
                                        await updateDoc(doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC"), {visitors : [...this.props.visitors, user.displayName]})
                                        this.setState(prev => {
                                            return {
                                                userName : user.displayName, 
                                                visitors : [...prev.visitors, user.displayName]
                                            }
                                        })
                                    } else {
                                        this.setState({userName : user.displayName})
                                    }
                                    

                                    // ...
                                }).catch((error) => {
                                    // Handle Errors here.
                                    const errorCode = error.code;
                                    const errorMessage = error.message;
                                    // The email of the user's account used.
                                    const email = error.email;
                                    // The AuthCredential type that was used.
                                    const credential = GoogleAuthProvider.credentialFromError(error);
                                    // ...
                                });
                            }}
                        /> */}
                    </Menu>

                    <Routes>
                        <Route path="/" element={ <HomePage />} />
                        <Route path="/chartStock" element={<ChartStock/>} />
                        <Route path="/AssetInput" element={<AssetInput/>} />
                        <Route path="/Test" element={<Test/>} />
                        <Route path="/Test1" element={<TestPage1/>} />
                        <Route path="/snowball" element={<Snowball/>} />
                        <Route path="/PostMain" element={<PostMain/>} />
                        <Route path="/PostView/:id" element={<PostView/>} />
                        <Route path="/PostAdd" element={<PostAdd />} />
                        <Route path="/PostEdit/:id" element={<PostEdit mode={"edit"}/>} />
                        <Route path="/SignUpPage" element={<SignUpPage />} />
                        <Route path="/myWeb" element={
                            <MyWeb 
                            userName = {userName}
                            visitors = {visitors} />
                        } />
                        
                    </Routes>
            </RecoilRoot>
        </div>
    );
  }

export default MenuFrame;