import React, { useState, useEffect } from 'react';
import PostList from './PostList';

const PostMain = props => {
  return (
    <>
      <h2 align="center">게시판</h2>
      <PostList />
    </>
  )
}

export default PostMain;