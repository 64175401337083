/* eslint-disable */    //waring 제거
import React, { Component, useEffect, useState, Fragment } from 'react';

import _ from "lodash";
import hs1 from "../HS1.jpg"

import {
    Image,
    Divider,
    Header,
    Icon,
    Grid,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async';

import Comments from './comment.js';
import Buttons from './buttons.js';
import VisitorModal from './modal.js';
import preval from 'preval.macro'

import imgHome1 from 'assets/home1.png';
import imgHome2 from 'assets/home2.png';
import endMonthAsset1 from 'assets/endMonthAsset1.jpg';
import endMonthAsset2 from 'assets/endMonthAsset2.jpg';
import endMonthAsset3 from 'assets/endMonthAsset3.jpg';

function HomePage () {
  const [showBalance, setShowBalance] = useState(false);
  const buildDateTime = preval`module.exports = new Date().toLocaleString();` // 빌드 시점의 정보
  const isLocal = window.location.href.includes('localhost'); // url주소에 localhost를 포함한다면, 로컬환경

  const handleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  useEffect(() => {
    console.log('AAAAAAAAAAAAAAAAAAAAAAA', window.location.href);
  }, [])

    
    return (
        <div>
          <div align="center" style={{ fontSize:"17px" }}>
              
              <br/>
              <br/>
              <br/>
              <br/>
              
              
              
              <h3>1. [자산입력] 메뉴에서 월말 계좌잔고를 입력하시면, 계좌들의 <strong style={{color: 'blue'}}>월별 총 손익</strong>을 볼 수 있습니다.</h3><br />
              <img src={imgHome1} width="1100"></img>
              <br /><br /><br /><br /><br /><br /><br /><br /><br />
              <h3>2. [투자요약] 메뉴에서는 나의 투자현황을 <strong style={{color: 'blue'}}>다른 종목과 그래프로 비교</strong>해 볼 수 있습니다.</h3>
              <img src={imgHome2} width="1100"></img>
              <br/><br/><br/><br/><br/>

              <h2 style={{ color:'blue' }}>&gt;&gt;&gt; 키움증권 앱 월말잔고 확인하는 방법</h2>
              <button onClick={handleShowBalance}>
                {showBalance ? "숨기기" : "자세히 보기"}
              </button>
              {showBalance && (
                <div>
                  <div align="left" style={{ marginLeft:'400px' }} >
                    <br/>
                    <p>키움증권 (영웅문SG 기준)</p>
                    <p>1. 앱 우측하단의 [계좌] --&gt; [기간별 수익률]</p>
                    <p>2. 조회기간 월초 ~ 월말로 설정 　　ex) 2023/02/01 ~ 2023/02/28</p>
                    <p>3. 맨 아래 [평가기간 말]의 [순 자산액 계]를 입력</p>
                    <p>4. 100원 단위 이하 금액은 깔끔하게 0으로 입력 　　ex) 21,496,769 --&gt; 21,496,000</p>
                    <br/>
                    <br/>
                  </div>
                  <div align="left" style={{ whiteSpace:'nowrap' }}>
                    <div style={{ display:'inline-block', marginLeft:'20px' }}>
                      <img src={endMonthAsset1} width="400"></img>
                    </div>
                    <div style={{ display:'inline-block', marginLeft:'100px' }}>
                      <img src={endMonthAsset2} width="400"></img>
                    </div>
                    <div style={{ display:'inline-block', marginLeft:'100px' }}>
                      <img src={endMonthAsset3} width="400"></img>
                    </div>
                  </div>
                </div>
              )}

              
              <br/><br/><br/><br/><br/>
              <h4>마지막 업데이트 일자: {isLocal ? '2024. 1. 13. 오후 3:58:35 (local/dev)' : buildDateTime}</h4>
          

          </div>
        </div>
    );
}

export default HomePage;