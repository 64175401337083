import React from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import {
  Checkbox,
} from "semantic-ui-react";


import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root : {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }, 
  table: {
    minWidth: 1080
  }
})

const AccountTable = (headerList, boardList) => {

  var userId = "RedRoad";
  var userName = "홍길동";
  var birthday = "1950.6.25";
  var gender = "남자";
  var job = "직장인";

  return (
    <Paper className={styles.root}>
      <Table className={styles.table}>
        <TableHead>
          <TableCell></TableCell>
          <TableCell>아이디</TableCell>
          <TableCell>이름</TableCell>
          <TableCell>생년월일</TableCell>
          <TableCell>성별</TableCell>
          <TableCell>직업</TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Checkbox /></TableCell>
            <TableCell>{userId}</TableCell>
            <TableCell>{userName}</TableCell>
            <TableCell>{birthday}</TableCell>
            <TableCell>{gender}</TableCell>
            <TableCell>{job}</TableCell>
          </TableRow>
        </TableBody>

      </Table>
    </Paper>
  )
}

export default withStyles(styles)(AccountTable);