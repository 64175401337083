/* eslint-disable */    //waring 제거

//react 유용한 툴
//https://react.vlpt.us/basic/27-useful-tools.html

import logo from './logo.svg';
import './App.css';
import React from 'react';
import { doc, getDoc } from "firebase/firestore";
import {db} from "./fb.js";
import _ from "lodash";
import { BrowserRouter } from 'react-router-dom';
import {
    Button,
    Input,
    Progress,
    Image,
    Divider,
    Header,
    Icon,
} from 'semantic-ui-react';

import MenuFrame from './top/MenuFrame.js';
import Footer from 'bottom/Footer.js';
import { render } from '@testing-library/react';

function Login2() {
    return (
        <div>
            <Input />
            <Button
                onClick={(e) => {
                    console.log(e)
                    alert(e.target.value)
                }}>로그인!</Button>
        </div>
    )
}

// class Login extends React.Component {

//     constructor() {
//         super()
//         this.state = {
//             id : "아이디랑께"
//         }
//     }

//     render() {
//         return (
//             <div>
//                 <Input onChange={(e) => this.setState({ id: e.target.value })} />
//                 <Button
//                     value={this.state.id}
//                     onClick={(e) => {
//                         console.log(e)
//                         alert(e.target.value)
//                     }}>Login!</Button>
//             </div>
//         )
//     }
// }

class App extends React.Component{
    constructor() {
        super()
        this.state = {
            userName : "방문자",
            //isModalOpen : false,
            visitors : ["통윤", "뱅민", "슈카월드"],
        }
    }

    componentDidMount = async () => {
        const docRef = doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document visitors:", docSnap.data());
            this.setState({visitors : docSnap.data().visitors})
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    render() {
        return (
            <div className="App" style={{ width: "100%", overflowX: "hidden", margin: "0 auto" }}>
                {/* 
                <p>hellooooo</p>
                <Button primary="primary">버튼쓰</Button>
                <Progress percent={66} indicating="indicating"></Progress> 
                */}
    
                <div style={{ width: "1300px", margin: "0 auto"  }}>
                    {/* TOP */}
                    <BrowserRouter>
                        <MenuFrame 
                            visitors = {this.state.visitors}
                        />
                    </BrowserRouter>

                    {/* BODY */}
                    {/* <MyWeb/> */}

                    {/* Bottom */}
                    
                    <div style={{width: '100%'}}>
                        <Footer />
                    </div>
                </div>
                {/* <Login /> */}
            </div>
        );
    }
}


export default App;
