import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { HelmetProvider, Helmet } from 'react-helmet-async';


import './index.css';
import App from './App.js';
import rootReducer from "./reducer";

// 배포 레벨에서는 리덕스 발동시 찍히는 logger를 사용하지 않습니다.
const enhancer =
  process.env.NODE_ENV === "production"
    ? compose(applyMiddleware())
    : composeWithDevTools(applyMiddleware(logger));

// 위에서 만든 reducer를 스토어 만들때 넣어줍니다
const store = createStore(rootReducer, enhancer);

function MainHelmet() {
  let title = "Alpha Who";
  let description = "내 투자현황을 ETF, 주식과 비교하기";
  let url = "https://alphawho.com";
  let image = "/og.Logo.png";

  return (
    <Helmet>

      {/* 메타태그 */}
      <title>Alpha Who</title>

      {/* 오픈 그래프 메타태그 */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      {/* 기타 공통적으로 사용될 head 정보 설정 */}

      {/* 뷰포트 설정 (모바일에서 확대비율) */}
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
    </Helmet>
  );
}

ReactDOM.render(
  // 만든 store를 앱 상위에 넣어줍니다.
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <HelmetProvider>
        <MainHelmet />
        <App />
      </HelmetProvider>
    {/* </React.StrictMode> */}
  </Provider>
  ,document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
