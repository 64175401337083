

//아래 링크 보고 만듬
//https://www.youtube.com/watch?v=dYjdzpZv5yc&list=PL67KSqKuOe5thV9nf2ZozdJIhCgQetN0x&index=1

//코드 포맷팅 익스텐션
//https://onlydev.tistory.com/60

//React 크롬 개발자도구
//https://reference-m1.tistory.com/171


/*
 * 0. import
*/
import React, { Component, useEffect, useState, Fragment } from 'react';
import "SnowballTable.css";
import data from "db/mock-data.json";
import dataHeader from "db/mock-dataHeader.json";
import { nanoid } from "nanoid"

import IndexChart from 'body/component/IndexChart.js';
import dataProfit from "db/month-profit-data.json";
import dataAsset from "db/month-asset-data.json";
import dataSnowball1 from "db/snowball-data1.json";
import dataSnowball2 from "db/snowball-data2.json";
import dataSnowball3 from "db/snowball-data3.json";
import dataSnowball4 from "db/snowball-data4.json";
import dataHeader1 from "db/snowball-dataHeader1.json";
import dataHeader2 from "db/snowball-dataHeader2.json";
import dataHeader3 from "db/snowball-dataHeader3.json";
import dataHeader4 from "db/snowball-dataHeader4.json";
import ReadOnlyRowSnowInfo from "body/component/ReadOnlyRowSnowInfo";
import ReadOnlyRowSnowPer from "body/component/ReadOnlyRowSnowPer";
import ReadOnlyRowSnowIndex from "body/component/ReadOnlyRowSnowIndex";

import * as wjCore from '@grapecity/wijmo';
import * as wjcInput from '@grapecity/wijmo.react.input';

import { collection, doc, getDocs, updateDoc, addDoc, deleteDoc, query, where, getDoc, limit, orderBy } from "firebase/firestore";
import {db} from "fb.js";

import {
    Image,
    Dimmer,
    Loader,
    Divider,
    Header,
    Icon,
    Grid,
    Button,
    TextArea,
    Form,
    Segment,
    GridColumn,
    GridRow,
    Label,
    Table,
    Checkbox,
  } from "semantic-ui-react";
import densitymapbox from 'plotly.js/src/traces/densitymapbox';

const SnowballTable = () => {
    const [loading ,setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [sy_user_docId, setSyUserDocId] = useState("NULL");
    
    //투자요약용 state
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [nowMoney, setNowMoney] = useState(0);
    const [nowProfitPer, setNowProfitPer] = useState(0);

    var newContacts = [];   //contactsAsset
    var newContacts1 = [];  //
    var newContacts2 = [];  //contactsProfit
    var newContacts3 = [];   //
    var newContacts4 = [];  //

    var bgColor = "";
    var preYear = "";       //당월손익
    var profitYear = 0;     //연간손익
    var profitTotal = 0;     //누적손익
    var currentYearProfit_Index = 0;
    var currentYearAsset_Index = 0;
    var isYearChanged = false;  //투자수익률 연간 계산용 플래그


    const [headerList1, setHeaderList1] = useState(dataHeader1);
    const [headerList2, setHeaderList2] = useState(dataHeader2);
    const [headerList3, setHeaderList3] = useState(dataHeader3);
    const [headerList4, setHeaderList4] = useState(dataHeader4);

    const [contacts1, setContacts1] = useState(dataSnowball1);  // table 1
    const [contacts2, setContacts2] = useState(dataSnowball2);  // table 2
    const [contacts3, setContacts3] = useState(dataSnowball3);  // table 3
    const [contacts4, setContacts4] = useState(dataSnowball4);  // table 4

    const [headerList, setHeaderList] = useState(dataHeader);
    

    const [str1, setStr1] = useState("TEST 1");
    const [str2, setStr2] = useState("TEST 2");

    const [addFormData, setAddFormData] = useState({
        fullName: "",
        address: "",
        phoneNumber: "",
        email: "",
    });
    
    const [editFormData, setEditFormData] = useState({
        fullName: "",
        address: "",
        phoneNumber: "",
        email: "",
    });

    var dynamicColNum;

    const getAccount = async() => {
        newContacts1 = []; 
        newContacts2 = []; 
        newContacts3 = []; 
        newContacts4 = []; 

        console.log("getAccount")

        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));    
        //var vUserInfo = getUserInfoRedux();     // 로그인 정보를 로컬스토리지에서 가져옵니다.
        if (vUserInfo == null) return;         // 로그인 정보가 없다면 여기서 멈춥니다.

        //1. user_id == "로그인 id"인 doc.id를 얻는다.
        const querySnapshot = query(collection(db, "sy_user"), where("user_id", "==", vUserInfo.user_id));
        //var docRef = doc(db, "sy_user", userInfo.user_id);
        //const docSnap = await getDoc(docRef);

        var v_SyUserDocId
        const docSnap = await getDocs(querySnapshot);
        docSnap.forEach((doc) => {
            console.log("get DocId ===> " + doc.id);
            //console.log(doc.id, " => ", doc.data());
            setSyUserDocId(doc.id);
            v_SyUserDocId = doc.id
        })

        //2. 위에서 얻은 doc.id로 쿼리날린다. query(collection(db, "sy_user", docId, "account")
        const querySnapshot2 = query(collection(db, "sy_user", v_SyUserDocId, "account"), orderBy("monthEnd", "asc"), limit(100));
        const docSnap2 = await getDocs(querySnapshot2);
        
        var i = 0;
        docSnap2.forEach((doc) => {
            //setBoardList(doc.id, doc.data());
            //doc.data() is never undefined for query doc snapshots
            //console.log(doc.id, " => ", doc.data());
            //dataList.push(Object.assign(doc.data(), { monthEnd : doc.data().monthEnd}))


            //var _chk = false;
            //var _state = "NORMAL";
            var docId = doc.id;
            var yearMonth = doc.data().monthEnd;
            var account1 = doc.data().account1;
            var account2 = doc.data().account2;
            var account3 = doc.data().account3;
            var deposit = doc.data().deposit;

            //  console.log("monthEnd type: " + typeof(yearMonth));
            //   console.log("i type: " + typeof(i));
            //   console.log("i: " +i);
          
            //addData1 --> addData4 --> addData2, addData3
            addData1(docId, yearMonth, account1, account2, account3, deposit, i);

            i++;
        })

        if (i == 0) {
            // console.log("SnowballTable / i == 0")
            // console.log("#### contacts1")
            // console.dir(contacts1, {depth:null})
            // console.log()
            // console.log("#### contacts2")
            // console.dir(contacts2, {depth:null})
            // console.log()
            // console.log("#### contacts3")
            // console.dir(contacts3, {depth:null})
            // console.log()
            // console.log("#### contacts4")
            // console.dir(contacts4, {depth:null})
            // console.log()

            setLoading(false)
            //setSummary();
            return;
        }

        setContacts1(newContacts1);
        setContacts4(newContacts4);
        setContacts2(newContacts2);
        setContacts3(newContacts3);

        setLoading(false)
        setSummary();
      };

    
    /*
    * DB에 있는 데이터를 배열에 저장
    * addData1 --> addData4 --> addData2 --> addData3
    */

    // 1. 메인 테이블
    function addData1 (docId, yearMonth, account1, account2, account3, deposit, i) {
        var newContact = { };
        var year = yearMonth.substring(0, 4).toString();
        var month = yearMonth.substring(5, 7).toString();
        var totalAsset_start = (account1 + account2 + account3).toString();
        var totalAsset_end = (account1 + account2 + account3).toString();

        // 월앞에 '0' 뗴주기
        if (yearMonth.substring(5, 6) == '0') {
            month = yearMonth.substring(6, 7).toString();
        }

        if (i == 0) {
            preYear = year;
            bgColor = "Gray"
            var deposit = account1 + account2 + account3
            newContact = {
                id: i,
                _docId : docId,
                bgColor : bgColor,
                year: year,
                month: month + '월',
                totalAsset_start: (account1 + account2 + account3).toString(),
                totalAsset_end: (account1 + account2 + account3).toString(),
                deposit: deposit.toString(),
                profit_month: "-",
                profit_year: "-",
                profit_total: "-",
                account1: account1,
                account2: account2,
                account3: account3,
            }

            addData4(i, docId, totalAsset_end, deposit, 1000, isYearChanged);
            // addData3();
        }
        else {
            totalAsset_start = newContacts1[i-1].totalAsset_end.toString();
            var profitMonth = (   (account1 - newContacts1[i-1].account1) 
                                + (account2 - newContacts1[i-1].account2) 
                                + (account3 - newContacts1[i-1].account3) 
                                - deposit) + "";
            if (preYear != year) {
                preYear = year;
                bgColor = "Gray";
                profitYear = Number(profitMonth);
                profitTotal += Number(profitMonth);
                isYearChanged = true;   //투자수익률 연간 계산용
            } else {
                year = "";
                bgColor = "NotGray";
                profitYear += Number(profitMonth);
                profitTotal += Number(profitMonth);
                isYearChanged = false;
            }

            newContact = {
                id: i,
                _docId : docId,
                bgColor : bgColor,
                year: year,
                month: month + '월',
                totalAsset_start: totalAsset_start,
                totalAsset_end: totalAsset_end,
                deposit: deposit.toString(),
                profit_month: profitMonth,
                profit_year: profitYear.toString(),
                profit_total: profitTotal.toString(),
                account1: account1,
                account2: account2,
                account3: account3,
            }
            addData4(i, docId, totalAsset_end, deposit, profitMonth, isYearChanged);
        }

        newContacts1 = [...newContacts1, newContact];
    }
    
    // 2. 투자수익 (수익률) 테이블
    function addData2 (i, docId, profit_index, isYearChanged) {
        var newContact = { };

        if (i == 0) {
            newContact = {
                id: i,
                _docId : docId,
                perMonth: 0,
                perYear: 0,
                perTotal: 0,
            }
        }
        else {
            if (i==1 || isYearChanged) {
                currentYearProfit_Index = newContacts4[i-1].profit_index;
            }

            newContact = {
                id: i,
                _docId : docId,
                perMonth: (Math.round(((profit_index / newContacts4[i-1].profit_index) - 1) * 1000) / 10),
                perYear: (Math.round(((profit_index / currentYearProfit_Index) - 1) * 1000) / 10),
                perTotal: (Math.round(((profit_index / newContacts4[0].profit_index) - 1) * 1000) / 10),
            }
        }

        newContacts2 = [...newContacts2, newContact];
    }
    
    // 3. 포트폴리오 자산규모 테이블
    function addData3 (i, docId, asset_index, isYearChanged) {
        var newContact = { };

        if (i == 0) {
            newContact = {
                id: i,
                _docId : docId,
                perMonth: 0,
                perYear: 0,
                perTotal: 0,
            }
        }
        else {
            if (i==1 || isYearChanged) {
                currentYearAsset_Index = newContacts4[i-1].asset_index;
            }

            newContact = {
                id: i,
                _docId : docId,
                perMonth: (Math.round(((asset_index/newContacts4[i-1].asset_index) - 1) * 1000) / 10),
                perYear: (Math.round(((asset_index/currentYearAsset_Index) - 1) * 1000) / 10),
                perTotal: (Math.round(((asset_index/newContacts4[0].asset_index) - 1) * 1000) / 10),
            }
        }

        newContacts3 = [...newContacts3, newContact];
    }
    
    // 4. 투자자산 테이블
    function addData4 (i, docId, totalAsset_end, deposit, profitMonth, isYearChanged) {
        //엑셀 E,F,G (기말자산, 입출금, 당월손익) 필요함.
        var newContact = { };
        //var divNum = Number(newContacts1[0].deposit) / 1000;

        if (i == 0) {
            newContact = {
                id: i,
                _docId : docId,
                //unit: (Number(deposit) / divNum).toString(),
                unit: 1000,
                profit_index: 1000,
                asset_index: 1000,
                profit: 0,
            }
            addData2(i, docId, 1000, isYearChanged);
            addData3(i, docId, 1000, isYearChanged);
        }
        else {
            var unit =  Number(newContacts4[i-1].unit) + (deposit / Number(newContacts4[i-1].profit_index));
            var profit_index = Number(newContacts4[i-1].profit_index) + (Number(profitMonth) / Number(unit));
            var asset_index = Number(newContacts4[0].asset_index) * (Number(totalAsset_end) / Number(newContacts1[0].totalAsset_end))
            var profit = Number(profit_index) - Number(newContacts4[0].profit_index);

            newContact = {
                id: i,
                _docId : docId,
                unit: (Math.round(unit * 1) / 1).toString(),
                profit_index: (Math.round(profit_index * 1) / 1).toString(),
                asset_index: (Math.round(asset_index * 1) / 1).toString(),
                profit: (Math.round(profit * 1) / 1).toString(),
            }

            addData2(i, docId, profit_index, isYearChanged);
            addData3(i, docId, asset_index, isYearChanged);
        }

        newContacts4 = [...newContacts4, newContact];
    }

    function fnMoneyNumToString(inputNum) {
        var resultNum;

        if (inputNum == 0) {
            resultNum = "";
        } else {
            resultNum = inputNum.toString();
        }

        return resultNum;
    }

    function setSummary() {
        var totalDeposit = 0;
        var totalProfit = 0;
        var nowMoney = 0;
        var nowProfitPer = 0;


        for (var i = 0; i<newContacts1.length; i++) {
            totalDeposit += Number(newContacts1[i].deposit);
            //console.log("totalProfit : " + newContacts1[i].profit_total)
        }

        totalProfit = newContacts1[newContacts1.length -1].profit_total;
        nowMoney = newContacts1[newContacts1.length -1].totalAsset_end;
        nowProfitPer =  (Math.round(totalProfit / totalDeposit * 1000) / 10)


        setTotalDeposit(totalDeposit);      // 투자금액
        setTotalProfit(totalProfit);        // 투자손익
        setNowMoney(nowMoney);              // 현재자산
        setNowProfitPer(nowProfitPer);      // 수익률
    }

    /**
    * 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    * onFocus()
    */ 
     Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }

        var reg = /(^[+-]?\d+)(\d{3})/;
        var minus = "";
        var n = (this + '');

        if (this < 0) {
            minus = "-";
        }
        
        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return minus + n;
    };

    useEffect(() => {
        var headerListNum = Object.keys(headerList).length;
        dynamicColNum = (headerListNum - 7) / 2;
        console.log("dynamicColNum =======> " + dynamicColNum);
        getAccount()
      }, [])

    return (
        <div>
            {/* 
                자동완성 직접 만들어보기
                https://velog.io/@gbwlxhd97/%EA%B2%80%EC%83%89%EC%B0%BD-%EC%9E%90%EB%8F%99%EC%99%84%EC%84%B1-%EB%A7%8C%EB%93%A4%EA%B8%B0-featreact 
                https://goddino.tistory.com/367

            */ }

            <IndexChart />

            <div style={{ display: "flex", margin: "0 20px", color:'black'}}>
                <div style={{margin: "0"}}>
                    <h4>투자금액: {totalDeposit.format()}원</h4>
                    <h4>현재자산: {nowMoney.format()}원</h4>
                </div>
                <div style={{margin: "0 30px"}}>
                    <h4>투자손익: {totalProfit.format()}원</h4>
                    <h4>수익률: {nowProfitPer}%</h4>
                    <br />
                </div>
            </div>

            <div style={{width:'100%', overflowX: "auto", margin:'20px'}}>
                {loading ? 
                    <Segment>
                        <Dimmer active>
                            <Loader size='medium'>Loading</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                    </Segment> 
                :   <div>
                        <div style={{display: 'flex', flexDirection: "row"
                            //, overflowY: "auto", whiteSpace: "nowrap", height: "300px"
                            }}>

                            {/* {console.log("SnowballTable / i == 0")}
                            {console.log("#### contacts1")}
                            {console.dir(contacts1, {depth:null})}
                            {console.log()}
                            {console.log("#### contacts2")}
                            {console.dir(contacts2, {depth:null})}
                            {console.log()}
                            {console.log("#### contacts3")}
                            {console.dir(contacts3, {depth:null})}
                            {console.log()}
                            {console.log("#### contacts4")}
                            {console.dir(contacts4, {depth:null})}
                            {console.log()} */}

                            {/* 
                                * 음수일때 파란색으로 바꾸는 방법
                                * https://www.phpschool.com/gnuboard4/bbs/board.php?bo_table=qna_html&wr_id=283348&sca=&sfl=wr_subject%7C%7Cwr_content&stx=%C1%EF%BD%C3&sop=and 
                            */}
                            <div style={{flexBasis: "35%"}}>
                                <h3>　</h3>
                                <table id="SnowballTableInfo"
                                    className='SnowballTable-Info'
                                    style={{tableLayout: 'auto'}}>
                                    <thead>
                                        <tr>
                                            {headerList1.map((header, i) => (
                                                <th key={'A+' + i} style={{ textAlign: 'center' }}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts1.map((contact, i) => {
                                            return (
                                                <Fragment key={'A' + i}>
                                                    { (
                                                        <ReadOnlyRowSnowInfo 
                                                            key={contact.toString()}
                                                            contact = { contact }
                                                        />
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{flexBasis: "10%", marginLeft: "10px"}}>
                                <h3>수익률 (손익 index)</h3>
                                <table id="SnowballTablePercent1"
                                    className='SnowballTable-Percent'
                                    style={{tableLayout: 'auto'}}>
                                    <thead>
                                        <tr>
                                            {headerList2.map((header, i) => (
                                                <th key={'B+' + i} style={{ textAlign: 'center' }}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts2.map((contact, i) => {
                                                return (
                                                <Fragment key={'B' + i}>
                                                    { (
                                                        <ReadOnlyRowSnowPer 
                                                            key={contact.toString()}
                                                            contact = { contact }
                                                            bgColor = { contacts1[i].bgColor }
                                                        />
                                                    )}
                                                </Fragment>
                                                );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            {/* <div style={{flexBasis: "10%", marginLeft: "10px"}}>
                                <h3>자산규모 (자산 index)</h3>
                                <table id="SnowballTablePercent2"
                                    className='SnowballTable-Percent'
                                    style={{tableLayout: 'auto'}}>
                                    <thead>
                                        <tr>
                                            {headerList3.map((header, i) => (
                                                <th key={'C+' + i} style={{ textAlign: 'center' }}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts3.map((contact, i) => {
                                            if (contact._state !== "DELETE") {
                                                return (
                                                <Fragment key={'C'+i}>
                                                    { (
                                                        <ReadOnlyRowSnowPer 
                                                            key={contact.toString()}
                                                            contact = { contact }
                                                            bgColor = { contacts1[i].bgColor }
                                                        />
                                                    )}
                                                </Fragment>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div> */}

                            <div style={{flexBasis: "35%", marginLeft: "10px"}}>
                                <h3>펀드로 봤을 때</h3>
                                <table id="SnowballTableIndex"
                                    className='SnowballTable-Index'
                                    style={{tableLayout: 'auto'}}>
                                    <thead>
                                        <tr>
                                            {headerList4.map((header, i) => (
                                                <th key={'D+'+i} style={{ textAlign: 'center' }}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contacts4.map((contact, i) => {
                                            if (contact._state !== "DELETE") {
                                                return (
                                                <Fragment key={'D'+i}>
                                                    { (
                                                        <ReadOnlyRowSnowIndex 
                                                            key={contact.toString()}
                                                            contact = { contact }
                                                            bgColor = { contacts1[i].bgColor }
                                                        />
                                                    )}
                                                </Fragment>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );}

export default SnowballTable;