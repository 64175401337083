import React, { useState, useEffect } from "react";
import { Checkbox } from "semantic-ui-react";

const ReadOnlyRowSnowPer = ({ contact, handleCheckChange, bgColor }) => {

    const [textAssetData, setTextAssetData] = useState(contact);
    
    // onFocus 이벤트 발생 시 blur처리
    // contentEditable = true로 안하면 좌우 테이블의 행크기가 달라지기 때문
    function handlerNoInput(e) {
        e.target.blur();
    }

    function getClassName(value, text) {
        var className = "";

        //console.dir(accountBalance1.current, { depth: null })
        //console.log(value);

        // // ▲ ▼
        // //https://www.w3schools.com/cssref/tryit.asp?cssenti=25B2

        if (value === "") {
            className = 'zero'
        }
        else if (Number(value) > 0) {
            className = 'plusClassPer'
        } else if (Number(value) < 0) {
            className = 'minusClassPer'
        }

        return className
    }

    // 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }
        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return n;
    };
        
    // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    String.prototype.format = function() {
        var num = parseFloat(this);
        if( isNaN(num) ) {
            return "0";
        }

        return num.format().toString().replace('-', '');
    };

    useEffect(() => {
        // 조회 시 숫자 format() 적용
        const newFormData = { ...textAssetData };
        newFormData["perMonth"] = contact.perMonth;
        newFormData["perYear"] = contact.perYear;
        newFormData["perTotal"] = contact.perTotal;
        setTextAssetData(newFormData);

     }, [])

    return (
        <tr>
            <td 
                className={getClassName(contact.perMonth, "perMonth")}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '60px'
                }}
                onFocus={handlerNoInput}
                suppressContentEditableWarning={true}
                >{contact.perMonth}</td>
            <td
                className={getClassName(contact.perYear, "perYear")}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '60px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.perYear}</td>
            <td
                className={getClassName(contact.perTotal, "perTotal")}
                style={{ 
                    textAlign: 'right',  
                    background: bgColor === 'Gray' ? '#eaeaed' : '#fff',
                    width: '60px'
                }}
                suppressContentEditableWarning={true}
                >{textAssetData.perTotal}</td>
        </tr>
    )
}

export default ReadOnlyRowSnowPer;