/* eslint-disable */    //waring 제거
//1. 주식 API 추천   https://geekflare.com/best-stock-market-api/
//2. amChart 사용법  https://www.amcharts.com/docs/v5/getting-started/integrations/react/
//3. FinanceDataReader API 사용법    https://github.com/FinanceData/FinanceDataReader/

// AlphaVantage 무료버전은 1분에 5개의 요청만 할 수 있고 하루에 500개의 요청 제한이 있다. 
// 대략 7400 종목이 있다고하니 매월1일 시총순으로 500개씩 업데이트해서 DB에 때려넣기? --> 주요종목 500개 우선처리(?)
// investing.com 크롤링하기  https://lzomedia.com/blog/investing-com-%EA%B3%BC%EA%B1%B0-%EC%A3%BC%EA%B0%80-%EB%8D%B0%EC%9D%B4%ED%84%B0-%ED%81%AC%EB%A1%A4%EB%A7%81/

// Firebase Funtions에서 구글API(무료), 타사API(유료) 호출 
// https://stackoverflow.com/questions/42995433/how-firebase-cloud-functions-handle-http-post-method

// 홈서버 구축   https://www.notion.so/b2079601e77b411bbabbd11d0e6e33cc

// 2022.04.13
// 스케쥴링 모듈: agenda, Node-cron, Node-schedule
// https://velog.io/@filoscoder/%EC%8A%A4%EC%BC%80%EC%A4%84-%EC%97%85%EB%AC%B4-%EC%9E%90%EB%8F%99%ED%99%94-Node-cron-vs-Node-schedule-%EB%B9%84%EA%B5%90-clk4dyynve
// --> cloud function은 무료버전에서 단 1회도 지원하지 않음..
// 월1회 스케쥴링정도면 admin 전용 버튼으로 만들어서 내가 직접 눌러줘도 될듯.

//DatePicker 라이브러리 정리
//https://velog.io/@seeh_h/React%EC%97%90%EC%84%9C-%ED%99%9C%EC%9A%A9-%EA%B0%80%EB%8A%A5%ED%95%9C-DatePicker-Library

/*
 * 0. import
*/
import React, { Component, useEffect, useState, Fragment } from 'react';
import Plot from 'react-plotly.js';
import AccountTable from './component/AccountTable'
import { ResponsiveLine } from '@nivo/line';
//import {fdr} from 'finance-datareader';

import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import dummyData from '../db/data.json';
import * as commonDB from "awUtils/dbFunction"
import CommonTable from 'body/component/CommonTable';
import CommonTableColumn from 'body/component/CommonTableColumn';
import CommonTableRow from 'body/component/CommonTableRow';
import DatePicker from 'react-datepicker'


import SnowballTable from 'body/component/SnowballTable.js';
import dataProfit from "db/month-profit-data.json";
import dataAsset from "db/month-asset-data.json";
import dataHeaderProfit from "db/month-profit-dataHeader.json";
import dataHeaderAsset from "db/month-asset-dataHeader.json";
import ReadOnlyRow from "body/component/ReadOnlyRow";
import ReadOnlyRowProfit from "body/component/ReadOnlyRowProfit";
import ReadOnlyRowAsset from "body/component/ReadOnlyRowAsset";


import {
  Image,
  Dimmer,
  Loader,
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  TextArea,
  Form,
  Segment,
  GridColumn,
  GridRow,
  Label,
  Table,
  Checkbox,
} from "semantic-ui-react";
import { isNull } from 'lodash';

/*
 * css
*/
import "react-datepicker/dist/react-datepicker.css";

const data = dummyData.data

const Snowball = () => {
  var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
	const [isAdmin, setIsAdmin] = useState(false);

  const [ headerList, setHeaderList ] = useState(['날짜', 'A계좌', 'B계좌']);
  const [ boardList, setBoardList ] = useState(
    [
      {
        last_month : '2022.3 월말',
        balance : '40,000,000',
        balance2 : '3,000,000'
      },
      {
        last_month : '2022.4 월말',
        balance : '50,000,000',
        balance2 : '2,000,000'
      }
    ]
  );
  
  const [headerListProfit, setHeaderListProfit] = useState(dataHeaderProfit);
  const [headerListAsset, setHeaderListAsset] = useState(dataHeaderAsset);
  const [contactsProfit, setContactsProfit] = useState(dataProfit);
  const [contactsAsset, setcontactsAsset] = useState(dataAsset);

  const [startDate, setStartDate] = useState(new Date());

  // store에 접근하여 state 가져오기
  const { isLogin, userInfo } = useSelector(state => state.userInfo);

  const [stockChartXValues, setStockChartXValues] = useState([]);
  const [stockChartYValues, setStockChartYValues] = useState([]);
  //https://quant.stackexchange.com/questions/46209/using-alphavantage-for-japan-shanghai-hong-kong-shenzhen-stock-exchange-data
  //위처럼 타 거래소도 지원하는거 같은데, 코스피 티커를 몰라서 못하는중. ex) 6758.T (도쿄증권거래소)
  //096770.KS --> 안됨..
  let stockArray = ['QQQ', 'TSLA'];
  const colorArray = ['red', 'blue', 'green', 'gray'];
  const [stockData, setStockData] = useState([]);
  // let stockXValues = [];
  // let stockYValues = [];
  let colorIdx = 0;
  
  //웹 스크랩핑 https://blog.naver.com/PostView.nhn?isHttpsRedirect=true&blogId=htk1019&logNo=220969095347&parentCategoryNo=&categoryNo=27&viewDate=&isShowPopularPosts=true&from=search
  async function fetchInvesting() {
    let API_Call = ``

    var ticker = 'AAPL'
    var url = 'https://www.investing.com/search/service/searchTopBar'
    var requestOptions = {
      method : 'POST',
      headers : { 
        'User-Agent': 'Mozilla',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin' : '*',
        'Accept':'application/json, text/javascript, */*; q=0.01',
        'Content-Type':'application/x-www-form-urlencoded',
      },
      body : JSON.stringify({ 
        form_data : { 'search_text': ticker, },
      }),
      //origin : 'http://localhost:3000/chartStock_f'
    }
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    // CROS 정책위반..  https://evan-moon.github.io/2020/05/21/about-cors/
    console.log("investing data.id : " + data.id);
    console.log("inventing data: " + data);
    








    // var data = res.json()
    // var first_quote_result = data['quotes'][0]
    // var curr_id = first_quote_result['pairId']  // curr_id = 6408
    



    // let st_date = '01/06/2022'
    // let end_date = '02/06/2022'
    // form_data = {
    //     'curr_id': curr_id,
    //     'st_date': st_date,
    //     'end_date': end_date,
    //     'interval_sec': 'Daily',
    //     'action': 'historical_data',
    // }
    // url = 'https://www.investing.com/instruments/HistoricalDataAjax'
    // res = requests.post(url, data=form_data, headers=headers)
  }

// 이거 주소창에 치면 결과나옴   
// https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=seoul&apikey=my-api-key
  
  function fetchStock(stockSymbol) {
    console.log("**************** START *********** fetchStock ")
    const API_KEY = "CWKEZO3KHGLYJ9H0";
    //let StockSymbol = 'TSLA';
    //console.log("stockSymbol : " + stockSymbol)
    //https://www.alphavantage.co/documentation/
    let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${stockSymbol}&apikey=${API_KEY}`;
    let stockChartXValuesFunction = [];
    let stockChartYValuesFunction = [];

    fetch(API_Call)
      .then(
        function(response) {
          return response.json();
        }
      )
      .then(
        function(data) {
          //console.log(data);
          let i = 0;
          //Time Series (Daily)
          for (var key in data['Monthly Adjusted Time Series']) {
            stockChartXValuesFunction.push(key);
            stockChartYValuesFunction.push(data['Monthly Adjusted Time Series']
            [key]['5. adjusted close']);
            i++
          }
          console.log("i : " + i);

          //setStockChartXValues(stockChartXValuesFunction => (stockChartXValuesFunction));
          //setStockChartYValues(stockChartYValuesFunction => (stockChartYValuesFunction));
          //stockXValues = stockChartXValuesFunction;
          //stockYValues = stockChartYValuesFunction;
        }
      ).then(() => {
        //https://velog.io/@fltxld3/React-useState-%EB%A1%9C-%EB%B0%B0%EC%97%B4-%EC%B6%94%EA%B0%80%ED%95%98%EA%B8%B02%ED%83%84-feat.%EC%9D%BC%EA%B8%B0%EC%9E%A5
        let newData = {
          name: stockSymbol, // name 키를 가진 값을 value 로 설정
          x: stockChartXValuesFunction,
          y: stockChartYValuesFunction,
          marker: {color: colorArray[colorIdx]},
          type: 'scatter',
          mode: 'lines',
        }
        setStockData(stockData =>(
        [
          newData,
          ...stockData,
        ]));
        //console.log("stockData : "+ stockData[0].name);
        console.log("colorIdx : "+ colorIdx);
        console.log("****************************************");

        colorIdx++;
        //console.log(stockChartXValuesFunction);
      })
  }

  let testArray;
  
  // async function setStockFetch() {
  //   await commonDB.setStockFetch()
  //   .then(result => {
  //     //console.log("result.hash : " + result.hash);
  //     console.log("result.isEnd : " + result.isEnd);
  //   })
  //   .catch(result => {
  //     console.error("reject : " + result);
  //   })
  // }

  // console.dir(data, { depth: null })
  // console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
  // console.dir(boardList, { depth: null })
  // console.log("boardList[0].legnth : " + Object.keys(boardList[0]).length);

  useEffect(() => {
    console.log("useEffect stockData.length : " + stockData.length)
    //fetchInvesting();
    for (var i=0; i<stockArray.length; i++) {
      console.log("stockArray["+i+"] =====> " + stockArray[i])
      
      /*★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★
       * 2022.7.25 
       * API 요청횟수 때문에 테스트하는 동안 주석해둠. 이거 주석풀면 맨밑에 차트 그려짐
       *★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★★ */
      //fetchStock(stockArray[i])
    }
    { console.log("useEffect2 stockData.length : " + stockData.length) }
    
    //getStockFetchState();
    //setStockFetch();

    if (vUserInfo != null && vUserInfo.auth == "admin") {
			setIsAdmin(true);
		}
  }, [])

  return (
    <div>
      {isAdmin ? 
        <h3> This is snowball page </h3> 
        : null 
      }
      
      <SnowballTable />

    </div>
  );
  }

export default Snowball;