/* eslint-disable */    //waring 제거

import React, { Component } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import dummyData from '../db/data.json';
//import googleFinance from 'google-finance';

const data = dummyData.data

var SYMBOLS = [
  'NASDAQ:AAPL',
  'NASDAQ:AMZN',
  'NASDAQ:GOOGL',
  'NASDAQ:YHOO'
];

var FROM = '2021-12-01';
var TO = '2021-12-31';

class TestPage2 extends Component {

  componentDidMount = async () => {
    console.log('*******************************');
    console.log("****   componentDidMount   ****");
    console.log('*******************************');

    // googleFinance.historical({
    //   symbols: SYMBOLS,
    //   from: FROM,
    //   to: TO
    // }).then(function (result) {
    //   _.each(result, function (quotes, symbol) {
    //     console.log(util.format(
    //       '=== %s (%d) ===',
    //       symbol,
    //       quotes.length
    //     ).cyan);
    //     if (quotes[0]) {
    //       console.log(
    //         '%s\n...\n%s',
    //         JSON.stringify(quotes[0], null, 2),
    //         JSON.stringify(quotes[quotes.length - 1], null, 2)
    //       );
    //     } else {
    //       console.log('N/A');
    //     }
    //   });
    // });
  }

  render() {
    return (
        <div>
            <h3> This is Test 2 page </h3>
            
        </div>
    );
  }
}

export default TestPage2;