import { functionsIn } from 'lodash';
import React, { Component, useState, useEffect } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth, db } from "fb.js";

import { Button, Header, Image, Modal, Form, Icon } from 'semantic-ui-react'

import * as common from "awUtils/commonFunction"
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
import { LabelList } from 'recharts';

//새로고침하면 로그인 풀리는거 해결하기.
//https://blog.javabom.com/minhee/session/storage/localstorage-sessionstorage/react

const Modal_Login = () => {
  const [open, setOpen] = useState(false)

  //입력값
  const [inputs, setInputs] = useState({
    id: '',       //아이디
    pw: '',       //비밀번호
  });

  //오류메세지
  const [inputMessage, setInputMessages] = useState({
    // idMessage: "2글자 이상 5글자 미만으로 입력해주세요.",                //아이디
    // passwordMessage: "2 ~ 5글자 미만으로 입력해주세요.",
    idMessage: "",                //아이디
    passwordMessage: "",         //비밀번호
  });

  //유효성 검사
  const [validates, setValidates] = useState({
    isId: false,              //아이디
    isPassword: false,         //비밀번호
  });

  // 비밀번호 숨기기
  const [isPwVisible, setIsPwVisible] = useState(false);

  // dispatch를 사용하기 위한 준비
  const dispatch = useDispatch();

  // Redux에 접근하여 state 가져오기
  const { userInfo } = useSelector(state => state.userInfo);
  // const [ isLogin, setIsLogin ] = useState(false);
  // const [ userInfo, setUserInfo ] = useState({
  //   auth: '',
  //   id: '',
  //   name: '',
  //   nickname: '',
  // });

  const loginRedux = (userInfo) => {
    dispatch(login(userInfo));
  }

  const logoutRedux = () => {
    dispatch(logout());
  }

  async function Login() {
      var userInfo = {
        user_id : '',
        //pw : '',
        name : '',
        nickname : '',
        auth : '',
        isLogin : false,
        hasAccount : false,
      }
      
      if (inputs.id.length === 0 || inputs.pw === 0) {
        alert("ID 또는 비밀번호가 입력되지 않았습니다.");
        return ;
      }

      var docRef = doc(db, "sy_user", inputs.id.toLowerCase());
      const docSnap = await getDoc(docRef);

      //로그인 프로세스
      if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());	// 존재하는 id

          var pwCrypto;
          await common.cryptoPass(inputs.pw)
            .then(result => {
              //console.log("result.hash : " + result.hash);
              //console.log("result.salt : " + result.salt);
              console.log(result.hash)
              pwCrypto = result.hash;
            })
            .catch(result => {
              console.log("reject : " + result);
            })


          //비밀번호 비교
          var q = query(
            collection(db, "sy_user"),
            where("user_id", "==", inputs.id.toLowerCase()),
            where("pw", "==", pwCrypto)  //pw 암호화를 통한 비교 필요
          );

          const docSnap2 = await getDocs(q);
          if (docSnap2.size < 1) {
              //비밀번호 불일치
              alert("존재하지 않는 ID 또는 비밀번호가 틀렸습니다..");
          }
          else {
              //전역변수로 넘길 userInfo 초기화
              //userInfo.pw = doc.data().pw;

              // userInfo.name = doc.data().name;
              // userInfo.nickname = doc.data().nickname;
              // userInfo.auth = doc.data().auth;
              // userInfo.isLogin = true;

              console.log("userInfo.user_id: "+docSnap.data().user_id)

              userInfo.user_id = docSnap.data().user_id;
              userInfo.name = docSnap.data().name;
              userInfo.nickname = docSnap.data().nickname;
              userInfo.auth = docSnap.data().auth;
              userInfo.isLogin = true;

              const querySnapshot = await getDocs(collection(db, "sy_user", docSnap.data().user_id, "account"));
              if (!querySnapshot.empty) {
                userInfo.hasAccount = true;
              }

              //전역변수 셋팅
              loginRedux(userInfo);

              //window.localStorage.removeItem("userName");   // local storage 삭제
              //window.localStorage.clear();                  // local storage 전체삭제

              //2022.7.22 localStorage 셋팅
              window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
              console.dir(window.localStorage, { depth: null })
              window.location.reload()

              setInputs({
                ...inputs,
                id: '',
                pw: '',
              })
              setInputMessages({
                ...inputMessage,
                idMessage: "",
                passwordMessage: "",
              })
              setValidates({
                ...validates,
                isId: false,
                isPassword: false,
              })

              setOpen(false);
          }
      }
      else {  // 존재하지 않는 ID
          // doc.data() will be undefined in this case
          console.log("No such document!");
          alert("존재하지 않는 ID 또는 비밀번호가 틀렸습니다.");

          setInputs({
            ...inputs,
            pw: '',
          })
          setInputMessages({
            ...inputMessage,
            idMessage: "",
            passwordMessage: "",
          })
          setValidates({
            ...validates,
            isId: false,
            isPassword: false,
          })
      }
  }

  /*
   * 회원가입 유효성 검사
   */
  const onInputChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    // console.log("name ====> " + name);
    // console.log("value ====> " + value);

    switch (name) {
      //정규식 테스트 사이트
      //https://regexr.com/

      case "id": 
          const idRegex = /^[A-Za-z]{1}[A-Za-z0-9_-]{4,19}$/

          if (!idRegex.test()) {
            setValidates({ ...validates, isId : false })
            setInputMessages({ ...inputMessage
              , idMessage : "5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다." })
          } else {
            setValidates({ ...validates, isId : true})
            setInputMessages({ ...inputMessage
              , idMessage : "올바른 형식입니다." })
          }
          break;
      case "pw":
          const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?=.*[0-9]).{4,25}$/
          if (!passwordRegex.test(e.target.value)) {
            setValidates({ ...validates, isPassword : false })
            setInputMessages({ ...inputMessage
              , passwordMessage : "숫자+영문자+특수문자 조합으로 4자리 이상 입력해주세요!" })
          } else {
            setValidates({ ...validates, isPassword : true})
            setInputMessages({ ...inputMessage
              , passwordMessage : "올바른 형식입니다." })
          }
          break;
    };
  }

  /*
   * 비밀번호 '*' 숨기기/보이기
   */

  function togglePwVisible(e) {
    setIsPwVisible(!isPwVisible);
  }
  
  /**
   * 키 입력 이벤트
   */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      Login();
    }
  }

  

  useEffect(() => {

  }, [])

  return (
    <>
      {
        !userInfo.isLogin ? <Modal 
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open} 
          size='mini'
          trigger={
            <label 
              style={{
                cursor : 'pointer',}}
            >로그인 </label>}
        >

          <Modal.Header>로그인</Modal.Header>
          <Modal.Content image>
            <Modal.Description> 
              {/* <Header>They used Google Login</Header> */}
              <Form.Input
                            icon = 'user'
                            placeholder = 'id'
                            name = 'id'
                            value = {inputs.id}
                            onChange={onInputChange}
                            onKeyPress={handleKeyPress}
                          />
              <Form.Input
                            icon = {<Icon name={isPwVisible ? 'eye' : 'eye slash outline'}
                                          link
                                          onClick={togglePwVisible}
                                    />}  //eye
                            type = {isPwVisible ? 'text' : 'password'}
                            //{...(isPwVisible ? {type: 'text'} : {type: 'password'})}
                            placeholder = 'passwoard'
                            name ='pw'
                            value = {inputs.pw}
                            onChange={onInputChange}
                            onKeyPress={handleKeyPress}
                          />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
          {/* <Button color='black' onClick={() => alert(false)}>
            Nope
          </Button> */}
          <Button
            content="Sign In"
            labelPosition='right'
            icon='checkmark'
            positive
            onClick={Login}
          />
        </Modal.Actions>
        </Modal>
        : <label>{userInfo.nickname}님 </label>
      }
    </>
  );
}

export default Modal_Login;