/*
 * 게시판 목록
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CommonTable from '../component/CommonTable';
import CommonTableColumn from '../component/CommonTableColumn';
import CommonTableRow from '../component/CommonTableRow';
import { postList } from '../../db/Data';
import { withRouter } from 'react-router-dom';
import { collection, doc, getDocs, setDoc, query, where, getDoc, limit, orderBy } from "firebase/firestore";
import {db} from "fb.js";
import './Post.css';
import {Button, Comment, Form, Header, Icon, Pagination, Grid, Divider} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
//import { orderBy } from 'lodash';


//파이어베이스 (NoSQL DB 구조 이해)
//https://ven2s.github.io/2017/04/19/Firebase-Realtime-Database-1/

//2022.03.08 NoSQL 구조
//https://sub0709.tistory.com/205
//★ https://bcho.tistory.com/665
//★ https://lightcode.tistory.com/22

//전역변수 context
//https://codingbroker.tistory.com/125



function PostList() {
  // store에 접근하여 state 가져오기
  const { userInfo } = useSelector(state => state.userInfo);
  

  const [ loaded, setLoaded ] = useState(false);
  //const [ dataList, setDataList ] = useState([]);
  const [ boardList, setBoardList ] = useState([]);

  const getBoard = async() => {
    let dataList = [];
    var i = 0;
    //const querySnapshot = await getDocs(collection(db, "Board"));
    const querySnapshot = query(collection(db, "Board"), orderBy("board_id", "desc"), limit(20));
    const docSnap = await getDocs(querySnapshot);
    
    docSnap.forEach((doc) => {
      //setBoardList(doc.id, doc.data());
      //doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      dataList.push(Object.assign(doc.data(), { id : doc.id}))
      i++
    })
    //setBoardList(doc.id);

    setBoardList(dataList);

    //console.log("i: " + i)
    //console.log("boardList.board_id: " + dataList[0].board_id)
    console.log("boardList.title " + dataList[0].title)
    // console.log("boardList.view: " + dataList[0].view)
    // console.log("boardList.create_time " + dataList[0].create_time)
    // console.log("boardList.update_time " + dataList[0].update_time)
    console.log("boardList.length: " + dataList.length);
    
  };

  useEffect(() => {
    //setDataList(postList);
    getBoard();
    setLoaded(true);
  }, [])

  return (
    <div>
      
      <CommonTable headersName={['번호','카테고리', '제목', '등록일', '조회수']}>
        {
          loaded ? boardList.map((item, index) => {
            console.log("index : " + index);
            return (
              <CommonTableRow key={index}>
                <CommonTableColumn>{ item.board_id }</CommonTableColumn>
                <CommonTableColumn>{ item.category }</CommonTableColumn>
                <CommonTableColumn>
                  <Link to={`/PostView/${item.board_id}`}>{ item.title }</Link>
                </CommonTableColumn>
                <CommonTableColumn>{ item.create_time.substring(0, 10) }</CommonTableColumn>
                <CommonTableColumn>{ item.view }</CommonTableColumn>
              </CommonTableRow>
            )
          }) : null


        }
        
      </CommonTable>
      <hr style={{ 
        // border : 'none 1px #999',
        // chrome이라 그런지 color 안먹음..
        color : '#999999',
        backgroundColor : '#999999',
        width : '80%',
      }}/>

      <Grid>
        <Grid.Column width={13}> 
        
        </Grid.Column>
        <Grid.Column width={3}>
          <Button
              content="글쓰기"
              icon="add"
              labelPosition="left"
              secondary   //검은색
              onClick={() => {
                if (userInfo.isLogin) {
                  window.location.href = "/PostAdd"
                } else {
                  alert("회원가입이 필요한 서비스입니다.")
                  window.location.href = "/SignUpPage"
                }
                
              }}
            />
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default PostList;