import React from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'

class VisitorModal extends React.Component {

    render() {
        return(
            <Modal
      //onClose={() => setOpen(false)}
      //onOpen={() => setOpen(true)}
      open={this.props.isOpen}
      //trigger={<Button>Show Modal</Button>}
    >
      <Modal.Header>방명록</Modal.Header>
      <Modal.Content image>
        <Image size='medium' src='/images/avatar/large/rachel.png' wrapped />
        <Modal.Description>
          <Header>They used Google Login</Header>
          {this.props.visitorsList.map(name => <p>{`${name}님`}</p>) }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button color='black' onClick={() => alert(false)}>
          Nope
        </Button> */}
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => this.props.closeModal()}
          positive
        />
      </Modal.Actions>
    </Modal>
        )
    }
}

export default VisitorModal