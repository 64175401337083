import { functionsIn, truncate } from 'lodash';
import React, { Component, useState, useEffect } from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { auth, db } from "fb.js";

import { Button, Header, Image, Modal, Form } from 'semantic-ui-react'

import * as common from "awUtils/commonFunction"
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";
import { LabelList } from 'recharts';

//새로고침하면 로그인 풀리는거 해결하기.
//https://blog.javabom.com/minhee/session/storage/localstorage-sessionstorage/react

const Modal_SaveAsk = ({onClose}) => {
  const [modalResult, setModalResult] = useState();

  const handleOk = () => {
    setModalResult(true);
    onClose(true);
  }

  const handleCancel = () => {
    setModalResult(false);
    onClose(false);
  }

  // dispatch를 사용하기 위한 준비
  const dispatch = useDispatch();

  // Redux에 접근하여 state 가져오기
  const { userInfo } = useSelector(state => state.userInfo);
  // const [ isLogin, setIsLogin ] = useState(false);
  // const [ userInfo, setUserInfo ] = useState({
  //   auth: '',
  //   id: '',
  //   name: '',
  //   nickname: '',
  // });

  useEffect(() => {

  }, [])

  return (
    <>
        <Modal 
          onClose={() => handleCancel()}
          //onOpen={() => handleOk()}
          open={true}
          size='mini'
          // trigger={
          //   <label 
          //     style={{
          //       cursor : 'pointer',}}
          //   >시스템 메세지 </label>}
        >

          {/* <Modal.Header>Comfirmation Message</Modal.Header> */}
          <Modal.Content image>
            <Modal.Description> 
              {/* <Header>They used Google Login</Header> */}
              <p>Are you sure you want to save?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <button onClick={handleOk}>OK</button>
            {'        ' /* Add an empty string to create a gap between the buttons */}
            <button onClick={handleCancel}>Cancel</button>
          </Modal.Actions>
        </Modal>
    </>
  );
}

export default Modal_SaveAsk;