import React from "react";
import {
    Button,
    Input,
    Progress,
    Image,
    Divider,
    Header,
    Icon,
} from 'semantic-ui-react';

const Footer = ({ contact, handleEditClick, handleDeleteClick }) => {

    return (
        <div>
            <br/>
                <div class="ui divider"></div>
                {/* <Divider 
                    horizontal={true}>
                    <Header as='h4' style={{ textAlign : 'center'}}>
                         |
                    </Header>
                </Divider> */}
                <p style={{textAlign : 'center', fontSize: '20px'}}> <Icon name='mail'/> tongyoon0423@naver.com</p>
            <br/>
        </div>
    )
}

export default Footer;