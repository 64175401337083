/* eslint-disable */    //waring 제거
import React, { Component, useEffect, useState, useRecoilValue, useRef } from 'react';
import { Button, Progress } from 'semantic-ui-react';
import { doc, getDoc, getDocs, updateDoc, setDoc, query, where, collection, orderBy } from "firebase/firestore";
import {db} from "../fb.js";
import * as common from "awUtils/commonFunction"
import def_stockList from "db/stockList.json";

const FetchButtons = () => {
    var isClick = false;
    var isListClick = false;

    // 타이머
    const [remainingTime, setRemainingTime] = useState(null);

    // ProgressBar
    const [totalNum, setTotalNum] = useState(0);
    const [fetchNum, setFetchNum] = useState(0);

    // 반복수
    const [totalLoopCount, setTotalLoopCount] = useState(null);
    const [leftLoopCount, setLeftLoopCount] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    // 주식 리스트
    const allTextArray = def_stockList.map((text) => text.split('　　')[0]) // 0번째 요소만 추출
                                      .sort((a, b) => a.localeCompare(b)); // A-Z 가나다 순으로 정렬

    const refTotalLoopCount = useRef();

    function startFetch() {
        if (isClick) {
            setStockFetch();
            console.log("clicked!! fetch Stock")
        } else if (isListClick) {
            setStockListFetch();
            console.log("clicked!! fetch Stock List")
        }
    }

    async function setStockListFetch() {

        // ---> 'TSLA　　111111　　Tesla (테슬라)' 에서 맨앞에 티커만 빼서 배열로 만들기
        // var words = [];
        // var stockList = [];
        // for (var i=0; i<allTextArray.length; i++) {
        //     words = allTextArray[i].split('　　');
        //     stockList[i] = words[0];
        // }
        // console.log(stockList)



        // console.log("[FetchButtons - setStockListFetch] - allTextArray (stockList.json), ");
        // console.log(allTextArray)

        // 1. DB에 있는 주식 목록들 가져오기
        const q = query(  collection(db, "stock_data"), orderBy("__name__"));
        const querySnapshot = await getDocs(q);
        const stockDataFromDB = querySnapshot.docs.map((doc) => doc.id);
        // console.log("[FetchButtons - setStockListFetch] - stockDataFromDB, ");
        // console.log(stockDataFromDB)
    
        // 2. DB에는 없는 주식목록 골라내서 추가해주기 (stockList.json에는 있는 주식)
        const missingStocks = allTextArray.filter(stock => !stockDataFromDB.includes(stock));
        console.log("[FetchButtons - setStockListFetch] - missingStocks, "); // ["apple", "banana"]
        console.log(missingStocks)

        for (var i=0; i<missingStocks.length; i++) {
            const docRef = doc(db, "stock_data", missingStocks[i]);
            await setDoc(docRef, {
                update_time : new Date("2022-04-23"),
            });
        }
    }

    
    // fetch 시 필요한 딜레이타임
    function timeout(delayMs) { 
        return new Promise( res => setTimeout(res, delayMs)); 
    }

    function DateToTimeStamp(dateString) {
        return new Date(dateString).getTime()/10
    }

    /*
    인덱스 지수 fetch & set API
    * 2022.04.19
    */
    async function setStockFetch() {
        let unFetchedStockArray;

        // 1. 패치가 필요한 stock Name get
        await getStockFetchState()
            .then(result => {
                if (result == undefined) {
                    return ;
                }
                console.log("unFetchedStockArray : " + result)
                //console.log("result.hash : " + result.hash);
                console.log("[FetchButton getStockFetchState] result.stockArray : " + result.unFetchedStockArray);
                console.dir(result.unFetchedStockArray, {depth: null})
                unFetchedStockArray = result.unFetchedStockArray;
                setTotalNum(result.totalStockCount);

                var vCount;
                if (result.totalStockCount % 5 == 0) {
                    vCount = Math.floor(result.totalStockCount / 5)
                } else {
                    vCount = Math.floor(result.totalStockCount / 5) + 1
                }

                setTotalLoopCount(vCount);
                setLeftLoopCount(vCount)
                refTotalLoopCount.current = vCount;
            })
            .catch(result => {
                console.error("reject : " + result);
            })

        if (unFetchedStockArray == undefined) {
            return ;
        }
        console.log(" ===== unFetchedStockArray: " + unFetchedStockArray[0])
        console.dir(unFetchedStockArray, {depth:null})
        
        var docRef;
        let newData = {}
        for (var i = 0; i < unFetchedStockArray.length; i++) {
            console.log("unFetchedStockArray - i : " + i)
            console.log(i + " % 4 = " + (i%4))

            // 남은 반복수 차감
            refTotalLoopCount.current -= 1;
            setLeftLoopCount(refTotalLoopCount.current);
            
            //newData = await fetchStock(unFetchedStockArray[i])

            // 2. get API stockData
            await fetchStock(unFetchedStockArray[i]).then((data) => {
                if (Object.keys(data).length == 0) {
                    console.log("fetchStock 데이터 0건이므로 종료")
                    return ;
                }

                console.log("2022-04-19 fetchStock")
                newData = (data)
                console.dir(data, { depth: null })
            })

            if (Object.keys(newData).length == 0) {
                console.log("!!!!!!!!!!!!!!!!!!! New Data = Null !!!!!!!!!!!!!!!!!!!!!!");
                //너무 빨리 요청하면 0떠서 에러터지는듯..
            }

            // console.log("timeout START")
            // await timeout(5000);    //delay 5초주면 들어옴 --> 여기서 비동기, 동기 처리가 제대로 안되는듯
            // console.log("timeout END")

            console.log("########### newData ############");
            console.dir(newData, { depth: null })

            // 3. set API StockData
            docRef = await setDoc(doc(db, "stock_data", unFetchedStockArray[i]), {
                monthly_adjusted_data : newData,
                update_time : new Date(common.getDateTime()),   //String to TimeStamp
            });

            setFetchNum(i+1);

            if (i % 5 == 4) {
                //마지막엔 할 필요 없음. ex) 10/10
                if (i == unFetchedStockArray.length -1) {
                    break;
                }


                // 시간 깎이는거 보여줄 타이머 시작
                setRemainingTime(61);

                // 61초간 대기
                await timeout(61000);   //61초간 대기 (AlphaVantage API 호출횟수 때문)
                                        // 5/minute, 500/day
            }
        }

        return new Promise((resolve, reject) => {
            resolve({
                isEnd : true,
                test : "test 2022-04-16",  })
        }) 
    }

    /*
    * 주식 데이터 Fecth 함수
    * 2022.04.19
    */
    async function fetchStock(stockSymbol) {
        var resultData = { };
        const API_KEY = "CWKEZO3KHGLYJ9H0";
        let API_Call = `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY_ADJUSTED&symbol=${stockSymbol}&apikey=${API_KEY}`;
        let stockChartXValuesFunction = [];
        let stockChartYValuesFunction = [];

        try {
            const fetchData = await fetch(API_Call);
            const data = await fetchData.json()

            console.log(" ------------ fetch data *-------------- ");
            console.dir(fetchData, { depth: null });
            console.dir(data, { depth: null });

            let i = 0;
            let tsMonth
            for (var key in data['Monthly Adjusted Time Series']) {
                //월말 데이터를 기준으로 집계하기 때문에 현재 month의 데이터는 DB에 저장하지 않음.
                let tsMonth = key.substring(0, 7);
                if (tsMonth == common.getDateTime().substring(0,7)) {
                    continue;
                }

                stockChartXValuesFunction.push(tsMonth);
                stockChartYValuesFunction.push(data['Monthly Adjusted Time Series']
                                                [key]['5. adjusted close']);

                resultData[tsMonth] = data['Monthly Adjusted Time Series'][key]['5. adjusted close']
                i++
            }

            //alert("API Fetch END!!!");

            return resultData;
        } catch (err) {
            console.log("Failed to fetch StockData : "+err);
            return "Unknwon"
        }
    }

    /*
    * Stock Fetch 상태 get
    *
    * return
    * Array - 패치가 필요한 stock Name
    * int   - 전체 stock count
    * 2022.04.14
    */
    async function getStockFetchState() {
        var result;
        const firstDay = new Date('yyyy-MM');
        //const timestamp1 = admin.firestore.Timestamp.fromDate(firstDay);
        //const current = new Date(Timestamp.now().seconds*1000).toLocaleDateString()
        
        var unFetchedStockArray = [];
        console.log("getStockFetchState - getTime : " + new Date("2022-04-16 05:22:14"));
        console.log("getStockFetchState - toTImeStamp : " + DateToTimeStamp("2022-04-16 02:00:01"));
        console.log("getStockFetchState - getDateTime : " + DateToTimeStamp(common.getDateTime()));

        /* update_time을 2202.04로 넣으면 2022.04.01 09:00보다 이른 시간인 경우
        * 쿼리조건에 걸림 (2022.03.31 로 여김)
        * --> 그래서 (yyyy-mm-01 00:00:01) 형식으로 직접 만들어줌.
        */
        var current = common.getDateTime().substr(0, 7) + "-01 00:00:01";
        current = new Date(current);
        //console.log("commonDB.getDataTime : " + common.getDateTime().substr(0, 7))
        console.log("current : " + current)
        
        // unfetched stock name
        var q = query(collection(db, "stock_data"), where("update_time", "<", current))
        //var q = query(collection(db, "stock_data"))
        //q = collection(db, "stock_data")
        var docSnap = await getDocs(q);

        var i=0;
        //1. firebase DB에서 아직 업데이트가 안된 모든 Stock 컬렉션 get
        docSnap.forEach((doc) => {
            unFetchedStockArray = unFetchedStockArray.concat(doc.id);
            console.log("[FetchButtons - getStockFetchState] " + doc.id + " : " + doc.data().update_time.toDate())
            //console.log("[FetchButtons - getStockFetchState] " + doc.id + " : " + doc.data().update_time)
            //update_time = new Date();
            i++;
        })

        if (docSnap.docs.length == 0) {
            alert("패치할 데이터가 없습니다. (모두 최신데이터임)");
            console.log("이 밑으론 로그가 없어야함~")
            return ;
        }

        

        // total stock count
        //q = query(collection(db, "stock_data"));
        docSnap = await getDocs(q);
        let totalStockCount = docSnap.size;
        console.log("totalStockCount : "+ totalStockCount);

        //console.log("tong i : " + i)
        //console.log("tong stockArray : " + unFetchedStockArray[1])
        
        // console.log("docSnap.id : " + docSnap.id)
        // console.log("docSnap.data : " + docSnap.data())
        // result = docSnap.data().pw_salt;

        //console.log("현재 시간" + currentTime);

        if (docSnap.docs.length == 0) {
            return unFetchedStockArray;
        }
        else {
            return new Promise((resolve, reject) => {
                resolve({
                    unFetchedStockArray : unFetchedStockArray,
                    totalStockCount : totalStockCount,
                    test : "test 2022-04-14",  })
            })
        }
    }

    
    useEffect(() => {
        if (remainingTime > 0) {
            // 설정된 시간 간격마다 setInterval 콜백이 실행된다. 
            const id = setInterval(() => {
                // 타이머 숫자가 하나씩 줄어들도록
                setRemainingTime((remainingTime) => remainingTime - 1);
            }, 1000);
        
        // 0이 되면 카운트가 멈춤
        if(remainingTime === 0) {
            clearInterval(id);
        }
        return () => clearInterval(id);
        // 카운트 변수가 바뀔때마다 useEffecct 실행
        }
    }, [remainingTime])
    
    useEffect(() => {
        console.log("common.getDateTime() : " + common.getDateTime())
        
    }, [])

    return (
        <div>
            <div style={{ }}>
                타이머 {remainingTime}초 남았습니다.　　총 반복수 : {totalLoopCount}　　남은 반복수: {leftLoopCount}
            </div>
            
            <Button
                color='red'
                content='주식 데이터 업데이트'
                icon='cloud upload'
                label={{
                    basic: true,
                    color: 'red',
                    pointing: 'left',
                    content: '월1회',
                }}
                onClick = { () => {
                        isClick = true;
                        startFetch()
                    }
                }
            />

            <Progress value={fetchNum} total={totalNum} progress='ratio' active={true} 
                color={leftLoopCount === 0 ? 'black' : 'blue'}/>

            <Button
                color='red'
                content='주식 목록 업데이트 (stockList.json)'
                icon='cloud upload'
                label={{
                    basic: true,
                    color: 'red',
                    pointing: 'left',
                    content: 'DB업데이트',
                }}
                onClick = { () => {
                        isListClick = true;
                        startFetch()
                    }
                }
            />
            <br />
        </div>
    )
}

// class Buttons extends React.Component {
//     constructor() {
//         super()
//         this.state = {
//             likes : 0
//         }
//     }

//     componentDidMount = async () => {
//         const docRef = doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC");
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//             console.log("Document data:", docSnap.data());
//             this.setState({likes : docSnap.data().likes})
//         } else {
//             // doc.data() will be undefined in this case
//             console.log("No such document!");
//         }
//     }

//     render() {
//         console.log("================= 메모리 누수 체크");
//         return (
//             <div>
//                 <Button
//                     color='red'
//                     content='Like'
//                     icon='heart'
//                     label={{
//                         basic: true,
//                         color: 'red',
//                         pointing: 'left',
//                         content: this.state.likes,
//                     }}
//                     onClick = { () => {this.setState(prevState => {
//                                 //console.log("this.state.likes : " + this.state.likes);
//                                 return {likes : prevState.likes + 1}
//                             }, async () => await updateDoc(doc(db, "Basic", "5o3hbteAp3AfxeMFqqSC"), {likes : this.state.likes}))
//                         }
//                     }
//                 />
//                 <Button
//                     basic
//                     color='blue'
//                     content='Visitors'
//                     icon='user plus'
//                     label={{
//                         as: 'a',
//                         basic: true,
//                         color: 'blue',
//                         pointing: 'left',
//                         content: this.props.visitors,
//                     }}
//                     onClick = { () => this.props.openModal()}
//                 />

//                 <br/>
//                 <br/>
//                 <div>
//                     <Button circular="circular" color="facebook" icon="facebook" onClick={ () => alert("준비중입니다.")}/>
//                     <Button circular="circular" color="twitter" icon="twitter"/>
//                     <Button
//                         circular="circular"
//                         color="youtube"
//                         icon="youtube"
//                         onClick={() => window.open("https://www.youtube.com/c/%EC%8A%88%EC%B9%B4%EC%9B%94%EB%93%9C")}
//                     />
//                     <Button circular="circular" color="google plus" icon="google plus"/>
//                 </div>
//             </div>
            
            
//         )
//     }
// }

export default FetchButtons