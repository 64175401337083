

//아래 링크 보고 만듬
//https://www.youtube.com/watch?v=dYjdzpZv5yc&list=PL67KSqKuOe5thV9nf2ZozdJIhCgQetN0x&index=1

//코드 포맷팅 익스텐션
//https://onlydev.tistory.com/60

//React 크롬 개발자도구
//https://reference-m1.tistory.com/171


/*
 * 0. import
*/
import React, { Component, useEffect, useState, Fragment } from 'react';
import "DataTable.css";
import data from "db/mock-data.json";
import dataHeader from "db/mock-dataHeader.json";
import { nanoid } from "nanoid"
import { useNavigate } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';

import dataProfit from "db/month-profit-data.json";
import dataAsset from "db/month-asset-data.json";
import dataHeaderProfit from "db/month-profit-dataHeader.json";
import dataHeaderAsset from "db/month-asset-dataHeader.json";
import ReadOnlyRowProfit from "body/component/ReadOnlyRowProfit";
import ReadOnlyRowAsset from "body/component/ReadOnlyRowAsset";
import Modal_SaveAsk from 'body/Modal_SaveAsk';
import Modal_Progress from 'body/Modal_Progress.js';

import {db} from "fb.js";
import { collection, doc, getDocs, updateDoc, addDoc, deleteDoc, query, where, getDoc, limit, orderBy } from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import { login, logout, setAccount } from "reducer/userInfo";

import {
    Image,
    Dimmer,
    Loader,
    Divider,
    Header,
    Icon,
    Grid,
    Button,
    TextArea,
    Form,
    Segment,
    GridColumn,
    GridRow,
    Label,
    Table,
    Checkbox,
  } from "semantic-ui-react";

const MyDataTable = () => {
    const [loading ,setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [sy_user_docId, setSyUserDocId] = useState("NULL");

    // dispatch를 사용하기 위한 준비
    const dispatch = useDispatch();

    // Redux에 접근하여 state 가져오기
    const { userInfo } = useSelector(state => state.userInfo);

    const setAccountRedux = (bool) => {
        dispatch(setAccount(bool));
    }

    var newContacts = [];   //contactsAsset
    var newContacts2 = [];  //contactsProfit
    var hiddenContacts = [];
    var hiddenContacts2 = [];

    const [headerListProfit, setHeaderListProfit] = useState(dataHeaderProfit);
    const [headerListAsset, setHeaderListAsset] = useState(dataHeaderAsset);
    const [contactsProfit, setContactsProfit] = useState(dataProfit);
    const [contactsAsset, setContactsAsset] = useState(dataAsset);

    const [headerList, setHeaderList] = useState(dataHeader);
    const [contacts, setContacts] = useState(data);
    
    //투자요약용 state
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [nowMoney, setNowMoney] = useState(0);
    const [nowProfitPer, setNowProfitPer] = useState(0);

    var dynamicColNum;

    const getAccount = async() => {
        newContacts = [];   //contactsAsset
        newContacts2 = [];  //contactsProfit

        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        //var vUserInfo = getUserInfoRedux();     // 로그인 정보를 로컬스토리지에서 가져옵니다.
        if (vUserInfo == null) return;         // 로그인 정보가 없다면 여기서 멈춥니다.

        let dataList = [];

        //1. user_id == "로그인 id"인 doc.id를 얻는다.
        const querySnapshot = query(collection(db, "sy_user"), where("user_id", "==", vUserInfo.user_id));
        //var docRef = doc(db, "sy_user", userInfo.user_id);
        //const docSnap = await getDoc(docRef);

        var v_SyUserDocId
        const docSnap = await getDocs(querySnapshot);
        docSnap.forEach((doc) => {
            //console.log("get DocId ===> " + doc.id);
            //console.log(doc.id, " => ", doc.data());
            setSyUserDocId(doc.id);
            v_SyUserDocId = doc.id
        })

        //2. 위에서 얻은 doc.id로 쿼리날린다. query(collection(db, "sy_user", docId, "account")
        const querySnapshot2 = query(collection(db, "sy_user", v_SyUserDocId, "account"), orderBy("monthEnd", "asc"), limit(100));
        const docSnap2 = await getDocs(querySnapshot2);
        
        var i = 0;
        docSnap2.forEach((doc) => {
            // setBoardList(doc.id, doc.data());
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            dataList.push(Object.assign(doc.data(), { monthEnd : doc.data().monthEnd}))

            // var _chk = false;
            // var _state = "NORMAL";
            var docId = doc.id;
            var yearMonth = doc.data().monthEnd;
            var account1 = doc.data().account1;
            var account2 = doc.data().account2;
            var account3 = doc.data().account3;
            var deposit = doc.data().deposit;

            //console.log(yearMonth + " - account1: " + account1 +",    account2: " + account2 + ",   account3: " + account3 + ",   deposit: " + deposit)
            // console.log("monthEnd type: " + typeof(yearMonth));
            // console.log("i type: " + typeof(i));
            // console.log("i: " +i);
            addDataAsset(docId, yearMonth, account1, account2, account3, deposit, i);
            addDataProfit(yearMonth, account1, account2, account3, deposit, i);

            i++;
        })

        //console.log("docSnap2 ..." + docSnap2.size);

        if (i == 0) {
            setLoading(false);
            return ;
        }

        setContactsAsset(newContacts);
        setContactsProfit(newContacts2);

        setLoading(false)
        setSummary();
    };

    
    /*
    * DB에 있는 데이터를 배열에 저장
    */
    function addDataAsset (docId, yearMonth, account1, account2, account3, deposit, i)
    {
        var newContact = {
            id: i,
            _docId : docId,
            _chk : false,
            _state : "NORMAL",
            yearMonth: yearMonth,
            accountBalance1: account1.toString(),
            accountBalance2: account2.toString(),
            accountBalance3: account3.toString(),
            deposit: deposit.toString(),
            totalAsset: (account1 + account2 + account3).toString(),
        }


        newContacts = [...newContacts, newContact];
    }

    function addDataProfit (yearMonth, account1, account2, account3, deposit, i)
    {
        var newContact2 = {
            id: i,
            _chk : false,
            _state : "NORMAL",
            yearMonth: yearMonth,
            accountProfit1: "",
            accountProfit2: "",
            accountProfit3: "",
            grossProfit: "",
        }

        if (i == 0) {
            
        } else {
            var accountProfit1 = account1 - Number(newContacts[i-1].accountBalance1);
            var accountProfit2 = account2 - Number(newContacts[i-1].accountBalance2);
            var accountProfit3 = account3 - Number(newContacts[i-1].accountBalance3);
            var grossProfit = (accountProfit1 + accountProfit2 + accountProfit3) - deposit;

            newContact2 = {
                id: i,
                _chk : false,
                _state : "NORMAL",
                yearMonth: yearMonth,
                accountProfit1: fnMoneyNumToString(accountProfit1),
                accountProfit2: fnMoneyNumToString(accountProfit2),
                accountProfit3: fnMoneyNumToString(accountProfit3),
                grossProfit: fnMoneyNumToString(grossProfit),
            }
        }

        newContacts2 = [...newContacts2, newContact2];
    }

    function fnMoneyNumToString(inputNum) {
        var resultNum;

        if (inputNum == 0) {
            resultNum = "";
        } else {
            resultNum = inputNum.toString();
        }

        return resultNum;
    }
    
    const onClickTable = (event) => {
        event.preventDefault();

        var str = "";
        var tdArr = new Array();	// 배열 선언

        // 현재 클릭된 Row(<tr>)
        const tr = event.target.getAttribute('id');
        // console.dir(event, { depth: null });
        // console.log("클릭한 Row의 모든 데이터 ttrtrtrtr : "+tr);
        // console.log("event.target.offsetParent.className : " + event.target.offsetParent.className );

        // console.log("event.target.checked : " +event.target.checked);

        // const td = tr.children;

        // // tr.text()는 클릭된 Row 즉 tr에 있는 모든 값을 가져온다.
        // console.log("클릭한 Row의 모든 데이터 : "+tr.text());
        
        // // 반복문을 이용해서 배열에 값을 담아 사용할 수 도 있다.
        // td.each(function(i){
        //     tdArr.push(td.eq(i).text())
        // });

        // console.log("배열에 담긴 값 : "+tdArr);

        // // td.eq(index)를 통해 값을 가져올 수도 있다.
        // var no = td.eq(0).text();
        // var userid = td.eq(1).text();
        // var name = td.eq(2).text();
        // var email = td.eq(3).text();

        // str +=	" * 클릭된 Row의 td값 = No. : <font color='red'>" + no + "</font>" +
		// 			", 아이디 : <font color='red'>" + userid + "</font>" +
		// 			", 이름 : <font color='red'>" + name + "</font>" +
		// 			", 이메일 : <font color='red'>" + email + "</font>";

        // setStr1(" * 클릭한 Row의 모든 데이터 = " + tr.text());
        // setStr2(str);
    }

    
    /*
     * 추가 버튼 Click 이벤트
     */
    function handleAddButton(event) {
        event.preventDefault();
        var id = contactsAsset.length;

        const newContact = {
            id : id,
            _docId : "",
            _state : "INSERT",
            yearMonth: "",
            accountBalance1: "",
            accountBalance2: "",
            accountBalance3: "",
            deposit: "",
            totalAsset: "",
        }

        const newContact2 = {
            id : id,
            _chk : false,
            _state : "INSERT",
            yearMonth: "",
            accountProfit1: "",
            accountProfit2: "",
            accountProfit3: "",
            grossProfit: "",
        }

        const newContacts = [...contactsAsset, newContact];
        setContactsAsset(newContacts);

        const newContacts2 = [...contactsProfit, newContact2];
        setContactsProfit(newContacts2);
    }

    /*
     * 삭제 버튼 Click 이벤트
     */
    function handleDelClick(event) {
        event.preventDefault();

        /*  
        * 2023.02.06 _state를 쓰면서 이 로직은 버림
        //DB 저장용 히든 데이터 (이렇게하면 state의 변경에 영향받지 않고)
        //state를 변경하기 전 데이터로 온전하게 담아둘 수 있음.
        hiddenContacts = JSON.parse(JSON.stringify(contactsProfit));
        hiddenContacts2 = JSON.parse(JSON.stringify(contactsAsset));
        console.log("========== btnDel =========")
        console.dir(hiddenContacts, {depth : null})
        */

        var copyArray = [];
        var copyArray2 = [];
        
        for (var i=0; i<contactsProfit.length; i++) {
            if (contactsProfit[i]._chk !== true) {
                // console.log("======> contactsProfit["+i+"]._chk: " + contactsProfit[i]._chk);
                copyArray = [...copyArray, contactsProfit[i]];
                copyArray2 = [...copyArray2, contactsAsset[i]];
            } else {
                contactsProfit[i]._state = "DELETE";
                copyArray = [...copyArray, contactsProfit[i]];

                contactsAsset[i]._state = "DELETE";
                copyArray2 = [...copyArray2, contactsAsset[i]];
            }
        }

        // console.log("====== contactsProfit =====");
        // console.dir(contactsProfit, {depth:null})

        // console.log("====== contactsAsset =====");
        // console.dir(contactsAsset, {depth:null})

        setContactsProfit(copyArray);
        setContactsAsset(copyArray2);
    }

    
    /*
     * 저장 버튼 Click 이벤트
     */
    const handleSaveClick = async() => {
        //console.log("MyDataTable ... handleSaveClick()")

        /*
        // 1. 유효성 검사 (month 중복 있는지 체크) (_state != "DELETE")
        // 2. DELETE <-- 삭제
        // 3. INSERT, UPDATE <-- setDoc {merge:true}
        // 4. 성능향상을 위해서라면 hiddenArray에 NORMAL인건 빼고 I U D 배열만 넣어두는것도 괜찮을듯?
        //    4-0. 그정도꺼지 성능향상이 필요할까.. 
        //    4-1. hiddenArray에 넣는 코드를 짜는것(성능) 
        //    4-2. for문 냅다 돌리는 것 (가독성)
        //    4-3. 유의미한 성능차이도 없을것 같고, 속도 이슈가 발생하지 않을 것 같아서 일단 가독성을 채택.
        */

        //console.dir(contactsAsset, {depth : null});

        // 1. 유효성 검사
        var filteredContacts = contactsAsset.filter(function(contact) {
            return contact._state !== "DELETE";
        });
        
        var yearMonths = filteredContacts.map(function(contact) {
            return contact.yearMonth;
        });
        
        var hasDuplicates = yearMonths.some(function(yearMonth, index) {
            return yearMonths.indexOf(yearMonth) !== index;
        });

        const yearMonthRegex = /^\d{0,4}-{0,1}(0[1-9]{0,2}|1[012]{0,2}){0,2}$/
        const hasWrongFormat = yearMonths.some(function(yearMonth, index) {
            return !( (yearMonth.length === 7) && yearMonthRegex.test(yearMonth) );
          });

        if (hasWrongFormat) {
            alert("계좌별 자산의 형식이 잘못되었습니다."
                + "\nex) 2022-08 이와 같은 형식으로 작성해주십시오.");
            return 0;
        }
        
        // 중복 발생시 메세지를 띄워주고, 함수를 빠져나감
        if (hasDuplicates) {
            alert("계좌별 자산이 중복되었습니다. ex) 2022-01" 
                + "\n(Duplicate assets by account. ex) 2022-01)");
            return 0;
        }

        handleModalOpen();  // OK이면 저장
    }

    /*
    * Profit 그리드 CheckBox Change 이벤트 (Grid_Profit)
    */
    function handleCheckChange(data, id) {
        //console.log("data : " + data + ",  id :" + id);

        var copyArray = [...contactsProfit];
        copyArray[id] = {...copyArray[id], _chk: !(copyArray[id]._chk)};
        setContactsProfit(copyArray);
    }

    /*
    * Asset 그리드 Text Change 이벤트 (Grid_Asset)
    */
    function handleTextChange(newData) {
        // console.log("MyDataTable.js - handleTextChange --------- newData");
        // console.dir(newData, {depth:null})

        var newArray = [...contactsAsset];
        var state = newData["_state"];

        if (state != "INSERT" && state != "DELETE") {
            state = "UPDATE"
        }
        newData["_state"] = state;

        newArray[newData.id] = newData;

        if (!_isEqual(newArray, contactsAsset)) {
            setContactsAsset(newArray);
        }

        /**
         * 2023.02.11
         * 1. then()문으로 profit 계산 함수 돌리기
         * 2. setContactsProfit 적용
         * 3. profit 그리드에서 foramt 변환까지 잘되는지 확인하기.
         */
    }

    /**
     * [저장] 클릭 시 모달 팝업 오픈 (DB저장 )
     */
    const handleModalOpen = () => {
        setShowModal(true);
    }

    const handleModalClose = async (result) => {
        var isSaved = false;

        setShowModal(false);
        //console.log(`Modal result: ${result}`);
        //console.log("Modal result: " + result);
        console.log("sy_user_docId : " + sy_user_docId)

        //DB에 저장
        if (result == true) {
            setShowProgressModal(true);
            //console.log("MyDataTable ... contactsAsset.length : " + contactsAsset.length)
            // 2023.02.07 asset 테이블의 Update 시 state를 변경해주어야 일부러 중복을 발생시키고,
            // 아래 코드를 테스트해 볼 수 있을듯. (ReadOnlyRowAsset.js 에서 먼저 작업)

            //2. DELETE
            for (var i=0; i<contactsAsset.length; i++) {
                if (contactsAsset[i]._state == "DELETE") {
                    //console.log("contactsAsset[i]._docId : " + contactsAsset[i]._docId)

                    //저장 안된 row이면 쿼리 안날림. (바로 에러나고 사이트 멈춰버림)
                    if (contactsAsset[i]._docId != '') {

                        var accountRef = accountRef = doc(db, "sy_user", sy_user_docId, "account", contactsAsset[i]._docId);
                        await deleteDoc(accountRef);
                        isSaved = true;
                    }
                }
            }

            //3. INSERT, UPDATE
            for (var i=0; i<contactsAsset.length; i++) {
                if (contactsAsset[i]._state == "INSERT" || contactsAsset[i]._state == "UPDATE") {
                    //console.log("contactsAsset[i]._docId : " + contactsAsset[i]._docId)

                    var accountRef;
                    if (contactsAsset[i]._docId == "") {
                        // Insert (new data)
                        //console.log("Error 1...")
                        accountRef = collection(db, "sy_user", sy_user_docId, "account");

                        //Strig to Num 형변환 후 새로운 Object로 저장
                        var newObj = {
                            account1: 0,
                            account2: 0,
                            account3: 0,
                            deposit: 0,
                            monthEnd: "",
                        };
                        newObj.account1 = parseFloat(contactsAsset[i].accountBalance1 || 0);
                        newObj.account2 = parseFloat(contactsAsset[i].accountBalance2 || 0);
                        newObj.account3 = parseFloat(contactsAsset[i].accountBalance3 || 0);
                        newObj.deposit = parseFloat(contactsAsset[i].deposit || 0);
                        newObj.monthEnd = contactsAsset[i].yearMonth;

                        await addDoc(accountRef, newObj, {merge : true});
                        isSaved = true;
                    } else {
                        // Update
                        //console.log("Error 2...")
                        accountRef = doc(db, "sy_user", sy_user_docId, "account", contactsAsset[i]._docId);

                        var newObj = {
                            account1: 0,
                            account2: 0,
                            account3: 0,
                            deposit: 0,
                            monthEnd: "",
                        };
                        newObj.account1 = parseFloat(contactsAsset[i].accountBalance1 || 0);
                        newObj.account2 = parseFloat(contactsAsset[i].accountBalance2 || 0);
                        newObj.account3 = parseFloat(contactsAsset[i].accountBalance3 || 0);
                        newObj.deposit = parseFloat(contactsAsset[i].deposit || 0);
                        newObj.monthEnd = contactsAsset[i].yearMonth;

                        await updateDoc(accountRef, newObj, {merge : true});
                        isSaved = true;
                    }
                }
            }
            
            //데이터 저장 성공했으면 모든 그리드 _state를 NORMAL로 변경해줘야 할듯.
            if (isSaved) {
                // setContactsAsset(null);
                // setContactsProfit(null);
                getAccount()
                .then(() => {
                        setShowProgressModal(false);
                        //alert("Data successfully saved \n" + "저장 되었습니다.")
                        setAccountRedux(true);
                        var vUserInfo = JSON.parse(window.localStorage.getItem("userInfo"));
                        vUserInfo = {
                            ...vUserInfo,
                            hasAccount : true
                        }

                        window.localStorage.setItem("userInfo", JSON.stringify(vUserInfo));
                        //window.location.reload()
                        
                        //2023.05.07 이걸로 다시 계산할 수 있길..
                        // 그냥 위에서 getAccount 실행했는데, 걍 리로드만 빼주면 되는거 아닌가?
                        //getAccount();
                    }
                );   //재조회
            } else {
                alert("No data has been changed. \n" + "변경된 데이터가 없습니다.");
            }
        }
    }

    function setSummary() {
        //newContacts --> asset
        //newContacts2 --> profit

        var totalDeposit = 0;
        var totalProfit = 0;
        var nowMoney = 0;
        var nowProfitPer = 0;

        //console.log("newContacts.length : " + newContacts.length)
        for (var i = 0; i<newContacts.length; i++) {
            if (i == 0) {
                totalDeposit += Number(newContacts[i].totalAsset);
            } else {
                totalDeposit += Number(newContacts[i].deposit);
            }

            totalProfit += Number(newContacts2[i].grossProfit);
            //console.log("totalProfit : " + totalProfit)
        }

        
        nowMoney = Number(newContacts[newContacts.length -1].totalAsset);
        nowProfitPer = Number(Math.round(totalProfit / totalDeposit * 1000) / 10)


        setTotalDeposit(totalDeposit);
        setTotalProfit(totalProfit);
        setNowMoney(nowMoney);
        setNowProfitPer(nowProfitPer);
    }

    /**
    * 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    * onFocus()
    */ 
     Number.prototype.format = function() {
        if(this == 0) { 
            return 0; 
        }

        var reg = /(^[+-]?\d+)(\d{3})/;
        var minus = "";
        var n = (this + '');

        if (this < 0) {
            //minus = "-";
        }
        
        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
        
        return minus + n;
    };

    useEffect(() => {
         var headerListNum = Object.keys(headerList).length;
         
        dynamicColNum = (headerListNum - 7) / 2;
        console.log("dynamicColNum =======> " + dynamicColNum);
        getAccount();
            
      }, [])

    return (
        <div className='DataTable'>
            <br />
            
            <div style={{margin: "0 30px"}}>

            
            {showProgressModal && <Modal_Progress /> }
                    
            </div>
            <div style={{ display: "flex", margin: "0 20px", color:'black'}}>
                <div style={{margin: "0"}}>
                    <h4>투자금액: {totalDeposit.format()}원</h4>
                    <h4>현재자산: {nowMoney.format()}원</h4>
                </div>
                <div style={{margin: "0 30px"}}>
                    <h4>투자손익: {totalProfit.format()}원</h4>
                    <h4>수익률: {nowProfitPer}%</h4>
                </div>
            </div>
            <br />
        
            <div style={{wdith : "1480px", margin: "10px 20px", overflowX: "auto"}}>
                {loading ? 
                    <Segment>
                        <Dimmer active>
                            <Loader size='medium'>Loading</Loader>
                        </Dimmer>

                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                        <Image src='/images/wireframe/short-paragraph.png' />
                    </Segment> 
                :   <div>
                        {/* Table 버튼 */}
                        <div style={{margin: "10px 0", display:"flex"
                                //, overflowY: "auto", whiteSpace: "nowrap", height: "300px"
                                }}>
                            <Button
                                    icon="add"
                                    //labelPosition="left"
                                    secondary   //검은색
                                    onClick={handleAddButton}
                                    // onClick={() => {
                                    //   setBoardList(
                                    //     [
                                    //       ...boardList,
                                    //       {
                                    //         last_month : "",
                                    //         balance : "",
                                    //         balance2 : "",
                                    //       }
                                    //     ]
                                    //   )

                                    //   // if (isLogin) {
                                    //   //   //window.location.href = "/PostAdd"
                                        
                                    //   // } else {
                                    //   //   alert("회원가입이 필요한 서비스입니다.")
                                    //   //   //window.location.href = "/SignUpPage"
                                    //   // }
                                        
                                    // }}
                            />
                            <Button
                                icon="minus"
                                //labelPosition="left"
                                secondary   //검은색
                                onClick={handleDelClick}
                            />
                            <div style={{marginLeft: "1065px", maxHeight: "12px", minWidth: "75px"}}>
                                <Button 
                                    secondary   //검은색
                                    onClick={handleSaveClick}
                                >저장</Button>
                                {showModal &&
                                    <Modal_SaveAsk onClose={handleModalClose} />
                                }
                            </div>
                        </div>
                    
                        {/* 
                            * 1. 그리드 한번에 그릴껀지
                            *     --> assetTable 값 변경시 이벤트로 profitTable의 값을 계산해야함.
                            * 1-2. 한번에 그리든 따로 그리든 어떻게 데이터 바인딩할지 (계산할 때)
                            * 
                            * 2.   최초조회시 DB에서 profit 데이터 넣어주고, 저장할 때 DB에 넣어줄건지
                            *     --> DB에 profitTable 데이터가 1건이라도 있으면 넣어주고
                            *         없는 경우 그냥 addRow()
                        */}
                        
                        <div style={{display: 'flex', flexDirection: "row"
                            //, overflowY: "auto", whiteSpace: "nowrap", height: "300px"
                            }}>
                            {/* 
                                * 음수일때 파란색으로 바꾸는 방법
                                * https://www.phpschool.com/gnuboard4/bbs/board.php?bo_table=qna_html&wr_id=283348&sca=&sfl=wr_subject%7C%7Cwr_content&stx=%C1%EF%BD%C3&sop=and 
                            */}
                            <div style={{flexBasis: "42%"}}>
                                <table id="profitTable"
                                     className='my-data-table'
                                    onClick={onClickTable}>
                                    <thead>
                                        <tr>
                                            {headerListProfit.map((header, i) => (
                                                <th key={i} style={{ textAlign: 'center'}}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactsProfit.map((contact) => {
                                            if (contact._state !== "DELETE") {
                                                return (
                                                <Fragment key={contact.id}>
                                                    { (
                                                        <ReadOnlyRowProfit 
                                                            key={contact.toString()}
                                                            contact = { contact }
                                                            handleCheckChange = { handleCheckChange }
                                                        />
                                                    )}
                                                </Fragment>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{flexBasis: "55%", margin: "0 20px"}}>
                                <table id="assetTable"
                                    className='my-data-table'
                                    onClick={onClickTable}>
                                    <thead>
                                        <tr>
                                            {headerListAsset.map((header, i) => (
                                                <th key={i} style={{ textAlign: 'center' }}>
                                                    {header.column}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contactsAsset.map((contact) => {
                                            if (contact._state != "DELETE") {
                                                return (
                                                <Fragment key={contact.id}>
                                                    { (
                                                        <ReadOnlyRowAsset
                                                            key={contact.toString()}
                                                            contact = { contact } 
                                                            handleTextChange = {handleTextChange}
                                                        />
                                                    )}
                                                </Fragment>
                                                );
                                            }  
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                    </div>
                }
            </div>
        </div>
    );}

export default MyDataTable;