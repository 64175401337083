/* eslint-disable */    //waring 제거
import React, { Component } from 'react';

import _ from "lodash";
import hs1 from "../HS1.jpg"

import {
    Image,
    Divider,
    Header,
    Icon,
    Grid,
} from 'semantic-ui-react';

import Comments from './comment.js';
import Buttons from './buttons.js';
import VisitorModal from './modal.js';

class MyWeb extends Component {
    constructor() {
        super()
        this.state = {
            userName : "",
            isModalOpen : false,
            visitors : ""
        }
    }

    toggleModal = () => this.setState((prevState) => {
        return {isModalOpen : !prevState.isModalOpen}
    })
    
  render() {
    console.log("================= this.props.visitors: "+this.props.visitors);
    return (
        <div>
            
            <VisitorModal isOpen = {this.state.isModalOpen} closeModal = {this.toggleModal} visitorsList = {this.props.visitors}>

            </VisitorModal>

            <Grid centered="centered">
                    <Grid.Row>
                        <Image src={hs1} centered="centered" height={350} width={350} />
                    </Grid.Row>
                    <Grid.Row>
                        <Buttons openModal = {this.toggleModal} visitors = {this.props.visitors.length}/>
                    </Grid.Row>
                </Grid>
                
                <br/>
                <Divider horizontal="horizontal">
                    <Header as='h4' style={{ textAlign : 'center'}}>
                        <Icon name='comment alternate' />
                        Tpye Comment!
                    </Header>
                </Divider>

                <Comments 
                    userName = {this.props.userName}
                    visitors = {this.props.visitors}
                />
        </div>
    );
  }
}

export default MyWeb;