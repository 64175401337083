/*
 * 회원가입 페이지
 */

import React, { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from "react-router-dom";
import { withRouter, useParams, useNavigate } from "react-router-dom";
import { 
  useRecoilState, 
  useRecoilValue, 
  useSetRecoilState, 
  useResetRecoilState 
} from 'recoil';
import currentDateTimeSelector from "Recoil/Selector";
import * as common from "awUtils/commonFunction"
import * as commonDB from "awUtils/dbFunction"
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "reducer/userInfo";

import "./post/Post.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "fb.js";

import {
  Image,
  Divider,
  Header,
  Icon,
  Grid,
  Button,
  TextArea,
  Form,
  Segment,
  GridColumn,
  GridRow,
  Label,
} from "semantic-ui-react";
import { reject, size, toNumber } from "lodash";
import recaptcha from "react-google-recaptcha/lib/recaptcha";

//DividerClearing  (header)
//input form (list_Label, list_plcHolder, list_icon)
// 위에처럼 두개로 나눠서 세번째 기타개인 설정에서는 또 다른걸 넣을 수 있게 분리하기.
// 시간 걸리더라도 css 직접만져서 위치조정하는게 나을것 같음..

const DividerClearing = ({tittle, list_Label, list_plcHolder, list_icon}) => (
  <Segment>
    <Header as='h3' floated='left'>
      {tittle}
    </Header>

    <Divider clearing />
      {
        list_Label ? list_Label.map((item, i) => {
              console.log("index : " + i);
              return (
                <div>
                  <Grid>
                    <Grid.Row>
                      <GridColumn width={3} textAlign={'right'} verticalAlign={'top'}>
                        <h4> {list_Label[i]} </h4>
                      </GridColumn>
                      <GridColumn width={13} verticalAlign={'top'}>
                        <Form.Input
                          iconPosition = 'left'
                          icon = { list_icon[i] }
                          //placeholder =  { list_plcHolder[i]}
                        />
                        <label>{ list_plcHolder[i]}</label>
                        <div>
                          <label>　</label>
                        </div>
                      </GridColumn>
                    </Grid.Row>
                  </Grid>
                </div>
              )
            }) : null
      }
  </Segment>
)

const InputForm = ({inputLabel, underLabel, inputName, inputValue, isEnable, onChange}) => {
  var sType = "text";
  if (inputName == "pw" || inputName == "pw2") {
    sType = "password";
  }

  return (
     <Grid>
        <GridRow>
          <GridColumn width={3}>
            <label 
              //문자 입력제한 (정규식)
              //https://ryukato.github.io/javascript/2016/01/27/replace-some-text-with-regex.html

              //label 필수 설정
              //https://havebeard.com/posts/required%EA%B0%80%20%EB%B6%99%EC%9D%80%20input%EC%9D%98%20label%EC%97%90%20%EB%B3%84%20%EB%B6%99%EC%9D%B4%EA%B8%B0
              style={{
                position : 'absolute',
                right : '10%',
                top : '15%',
                size : '20px',
                height : '20px',
                fontStyle : 'bold',
                fontSize : '14px',
              }}
              ><b>{inputLabel}</b></label>
          </GridColumn>
          <GridColumn>
            <input
              name={inputName}
              type = {sType}
              value={inputValue}
              onChange={ onChange }
              style={{
                width: '210px',
                height : '30px',
                          //↑    →    ↓   ←
                padding: '5px 5px 5px 5px',
                margin: '0px 0',
                marginLeft:'auto',
                boxSizing: 'border-box',
                //backgroundImage : "url('./src/HS1.jpg')",
                backgroundPosition : "top right",
                backgroundRepeat : 'no-repeat',
              }}
            ></input>
            {underLabel ? 
              <label style={ isEnable ? {
                  //position : 'absolute',
                  display : "inline-block",
                  width : "550px",
                  whiteSpace : "nowrap",
                  color : "black", 
                } : 
                {
                  display : "inline-block",
                  width : "550px",
                  whiteSpace : "nowrap",
                  color : "red",}
              }
            >{underLabel}</label>
              : null
            }
          </GridColumn>
        </GridRow>
      </Grid>
)}


const SignUpPage = ({ mode }) => {
  const { id } = useParams();
  //const [ data, setData ] = useState({});
  const [board, setBoard] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [sitekey, setSiteKey] = useState();
  var [reCAPTCHA, setReCAPTCHA] = useState(false);
  var strPwHash;   //비밀번호
  //var bReCAPTCHA = false;   //reCAPTCHA

  /* input values */
  /*
    븅신같이 변수 오지게 만들어 쓰지말고 한번에 만들어쓰셈..
    https://react.vlpt.us/basic/09-multiple-inputs.html
  */
 //입력값
  const [inputs, setInputs] = useState({
    id: '',       //아이디
    pw: '',       //비밀번호
    pw2: '',      //비밀번호 확인
    name: "",     //이름
    nickname: '', //닉네임
    email: '',    //이메일
  });

  //오류메세지
  const [inputMessage, setInputMessages] = useState({
    // idMessage: "2글자 이상 5글자 미만으로 입력해주세요.",                //아이디
    // passwordMessage: "2 ~ 5글자 미만으로 입력해주세요.",         //비밀번호
    // passwordConfirmMessage: '비밀번호가 달라요 다시 확인해주세요!',  //비밀번호 확인
    // nameMessage: '',             //닉네임
    // nicknameMessage: "공백없이 한글, 영문, 숫자만 입력가능 (한글2자, 영문4자 이상) 닉네임을 바꾸시면 앞으로 30일 이내에는 변경 할 수 없습니다.",         //닉네임
    // emailMessage: '',            //이메일
    idMessage: "",                //아이디
    passwordMessage: "",         //비밀번호
    passwordConfirmMessage: "",  //비밀번호 확인
    nameMessage: "",             //이름
    nicknameMessage: "",         //닉네임
    emailMessage: "",
  });

  //유효성 검사
  const [validates, setValidates] = useState({
    isId: false,              //아이디
    isPassword: false,         //비밀번호
    isPasswordConfirm: false,  //비밀번호 확인
    isName: false,             //닉네임
    isNickname: false,         //닉네임
    isEmail: false,            //이메일
  });

  /*
   * 회원가입 유효성 검사
   */
  const onInputChange = (e) => {
      const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
      setInputs({
        ...inputs,    // 기존의 input 객체를 복사한 뒤
        [name]: value // name 키를 가진 값을 value 로 설정
      });
      //console.log("name ====> " + name);
      //console.log("value ====> " + value);

      switch (name) {
        //정규식 테스트 사이트
        //https://regexr.com/

        case "id": 
            const idRegex = /^[A-Za-z]{1}[A-Za-z0-9_-]{4,19}$/

            if (!idRegex.test(e.target.value)) {
              setValidates({ ...validates, isId : false })
              setInputMessages({ ...inputMessage
                , idMessage : "5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다." })
            } else {
              setValidates({ ...validates, isId : true})
              setInputMessages({ ...inputMessage
                , idMessage : "올바른 형식입니다." })
            }
            break;
        case "pw":
            const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?=.*[0-9]).{4,25}$/
            if (!passwordRegex.test(e.target.value)) {
              setValidates({ ...validates, isPassword : false })
              setInputMessages({ ...inputMessage
                , passwordMessage : "숫자+영문자+특수문자 조합으로 4자리 이상 입력해주세요!" })
            } else {
              setValidates({ ...validates, isPassword : true})
              setInputMessages({ ...inputMessage
                , passwordMessage : "올바른 형식입니다." })
            }
            break;
        case "pw2":
            const passwordRegex2 = /^(?=.*[a-zA-Z])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?=.*[0-9]).{4,25}$/
            if (inputs.pw != e.target.value) {
              setValidates({ ...validates, isPasswordConfirm : false })
              setInputMessages({ ...inputMessage
                , passwordConfirmMessage : "비밀번호가 일치하지 않습니다." })
            } else {
              if (!passwordRegex2.test(e.target.value)) {
                setValidates({ ...validates, isPasswordConfirm : false})
                setInputMessages({ ...inputMessage
                  , passwordConfirmMessage : "비밀번호가 일치하지만, 올바른 형식이 아닙니다." })
                
                return;
              }
              setValidates({ ...validates, isPasswordConfirm : true})
              setInputMessages({ ...inputMessage
                , passwordConfirmMessage : "정확히 일치합니다." })
            }
            break;
        case "name":
            const nameRegex = /^[가-힣]{2,10}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,20}$/
            if (!nameRegex.test(e.target.value)) {
              setValidates({ ...validates, isName : false })
              setInputMessages({ ...inputMessage
                , nameMessage : "형식이 올바르지 않습니다.  ex) 이순신 ex2) Elon Musk" }) 
            } else {
              setValidates({ ...validates, isName : true})
              setInputMessages({ ...inputMessage
                , nameMessage : "올바른 형식입니다." })
            }
            break;
        case "nickname":
            //const nicknameRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{2,10}$/
            const nicknameRegex = /^[가-힣a-zA-Z0-9]{2,10}$/
            if (!nicknameRegex.test(e.target.value)) {
              setValidates({ ...validates, isNickname : false })
              setInputMessages({ ...inputMessage
                , nicknameMessage : "닉네임은 2~10자의 한글, 영문, 숫자만 사용 가능합니다." })
            } else {
              setValidates({ ...validates, isNickname : true})
              setInputMessages({ ...inputMessage
                , nicknameMessage : "올바른 형식입니다." })
            }
            break;
        case "email":
            const emailRegex = /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/

            if (!emailRegex.test(e.target.value)) {
              setValidates({ ...validates, isEmail : false })
              setInputMessages({ ...inputMessage
                , emailMessage : "이메일 형식이 올바르지 않습니다." })
            } else {
              setValidates({ ...validates, isEmail : true})
              setInputMessages({ ...inputMessage
                , emailMessage : "올바른 형식입니다." })
            }
            break;
      }
  };

  let [a, setA] = useState("");
  let navigate = useNavigate();

  //https://velog.io/@kcdoggo/Cannot-read-property-params-of-undefined-%EC%97%90%EB%9F%AC
  // react-router-dom 6 버전부터는 아래와 같은 route props로
  // (history, location, match) 파라미터를 넘겨받지 않는다.
  //const { no } = match.params;

  //https://velog.io/@soryeongk/ReactRouterDomV6

  /* ID 중복체크 */
  async function getUserId() {
    //console.log("no : " + no)
    // const q = query(
    //   collection(db, "Reply"),
    //   where("board_id", "==", Number(id))
    // );
    // const docSnap = await getDocs(q);

    // docSnap.forEach((doc) => {
    //   setBoard(doc.data());
    // });
  }

  async function saveUserInfo() {
    // let newBoardId;

    // try{
    //   const querySnapshot = query(collection(db, "Board"), orderBy("board_id", "desc"), limit(1));
    //   const docSnap = await getDocs(querySnapshot);

    //   docSnap.forEach((doc) => {
    //     newBoardId = doc.data().board_id + 1;
    //     //console.log("docSnap.board_id ========> " + newBoardId);
    //   })

    //   const docRef = await addDoc(collection(db, "Board"), {
    //     board_id : newBoardId,
    //     title : title,
    //     content : content,
    //     nickname : "Admin",
    //     is_deleted : false,
    //     create_time : getDateTime(),
    //     update_time : getDateTime(),
    //     delete_time : "",
    //     reply_count : 0,
    //     category : "개발중",
    //   });
    // } catch (err) {
    //   console.error(err);
    //   alert(err.message);
    // } finally {
    //   alert("저장되었습니다.");
    //   //navigate("/PostMain")
    //   navigate("/PostView/" + newBoardId)
    // }
  }

  /*
   * ReCaptcha 인증 성공여부 set
   */
  function onChangeReCaptcha(value) {
    //console.log('Captcha value:', value);
    if (value == null) {
      console.log("ReCAPTCHA is Fali")
      setReCAPTCHA(false);
    } else {
      console.log("ReCAPTCHA is Success")
      setReCAPTCHA(true);
    }
  }

  /*
   * 회원가입 버튼 click Event (전처리)
  */
  async function onClickSignUp() {
    //https://velog.io/@leemember/%EB%A6%AC%EC%95%A1%ED%8A%B8-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%EC%9C%A0%ED%9A%A8%EC%84%B1-%EA%B2%80%EC%82%AC
    //https://kimyang-sun.tistory.com/entry/React-Hook-Form-Antd-Yup-%EB%A6%AC%EC%95%A1%ED%8A%B8-%ED%9A%8C%EC%9B%90%EA%B0%80%EC%9E%85-%ED%8F%BC-%EB%A7%8C%EB%93%A4%EA%B8%B0
    //첫번째 링크처럼 해봐야할듯..

    // alert("(개발중) 회원가입 기능은 개발중입니다.. ㅜㅜ");
    // return;

    if (!reCAPTCHA) {
      alert("자동입력방지를 체크해주세요.");
      return;
    }

    if (inputs.id.length < 1) {
      alert("아이디(을)를 입력해주세요.");
      return;
    }
    if (inputs.pw.length < 1) {
      alert("비밀번호(을)를 입력해주세요.");
      return;
    }
    if (inputs.pw2.length < 1) {
      alert("비밀번호 확인(을)를 입력해주세요.");
      return;
    }
    if (inputs.name.length < 1) {
      alert("이름(을)를 입력해주세요.");
      return;
    }
    if (inputs.nickname.length < 1) {
      alert("닉네임(을) 입력해주세요.");
      return;
    }
    if (inputs.email.length < 1) {
      alert("E-mail(을)를 입력해주세요.");
      return;
    }

    if (!(inputs.pw === inputs.pw2)) {
      alert("비밀번호가 다릅니다!");
      return;
    }

    //비밀번호 암호화
    await common.cryptoPass(inputs.pw)
      .then(result => {
        //console.log("result.hash : " + result.hash);
        //console.log("result.salt : " + result.salt);
        strPwHash = result.hash;
      })
      .catch(result => {
        console.log("reject : " + result);
      })
    signUp()
  }

  /*
   * 회원가입 process
  */
  async function signUp() {
    let isError = false;
    let userId;
    console.log("signUp ----- strPwHash : " + strPwHash)
    
    try {
      //2023.01.14 안씀
      // const querySnapshot = query(collection(db, "sy_user"), where("user_id", "==", inputs.id));
      // const docSnap = await getDocs(querySnapshot); 

      // docSnap.forEach((doc) => {
      //   userId = doc.data().user_id;
      //   //console.log("docSnap.id ========> " + userId);
      // })


      const docRef = doc(db, "sy_user", inputs.id.toLowerCase());
      const docSnap3 = await getDoc(docRef);

      //테스트용이라 문서명을 자동생성 해놓은 데이터들
      if (docSnap3.exists() && (inputs.id != "a1234" && inputs.id != "admin" && inputs.id != "admin2" && inputs.id != "test")) {
          console.log("========== 이미 존재하는 ID !!! ===========");
          //console.log("Document data:", docSnap3.data());
          
          isError = true;
          alert("이미 사용중인 회원아이디입니다.");
      }
      //회원가입 진행
      else {
          // doc.data() will be undefined in this case
          console.log("-------> 중복된 ID 없으므로 회원가입 진행! ");

          // ID와 같은 이름으로 Document 생성
          const docRef = await setDoc(doc(db, "sy_user", inputs.id.toLowerCase()), {
              user_id : inputs.id.toLowerCase(),
              pw : strPwHash,
              name : inputs.name,
              nickname : inputs.nickname,
              email : inputs.email,
              create_time : common.getDateTime(),
              update_time : common.getDateTime(),
              delete_time : "",
              last_login : "",
              is_deleted : false,
              is_withdraw : false,
              profile_picture : false,
              auth : 'public'
          });
      }
    } catch (err) {
        isError = true;
        console.error(err);
        alert(err.message);
    } finally {
        if (!isError) {
          alert("회원가입이 완료되었습니다.");
          loginLogic();
          navigate("/")
        }
        // navigate("/PostView/" + newBoardId)
    }
  }


  function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
  }

  const formatYmd = date => date.toISOString().slice(0, 19);
  function timestampt() {
    
  }

  async function getSiteKey() {
    //ReCaptcha SiteKey get (로컬, 웹 구분해서 가져옴)
    if (window.location.href.indexOf("localhost") > -1) {   
      //로컬호스트
      const docRef = doc(db, "sy_config", "config")
      const docSnap = await getDoc(docRef);
      setSiteKey(docSnap.data().recaptcha_local)
      //console.log("local : " + docSnap.data().recaptcha_local)
      console.log("============== 로컬 사이트 키 ==============");
    } else {
        //웹
      const docRef = doc(db, "sy_config", "config")
      const docSnap = await getDoc(docRef);
      setSiteKey(docSnap.data().recaptcha_web)
      //console.log("web : " + docSnap.data().recaptcha_web)
      console.log("============== 웹 사이트 키 ==============");
    }
  }

  async function crypto() {
    await common.cryptoPass('0000')
      .then(result => {
        //console.log("result.hash : " + result.hash);
        //console.log("result.salt : " + result.salt);
        console.log(result.hash)
        strPwHash = result.hash;
      })
      .catch(result => {
        console.log("reject : " + result);
      })
  }

  // dispatch를 사용하기 위한 준비
  const dispatch = useDispatch();

  // store에 접근하여 state 가져오기
  const { isLogin, userInfo } = useSelector(state => state.userInfo);

  const loginRedux = (userInfo) => {
    dispatch(login(userInfo));
  }

  const logoutRedux = () => {
    dispatch(logout());
  }

  async function loginLogic() {
    var userInfo = {
      id : '',
      pw : '',
      nickname : '',
      auth : '',
    }

    //아이디 비교
    var q = query(
      collection(db, "sy_user"),
      where("user_id", "==", inputs.id)
    );
    var docSnap = await getDocs(q);
    console.log("docSnap : "+docSnap.size);
    if (docSnap.size < 1) {
      //해당 아이디가 없습니다.
        alert("해당 아이디가 없습니다.");
    } else {
      docSnap.forEach((doc) => {
        //전역변수로 넘길 userInfo 초기화
        userInfo.user_id = doc.data().user_id;
      });

      var pwCrypto;
      await common.cryptoPass(inputs.pw)
        .then(result => {
          //console.log("result.hash : " + result.hash);
          //console.log("result.salt : " + result.salt);
          console.log(result.hash)
          pwCrypto = result.hash;
        })
        .catch(result => {
          console.log("reject : " + result);
        })

      //비밀번호 비교
      q = query(
        collection(db, "sy_user"),
        where("user_id", "==", inputs.id),
        where("pw", "==", pwCrypto)  //pw 암호화를 통한 비교 필요
      );
      docSnap = await getDocs(q);
      if (docSnap.size < 1) {
        //비밀번호가 일치하지 않습니다.
          alert("비밀번호가 일치하지 않습니다.");
      } else {
        docSnap.forEach((doc) => {
          //전역변수로 넘길 userInfo 초기화
          userInfo.pw = doc.data().pw;
          userInfo.nickname = doc.data().nickname;
          userInfo.auth = doc.data().auth;
        });

        //전역변수 셋팅
        loginRedux(userInfo);
      }
    }
  }

  useEffect(() => {
    //console.log("useEffect");
    //console.log("mode: " + mode);

    // commonDB.getBoard(3)
    //   .then(result => {
    //     //console.log("result.caterogy : " + result.data.category);
    //     setA(result.fuck);
    //     //console.log("********* a : " + a);
    //   });

    getSiteKey();
    //crypto();

  }, []);

  return (
    <>
      <h2 align="center">회원가입 페이지</h2>
      <div className="post-view-wrapper" style={{ whiteSpacae: "pre-wrap" }}>
        <Segment>
          <Header as='h3' floated='left'> ★ 사이트 이용정보 입력 </Header>
          <Divider clearing />
          <InputForm 
            inputName = {"id"}
            inputValue = {inputs.id}
            inputLabel = {"아이디 *"}
            underLabel = {inputMessage.idMessage}
            isEnable = {validates.isId}
            onChange = {onInputChange}
            />
          
          <InputForm 
            inputName = {"pw"} 
            inputValue = {inputs.pw} 
            inputLabel = {"비밀번호 *"}
            underLabel = {inputMessage.passwordMessage}
            isEnable = {validates.isPassword}
            onChange = {onInputChange}/>
          <InputForm 
            inputName = {"pw2"} 
            inputValue = {inputs.pw2}
            inputLabel = {"비밀번호 확인 *"}
            underLabel = {inputMessage.passwordConfirmMessage}
            isEnable = {validates.isPasswordConfirm}
            onChange = {onInputChange} />
        </Segment>

        <Segment>
          <Header as='h3' floated='left'> 개인정보 입력 </Header>
          <Divider clearing />
          <InputForm 
            inputName = {"name"} 
            inputValue = {inputs.name} 
            inputLabel = {"이름 *"}
            underLabel = {inputMessage.nameMessage}
            isEnable = {validates.isName}
            onChange = {onInputChange} />
          <InputForm 
            inputName = {"nickname"} 
            inputValue = {inputs.nickname} 
            inputLabel = {"닉네임 *"}
            underLabel = {inputMessage.nicknameMessage}
            isEnable = {validates.isNickname}
            onChange = {onInputChange} />
          <InputForm 
            inputName = {"email"}  
            inputValue = {inputs.email}
            inputLabel = {"E-mail *"}
            underLabel = {inputMessage.emailMessage}
            isEnable = {validates.isEmail}
            onChange = {onInputChange} />
        </Segment>

        <Segment>
          <Header as='h3' floated='left'> 기타 개인설정 </Header>
          <Divider clearing />
          {/*
            그리드로 하나하나 넣어줘야할듯..?
            ReCAPTCHA 인증실패 시 null 받음
          */}
          {/* https://velog.io/@turret1234/React-ReCaptcha-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0
          https://ichi.pro/ko/react-aepeullikeisyeon-eseo-recaptchaleul-guhyeonhaneun-bangbeob-106209256855376 */}
          

          { 
            userInfo.auth === 'admin' ? 
            <>
            <Grid>
            <GridRow>
              <GridColumn width = {3}>
              <label 
                style={{
                  position : 'absolute',
                  right : '10%',
                  top : '15%',
                  size : '20px',
                  height : '20px',
                  fontStyle : 'bold',
                  fontSize : '14px',
                }}
                ><b>메일링 서비스</b></label>
              </GridColumn>
              <GridColumn>
                <p>
                <input
                  //id="aaaa"
                  //https://cho2.tistory.com/entry/input-%EB%B0%95%EC%8A%A4%EC%97%90-%EC%9E%85%EB%A0%A5%EC%8B%9C-%ED%95%9C%EA%B8%80%EC%98%81%EC%96%B4%EB%A7%8C-%EC%9E%85%EB%A0%A5%ED%95%A0-%EC%88%98-%EC%9E%88%EB%8F%84%EB%A1%9D-%EC%84%A0%ED%83%9D
                  //https://goddino.tistory.com/229
                  type="checkbox"
                  //value={}
                  //onChange={(e) => }
                />
                <label
                  //for="aaaa"
                  style={{
                    display : "inline-block",
                    width : "550px"
                  }}>정보 메일을 받겠습니다.</label>
                  </p>
              </GridColumn>
            </GridRow>
          </Grid>

          
          <Grid>
            <GridRow>
              <GridColumn width = {3}>
              <label 
                style={{
                  position : 'absolute',
                  right : '10%',
                  top : '15%',
                  size : '20px',
                  height : '20px',
                  fontStyle : 'bold',
                  fontSize : '14px',
                }}
                ><b>정보 공개</b></label>
              </GridColumn>
              <GridColumn>
                <input 
                  type = "checkbox"
                />
                <label
                  style={{
                    display : "inline-block",
                    width : "550px"
                  }}
                  >다른분들이 나의 정보를 볼 수 있도록 합니다.</label>
                <label
                  style={{
                    display : "inline-block",
                    width : "550px"
                  }}>정보공개를 바꾸시면 앞으로 0일 이내에는 변경이 안됩니다.</label>
              </GridColumn>
            </GridRow>
          </Grid></> : null
          }

          
          
          <Grid>
            <GridRow>
              <GridColumn width={3}>
              <label 
                style={{
                  position : 'absolute',
                  right : '10%',
                  top : '15%',
                  size : '20px',
                  height : '20px',
                  fontStyle : 'bold',
                  fontSize : '14px',
                }}
                ><b>자동입력방지 *</b></label>
              </GridColumn>
              <GridColumn>
                {
                  (sitekey != null) ? <ReCAPTCHA
                    sitekey={sitekey}
                    onChange={onChangeReCaptcha}
                  />
                  : null
                }
                
              </GridColumn>
            </GridRow>
          </Grid>
          
        </Segment>

        {/* 빌드할때마다 스왑해야할듯.. (2022.03.24) --> url 읽어서 로컬인지 운영인지 체크
            처음으로 개발, 운영서버의 필요성을 느낌..
            
            (로컬) 사이트 키 : 6Lf_0QQfAAAAANZkGcFauFooSgkW4VCjar-EGUJR
            (로컬) 비밀 키 : 6Lf_0QQfAAAAAJTHEhBTtplNw253svLS0FqZ6z64
            (웹) 사이트 키 : 6Lf-JAkfAAAAAF_11vsi6qubxXGQmn5Mg56yysgJ
            (웹) 비밀 키 : 6Lf-JAkfAAAAANH-yc1uya3VC_18wl6QNwCw6XQD
          https://firebase.google.com/docs/app-check/web/recaptcha-provider
         */}

        <div style={{textAlign : 'center'}}>
          <Button 
            content="회원가입" 
            color="red"
            onClick={onClickSignUp}
            // disabled={!(
            //             validates.isId && validates.isPassword //&& validates.isPasswordConfirm
            //             //&& validates.isName && validates.isNickname && validates.isEmail
            //             && reCAPTCHA
            //            )} 
          />
          <Button
            content="취소"/>
        </div>
        
      </div>
    </>
  );
};

export default SignUpPage;