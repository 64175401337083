//아래 링크 보고 만듬
//https://www.youtube.com/watch?v=dYjdzpZv5yc&list=PL67KSqKuOe5thV9nf2ZozdJIhCgQetN0x&index=1

//코드 포맷팅 익스텐션
//https://onlydev.tistory.com/60

//React 크롬 개발자도구
//https://reference-m1.tistory.com/171


/*
 * 0. import
*/
import React, { Component, useEffect, useState, Fragment } from 'react';
import "DataTable.css";
import data from "db/mock-data.json";
import dataHeader from "db/mock-dataHeader.json";
import { nanoid } from "nanoid"
import ReadOnlyRow from "body/component/ReadOnlyRow";
import EditableRow from "body/component/EditableRow";
import {
    Image,
    Dimmer,
    Loader,
    Divider,
    Header,
    Icon,
    Grid,
    Button,
    //Input,
    TextArea,
    Form,
    Segment,
    GridColumn,
    GridRow,
    Label,
    Table,
    Checkbox,
  } from "semantic-ui-react";

const DataTable = () => {
	var sStartMonth = "a";
    const [endMonth, setEndMonth] = useState("b");
    const [colorIndex, setColorIndex] = useState(0);
    var sColorIndex = 0;
	
	// 실행순서 1
	useEffect(() => {
        loadStockData()
			.then(({ vStartMonth, vEndMonth }) => {
				sStartMonth = vStartMonth;
                setEndMonth(vEndMonth)

                //fnAddAssetIndex()
				
				console.log("sStartMonth : " + sStartMonth)
				console.log("endMonth : " + endMonth)
			})
            .then(() => addChartStock())
	}, [])

	async function loadStockData() {
		var vStartMonth = "new Data 1"
		var vEndMonth = "new Data 2"
        setEndMonth(vEndMonth);

		return { vStartMonth, vEndMonth};
	}

	async function addChartStock() {
		
        console.log("버튼 클릭!")
		console.log("sStartMonth : " + sStartMonth)
		console.log("endMonth : " + endMonth)
	}
	
	return (
        <div style={{ marginTop:'15px' }}>
			<Button onClick={addChartStock}></Button>
		</div>
	);
}

export default DataTable;